import XTwigPage from '@/components/base/XTwigPage';
import LogsRoutes from './administration/logs';
import ReportsRoutes from './administration/reports';

const AdminSettings = () => import(/* webpackChunkName: "admin_settings" */ '@/apps/admin/views/admin/administration/settings/AdminSettings');
const AdministratorsList = () => import(/* webpackChunkName: "admin_settings" */ '@/apps/admin/views/admin/administration/administrators/AdministratorsList');
const RolesList = () => import(/* webpackChunkName: "admin_settings" */ '@/apps/admin/views/admin/administration/roles/RolesList');
const PartnersList = () => import(/* webpackChunkName: "admin_settings" */ '@/apps/admin/views/admin/administration/partners/PartnersList');
const LocationsList = () => import(/* webpackChunkName: "admin_settings" */ '@/apps/admin/views/admin/administration/locations/LocationsList');
const ApiKeysList = () => import(/* webpackChunkName: "admin_settings" */ '@/apps/admin/views/admin/administration/api-keys/ApiKeysList');

export default [
    {
        path: '',
        name: 'admin.administration',
        component: AdminSettings,
        meta: {
            breadcrumbs: [
                {
                    title: ['common', 'Administration'],
                    url: '/admin/administration',
                },
            ],
            title: ['common', 'Administration'],
        },
    },
    {
        path: 'administrators',
        name: 'admin.administration.administrators',
        component: AdministratorsList,
        meta: {
            keepAlive: true,
            componentName: 'AdministratorsList',
        },
    },
    {
        path: 'roles',
        name: 'admin.administration.roles',
        component: RolesList,
        meta: {
            keepAlive: true,
            componentName: 'RolesList',
        },
    },
    {
        path: 'partners',
        name: 'admin.administration.partners',
        component: PartnersList,
        meta: {
            keepAlive: true,
            componentName: 'PartnersList',
        },
    },
    {
        path: 'locations',
        name: 'admin.administration.locations',
        component: LocationsList,
        meta: {
            keepAlive: true,
            componentName: 'LocationsList',
        },
    },
    {
        path: 'api-keys',
        name: 'admin.administration.api_keys',
        component: ApiKeysList,
        meta: {
            keepAlive: true,
            componentName: 'ApiKeysList',
        },
    },
    {
        path: 'logs',
        children: LogsRoutes,
    },
    {
        path: 'splynx/license',
        name: 'admin.administration.splynx.license',
        component: XTwigPage,
    },
    {
        path: 'reports',
        children: ReportsRoutes,
    },
];
