import { stateToGetters } from '@/apps/admin/store/helpers';
import { isset } from '@/utils/functions';
import { SET_TEMPLATES_ADD_PAGE_DATA, SET_TEMPLATES_DATA } from '../../mutation-types';

const state = {
    adminCanAdd: false,
    dataTableHeaders: [],
    templatesTypes: [],
    dtColumnDefs: {},
    modelName: '',
    loadSpanishTemplates: false,
    className: {},
    attributesMeta: {},
    model: {},
    isMailTemplate: false,
    previewType: '',
    placeholders: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadPageTemplatesData({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/config/templates--get-page-data',
                success: (response) => {
                    commit(SET_TEMPLATES_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    loadAddPageInfo({ commit }, type) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/templates--get-add-page-info?type=${type}`,
                success: (response) => {
                    commit(SET_TEMPLATES_ADD_PAGE_DATA, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
    loadEditPageInfo({ commit }, { id, type }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/templates--get-add-page-info?id=${id}&type=${type}`,
                success: (response) => {
                    commit(SET_TEMPLATES_ADD_PAGE_DATA, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
};

const mutations = {
    [SET_TEMPLATES_DATA](state, data) {
        for (let k in data) {
            state[k] = data[k];
        }
    },
    [SET_TEMPLATES_ADD_PAGE_DATA](state, info) {
        state.attributesMeta = info.attributesMeta.attributes;
        state.className = info.attributesMeta.className;
        state.isMailTemplate = info.isMailTemplate;
        state.previewType = info.previewType;
        state.placeholders = info.placeholders;

        if (isset(info, 'model')) {
            state.model = info.model;
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
