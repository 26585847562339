import XTwigPage from '@/components/base/XTwigPage';

const SuppliersList = () => import(/* webpackChunkName: "inventory_suppliers" */ '@/apps/admin/views/admin/inventory/supply/suppliers/SuppliersList');
const InventoryVendorsList = () => import(/* webpackChunkName: "inventory_vendors" */ '@/apps/admin/views/admin/inventory/supply/vendors/InventoryVendorsList');
const SupplierInvoicesList = () => import(/* webpackChunkName: "supplier_invoices" */ '@/apps/admin/views/admin/inventory/supply/supplier-invoices/SupplierInvoicesList');

export default [
    {
        path: 'suppliers',
        name: 'admin.inventory.supply.suppliers',
        component: SuppliersList,
        meta: {
            keepAlive: true,
            componentName: 'SuppliersList',
        },
    },
    {
        path: 'suppliers--view',
        name: 'admin.inventory.supply.suppliers.view',
        component: XTwigPage,
    },
    {
        path: 'vendor',
        name: 'admin.inventory.supply.vendor',
        component: InventoryVendorsList,
        meta: {
            keepAlive: true,
            componentName: 'InventoryVendorsList',
        },
    },
    {
        path: 'vendor--view',
        name: 'admin.inventory.supply.vendor.view',
        component: XTwigPage,
    },
    {
        path: 'supplier-invoices',
        name: 'admin.inventory.supply.supplier_invoices',
        component: SupplierInvoicesList,
        meta: {
            keepAlive: true,
            componentName: 'SupplierInvoicesList',
        },
    },
];
