import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        delimiter: '--',
        path: 'settings',
        name: 'admin.config.crm.settings',
        component: XTwigPage,
    },
    {
        path: 'finance',
        name: 'admin.config.crm.finance',
        component: XTwigPage,
    },
    {
        path: 'sign-up',
        name: 'admin.config.crm.sign_up',
        component: XTwigPage,
    },
    {
        path: 'leads',
        name: 'admin.config.crm.leads',
        component: XTwigPage,
    },
    {
        path: 'convert-status',
        name: 'admin.config.crm.convert_status',
        component: XTwigPage,
    },
    {
        path: 'leads-pipeline',
        name: 'admin.config.crm.leads_pipeline',
        component: XTwigPage,
    },
];
