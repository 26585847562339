import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: '',
        name: 'admin.networking.acs.devices_view',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'wan',
        name: 'admin.networking.acs.devices_view.wan',
        component: XTwigPage,
        meta: {
            title: ['networking', 'WAN'],
        },
    },
    {
        delimiter: '--',
        path: 'lan',
        name: 'admin.networking.acs.devices_view.lan',
        component: XTwigPage,
        meta: {
            title: ['networking', 'LAN'],
        },
    },
    {
        delimiter: '--',
        path: 'nat',
        name: 'admin.networking.acs.devices_view.nat',
        component: XTwigPage,
        meta: {
            title: ['networking', 'NAT'],
        },
    },
    {
        delimiter: '--',
        path: 'firewall',
        name: 'admin.networking.acs.devices_view.firewall',
        component: XTwigPage,
        meta: {
            title: ['networking', 'Firewall'],
        },
    },
    {
        delimiter: '--',
        path: 'diagnose',
        name: 'admin.networking.acs.devices_view.diagnose',
        component: XTwigPage,
        meta: {
            title: ['networking', 'Diagnoses'],
        },
    },
    {
        delimiter: '--',
        path: 'activity',
        name: 'admin.networking.acs.devices_view.activity',
        component: XTwigPage,
        meta: {
            title: ['networking', 'Activity'],
        },
    },
];
