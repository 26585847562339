export default class RefreshDeviceService {
    constructor(groupId, deviceId) {
        this.groupId = groupId;
        this.type = '';
        this.deviceId = deviceId;
        this.provisionConfigLoadingType = '';
        this.step = 1;
    }

    refresh() {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/admin/config/networking/acs--provision-flow-refresh-config',
                data: this.getRequestParams(),
                success(response) {
                    if (response.result) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                },
                failed(response) {
                    reject(response);
                },
            });
        });
    }

    getRequestParams() {
        return {
            id: this.groupId,
            type: this.type,
            provisionConfigLoadingType: this.provisionConfigLoadingType,
            step: this.step,
            device_id: this.deviceId,
        };
    }

    setProvisionConfigLoadingType(type) {
        this.provisionConfigLoadingType = type;
        return this;
    }

    setStep(step) {
        this.step = step;
        return this;
    }
}
