export default {
    data() {
        return {
            throttleTimers: {},
        };
    },
    methods: {
        throttle(callback, delay, key) {
            if (this.throttleTimers[key]) {
                return;
            }
            this.throttleTimers[key] = setTimeout(() => {
                callback();
                delete this.throttleTimers[key];
            }, delay);
        },
    },
    beforeDestroy() {
        for (let key in this.throttleTimers) {
            clearTimeout(this.throttleTimers[key]);
        }
    },
};
