(function( $ ) {
    $.fn.timeSlider = function(settings) {
        var self = this;
        var defaultSliderOptions = {
            range: true,
            step: 15,
            min: 0,
            max: 1440,
            tickInterval: 120,
            tickLabels: null,
            values: null,
            slide: slideHandler
        };
        var defaultOptions = {
            timeFormat: '24H',
            sliderOptions: null,
            flipInput: null,
            textInput: null,
            fromInput: null,
            toInput: null,
            textTemplate: "<b>From</b><span class='text-primary'> <b>{valueFrom}</span></b> <b>To</b> <span class='text-primary'><b>{valueTo}</span></b>"
        };
        var options = $.extend(defaultOptions, settings);
        options.sliderOptions = $.extend(defaultSliderOptions, options.sliderOptions);

        if (options.sliderOptions.tickLabels === null) {
            options.sliderOptions.tickLabels = getLabels();
        }

        if (options.sliderOptions.values === null) {
            options.sliderOptions.values = getSliderValues();
        }

        function minToHm(value, formatted) {
            var minutes = value % 60;
            var hours = Math.floor(value / 60);
            var ampmSwitch = "";

            minutes = (minutes < 10 ? '0' : '') + minutes;
            hours = (hours < 10 ? '0' : '') + hours;
            if (formatted && options.timeFormat === '12H') {
                ampmSwitch = (hours > 12) ? " PM":" AM";

                if (hours === 24) {
                    ampmSwitch = ' AM';
                }

                if (hours === 12) {
                    ampmSwitch = ' PM';
                }

                if (hours > 12) {
                    hours -= 12;
                }

                if (hours === 0) {
                    hours = 12;
                }
            }

            return hours + ':' + minutes + ampmSwitch;
        }

        function hmToMin(value) {
            value = value.split(':');
            return (parseInt(value[0], 10) * 60) + parseInt(value[1], 10);
        }

        function getLabels() {
            var res = Object();
            for (var i = options.sliderOptions.min; i <= options.sliderOptions.max; i += options.sliderOptions.step) {
                res[i] = minToHm(i,1);
            }

            return res;
        }

        function updateIntervalTime() {
            var valueFrom = options.fromInput.val();
            var valueTo = options.toInput.val();

            valueFrom = minToHm(hmToMin(valueFrom), 1);
            valueTo = minToHm(hmToMin(valueTo), 1);

            // Prepare text template
            var infoString = options.textTemplate.replace('{valueFrom}', valueFrom).replace('{valueTo}', valueTo);
            options.textInput.html(infoString);
        }

        function slideHandler(event, ui) {
            var value1 = minToHm(ui.values[0]);
            var value2 = minToHm(ui.values[1]);

            if (options.flipInput.is(':checked')) {
                options.fromInput.val(value2);
                options.toInput.val(value1);
            }
            else {
                options.fromInput.val(value1);
                options.toInput.val(value2);
            }
            updateIntervalTime();
        }

        function getSliderValues() {
            var from = options.fromInput.val();
            var to = options.toInput.val();
            if (from > to) {
                // flip
                var temp = to;
                to = from;
                from = temp;
                options.flipInput.prop('checked', true);
            }
            return [hmToMin(from), hmToMin(to)];
        }

        var updateVector = function() {
            if (options.flipInput.prop('checked')) {
                self.css({
                    "background": "#a2c4fb"
                });
                self.find('.ui-slider-range').css({
                    "background": "#ccc url(/css/images/ui-bg_highlight-soft_75_cccccc_1x100.png) 50% 50% repeat-x"
                });
            }
            else {
                self.css({
                    "background": "#ccc url(/css/images/ui-bg_highlight-soft_75_cccccc_1x100.png) 50% 50% repeat-x"
                });
                self.find('.ui-slider-range').css({
                    "background": "#a2c4fb"
                });
            }
        };

        options.flipInput.change(function () {
            var swap = options.fromInput.val();
            options.fromInput.val(options.toInput.val());
            options.toInput.val(swap);
            updateIntervalTime();
            updateVector();
        });

        this.labeledslider(options.sliderOptions);
        updateIntervalTime();
    };
})(jQuery);