import { SchedulingHelpers as helper } from '@/apps/admin/services/scheduling/SchedulingHelpers';
import Date from '@/apps/admin/services/scheduling/Date';

export default {
    created() {
        remove_socket_listener('scheduling_calendar_event');
        add_socket_listener('scheduling_calendar_event', ({ action, event }) => {
            if (action !== 'edit') {
                return false;
            }
            if (event.id !== this.model.id) {
                return false;
            }
            for (let key in event) {
                if (key === 'scheduled_from' || key === 'scheduled_to') {
                    this.model.is_scheduled = true;
                    if (event[key]) {
                        this.model[key] = helper.parseDate(new Date(event[key]));
                    }
                } else {
                    this.model[key] = event[key];
                }
            }
        });
    },
};
