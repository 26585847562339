<template>
    <div class="sidebar-content-inner">
        <iframe
            id="splynx-feedback-widget-frame"
            width="100%"
            :src="src"
            frameborder="0"
        />
    </div>
</template>

<script>
export default {
    // This component used in mobile browsers
    name: 'XSupportPanel',
    props: {
        src: String,
    },
};
</script>
