import { stateToGetters } from '@/apps/admin/store/helpers';
import {
    SET_ADMIN_SCHEDULING_PAGE_DATA,
    SET_ADMIN_PAIRED_CALENDARS_MODAL_DATA,
    SET_ADMIN_PAIRED_CALENDARS_DELETE_MODAL_DATA,
} from '../../mutation-types';

const state = {
    model: null,
    without_google_account: false,
    google_account_email: '',
    google_account_is_enabled: null,
    data_table_headers: [],
    splynx_domain: '',
    pairedCalendarsModal: {
        model: {},
        calendars_list: {},
        google_calendars_list: {},
        attribute_labels: {},
        attributes_meta: {},
        is_new_record: false,
        empty_account: false,
        error: false,
        message: '',
    },
    deleteModal: {
        attributes_meta: {},
    },
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadPageData({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/profile--load-scheduling-page-data',
                success: (response) => {
                    commit(SET_ADMIN_SCHEDULING_PAGE_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    disconnectAccount() {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/profile--scheduling-disconnect-google-account',
                type: 'POST',
                success: (response) => {
                    show_success(response.message, 4);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    loadPairedCalendarsData({ commit }, data) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: data.url,
                success: (response) => {
                    commit(SET_ADMIN_PAIRED_CALENDARS_MODAL_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    loadPairedCalendarsDeleteData({ commit }, data) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: data.url,
                success: (response) => {
                    commit(SET_ADMIN_PAIRED_CALENDARS_DELETE_MODAL_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_ADMIN_SCHEDULING_PAGE_DATA](state, data) {
        for (let k in data) {
            state[k] = data[k];
        }
    },
    [SET_ADMIN_PAIRED_CALENDARS_MODAL_DATA](state, data) {
        for (let k in data) {
            state.pairedCalendarsModal[k] = data[k];
        }
    },
    [SET_ADMIN_PAIRED_CALENDARS_DELETE_MODAL_DATA](state, data) {
        for (let k in data) {
            state.deleteModal[k] = data[k];
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
