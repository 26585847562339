<template>
    <a
        class="attachment-image pws-open"
        :data-is-pws="isPws"
        :data-pws-download-url="downloadUrl"
        :data-pws-ratio="ratio"
        :data-pws-title="title"
        :data-pws-type="type"
        :data-pws-url="url"
    >{{ title }}</a>
</template>

<script>
import ChatMessageAttachmentMixin from '@/apps/admin/mixins/messengers/message/chat-message-attachment-mixin';

export default {
    name: 'ChatAttachmentIframe',
    mixins: [ChatMessageAttachmentMixin],
};
</script>
