import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: 'add',
        name: 'admin.config.extended_module.add',
        component: XTwigPage,
    },
    {
        path: 'list',
        name: 'admin.config.extended_module.list',
        component: XTwigPage,
    },
    {
        path: 'view',
        name: 'admin.config.extended_module.view',
        component: XTwigPage,
    },
];
