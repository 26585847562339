import { stateToGetters } from '@/apps/admin/store/helpers';
import { toCamelCase } from '@/utils/functions';
import { SET_LEADS_INFO } from '@/apps/admin/store/mutation-types';

const state = () => ({
    model: {},
    showDocumentsTab: false,
    showQuotesTab: false,
    showCommunicationTab: false,
    showMessengersTab: false,
    existsMessengerAddons: false,
    showCustomerTab: false,
    addonTabs: [],
});

const getters = {
    ...stateToGetters(state()),
};

const actions = {
    loadLeadInfo({ commit, state }, id) {
        if (!empty(id)) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: `/admin/crm/leads/view--get-page-data?id=${id}`,
                    success: (response) => {
                        commit(SET_LEADS_INFO, response);
                        resolve();
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
            });
        } if (empty(state.model)) {
            show_warning(t('crm', 'Lead is not selected'), 3);
        }
    },
};

const mutations = {
    [SET_LEADS_INFO](state, data) {
        for (let k in data) {
            state[toCamelCase(k)] = data[k];
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
