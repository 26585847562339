const NetworkSitesList = () => import(/* webpackChunkName: "network-sites" */ '@/apps/admin/views/admin/networking/network-sites/NetworkSitesList');
const NetworkSitesViewPage = () => import(/* webpackChunkName: "scheduling-map" */ '@/apps/admin/views/admin/networking/network-sites/view/NetworkSitesView.vue');

export default [
    {
        path: '',
        name: 'admin.networking.network_sites',
        component: NetworkSitesList,
        meta: {
            keepAlive: true,
            componentName: 'NetworkSitesPage',
            title: ['networking', 'Network sites'],
            breadcrumbs: [
                {
                    title: ['networking', 'Networking'],
                    url: '/admin/networking/network-sites',
                },
                {
                    title: ['networking', 'Network sites'],
                    url: '/admin/networking/network-sites',
                },
            ],
        },
    },
    {
        path: 'view',
        name: 'admin.networking.network_sites.view',
        component: NetworkSitesViewPage,
        meta: {
            componentName: 'NetworkSitesViewPage',
            title: ['networking', 'Network sites'],
            breadcrumbs: [
                {
                    title: ['networking', 'Networking'],
                    url: '/admin/networking/network-sites',
                },
                {
                    title: ['networking', 'Network sites'],
                    url: '/admin/networking/network-sites',
                },
            ],
        },
    },
];
