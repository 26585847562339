import Ticket from '@/apps/admin/services/tickets/Ticket';
import MessageLocation from '@/apps/admin/services/messengers/MessageLocation';
import MessageAuthor from '@/apps/admin/services/messengers/MessageAuthor';
import Attachments from '@/apps/admin/services/messengers/attachments/Attachments';

export default class Message {
    static TYPE_OUT = 'out';

    static NOTIFICATION_TYPE = 'message_and_action';

    static TYPE_IN = 'in';

    constructor({
        id, conversation_id, type, author, phone, created_at, updated_at, content, status, ticket, attachments, location,
        activities,
    } = {}) {
        this.id = id;
        this.conversationId = conversation_id;
        this.type = type;
        this.status = status;
        this.author = author;
        this.phone = phone;
        this.createdAt = created_at;
        this.updatedAt = updated_at;
        this.content = content;
        this.activities = activities;

        this.ticket = ticket ?? new Ticket();
        this.location = location ?? new MessageLocation();
        this.author = author ?? new MessageAuthor();
        this.attachments = new Attachments(attachments);
    }

    withMappedStatus(statuses) {
        this.status = statuses[this.id] ?? this.status;
        return this;
    }
}
