import MessagesRoutes from './support/messages';

const NewsPage = () => import(/* webpackChunkName: "support_news" */ '@/apps/admin/views/admin/support/News');
const InboxList = () => import(/* webpackChunkName: "support_inbox" */ '@/apps/admin/views/admin/support/inbox/InboxList');
const MessengersPage = () => import(/* webpackChunkName: "support_inbox" */ '@/apps/admin/views/admin/support/messengers/MessengersPage');

export default [
    {
        path: 'inbox',
        name: 'admin.support.inbox',
        component: InboxList,
        meta: {
            keepAlive: true,
            componentName: 'InboxList',
        },
    },
    {
        path: 'messages',
        children: MessagesRoutes,
    },
    {
        path: 'messengers',
        name: 'admin.support.messengers',
        component: MessengersPage,
        meta: {
            breadcrumbs: [
                { title: ['common', 'Messages'], url: '/admin/support/messengers' },
                { title: ['common', 'Messengers'], url: '/admin/support/messengers' },
            ],
            title: ['support', 'Messengers'],
            keepAlive: true,
            componentName: 'MessengersPage',
        },
    },
    {
        path: 'news',
        name: 'admin.support.news',
        component: NewsPage,
        meta: {
            breadcrumbs: [
                { title: ['common', 'Messages'], url: '/admin/support/news' },
                { title: ['common', 'News'], url: '/admin/support/news' },
            ],
            title: ['support', 'News'],
            keepAlive: true,
            componentName: 'NewsPage',
        },
    },
];
