import AddOnsList from '@/apps/admin/views/admin/config/integrations/modules/add-ons/AddOnsList';
import XTwigPage from '@/components/base/XTwigPage';
import ModulesList from '@/apps/admin/views/admin/config/integrations/modules/list/ModulesList';

const EntryPoints = () => import(/* webpackChunkName: "entry_points" */ '@/apps/admin/views/admin/config/integrations/modules/EntryPoints');
const MainInformation = () => import(/* webpackChunkName: "entry_points" */ '@/apps/admin/views/admin/config/integrations/modules/add-ons/edit/MainInformation');
const InstallModule = () => import(/* webpackChunkName: "entry_points" */ '@/apps/admin/views/admin/config/integrations/modules/InstallModule');

export default [
    {
        path: 'main',
        name: 'admin.config.modules.main',
        component: XTwigPage,
    },
    {
        path: 'add-ons',
        name: 'admin.config.modules.add_ons',
        component: AddOnsList,
        meta: {
            keepAlive: true,
            componentName: 'AddOnsList',
        },
    },
    {
        path: 'install',
        name: 'admin.config.modules.install',
        component: InstallModule,
        meta: {
            breadcrumbs: [{ title: ['config', 'Install module'], url: '/admin/config/modules/list' }],
            title: ['config', 'Install module'],
        },
    },
    {
        path: 'list',
        name: 'admin.config.modules.list',
        component: ModulesList,
        meta: {
            breadcrumbs: [{ title: ['common', 'Modules list'], url: '/admin/config/modules/list' }],
            keepAlive: true,
            componentName: 'ModulesList',
        },
    },
    {
        path: 'list--edit',
        name: 'admin.config.modules.edit_config',
        component: MainInformation,
        meta: {
            breadcrumbs: [
                { title: ['common', 'Modules list'], url: '/admin/config/modules/list' },
            ],
        },
    },
    {
        path: 'list--edit-entry-points',
        name: 'admin.config.modules.entry_points',
        component: EntryPoints,
        meta: {
            breadcrumbs: [{ title: ['common', 'Modules list'], url: '/admin/config/modules/list' }],
            title: ['config', 'Points list'],
        },
    },
];
