import XTwigPage from '@/components/base/XTwigPage';
import AcsRouter from './networking/acs';

const RadiusFailover = () => import(/* webpackChunkName: "networking_config" */ '@/apps/admin/views/admin/config/networking/radius-failover/RadiusFailover');
const NasTypesList = () => import(/* webpackChunkName: "networking_config" */ '@/apps/admin/views/admin/config/networking/nas-types/NasTypesList');
const TrafficClassList = () => import(/* webpackChunkName: "networking_config" */ '@/apps/admin/views/admin/config/networking/traffic-class/TrafficClassList');
const IpNetworkCategoriesList = () => import(/* webpackChunkName: "networking_config" */ '@/apps/admin/views/admin/config/networking/ip-network-categories/IpNetworkCategoriesList');
const IpHostCategories = () => import(/* webpackChunkName: "networking_config" */ '@/apps/admin/views/admin/config/networking/ip-host-categories/IpHostCategories');
const MonitoringList = () => import(/* webpackChunkName: "networking_config" */ '@/apps/admin/views/admin/config/networking/monitoring/MonitoringList');

export default [
    {
        delimiter: '--',
        path: 'settings',
        name: 'admin.config.networking.settings',
        component: XTwigPage,
    },
    {
        path: 'radius',
        name: 'admin.config.networking.radius',
        component: XTwigPage,
    },
    {
        path: 'fup',
        name: 'admin.config.networking.fup',
        component: XTwigPage,
    },
    {
        path: 'nas-types',
        name: 'admin.config.networking.nas_types',
        component: NasTypesList,
        meta: {
            keepAlive: true,
            componentName: 'NasTypesList',
        },
    },
    {
        path: 'radius-failover',
        name: 'admin.config.networking.radius_failover',
        component: RadiusFailover,
        meta: {
            keepAlive: true,
            componentName: 'RadiusFailover',
        },
    },
    {
        path: 'ip-network-categories',
        name: 'admin.config.networking.ip_network_categories',
        component: IpNetworkCategoriesList,
        meta: {
            keepAlive: true,
            componentName: 'IpNetworkCategoriesList',
        },
    },
    {
        path: 'ip-host-categories',
        name: 'admin.config.networking.ip_host_categories',
        component: IpHostCategories,
        meta: {
            keepAlive: true,
            componentName: 'IpHostCategories',
        },
    },
    {
        path: 'monitoring',
        name: 'admin.config.networking.monitoring',
        component: MonitoringList,
        meta: {
            keepAlive: true,
            componentName: 'MonitoringList',
        },
    },
    {
        path: 'acs',
        name: 'admin.config.networking.acs',
        children: AcsRouter,
        meta: {
            breadcrumbs: [{ title: ['config', 'ACS'], url: '/admin/config/networking/acs' }],
        },
    },
    {
        path: 'traffic-class',
        name: 'admin.config.networking.traffic_class',
        component: TrafficClassList,
        meta: {
            keepAlive: true,
            componentName: 'TrafficClassList',
        },
    },
];
