import { stateToGetters } from '@/apps/admin/store/helpers';
import GeoData from '@/apps/admin/services/common/GeoData';
import { formattedResponse } from '@/utils/functions';

const state = {
    coordinate: '',
    address: '',
    mapConfig: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    setCoordinateAction({ commit }, coordinate) {
        commit('setCoordinate', coordinate);
    },
    setAddressAction({ commit }, address) {
        commit('setAddress', address);
    },
    setMapConfigAction({ commit }, mapConfig) {
        commit('setMapConfig', mapConfig);
    },
    async setGeoData({ dispatch }, data) {
        let service = new GeoData('/admin/tools--get-geo-data', data);
        let { result, coordinates, message } = await service.getGeoData();
        if (result) {
            dispatch('setCoordinateAction', coordinates);
            show_success(message, 5);
        } else {
            show_error(message, 5);
        }
        return { result, coordinates, message };
    },
    async loadMapConfig({ dispatch }) {
        let service = new GeoData('/admin/get-data--map-config');
        let data = await service.getMapConfig();
        dispatch('setMapConfigAction', formattedResponse(data));
    },
};

const mutations = {
    setCoordinate(state, coordinate) {
        state.coordinate = coordinate;
    },
    setAddress(state, address) {
        state.address = address;
    },
    setMapConfig(state, mapConfig) {
        state.mapConfig = mapConfig;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
