import XTwigPage from '@/components/base/XTwigPage';

const RefillCardsList = () => import(/* webpackChunkName: "refill_cards" */ '@/apps/admin/views/admin/finance/refill-cards/RefillCardsList');

export default [
    {
        path: 'generate',
        name: 'admin.finance.refill_cards.generate',
        component: XTwigPage,
    },
    {
        path: 'series',
        name: 'admin.finance.refill_cards.series',
        component: RefillCardsList,
        meta: {
            keepAlive: true,
            componentName: 'RefillCardsList',
        },
    },
    {
        path: 'view',
        name: 'admin.finance.refill_cards.view',
        component: XTwigPage,
    },
];
