const getMenu = (state) => state.menu;

const admin = (state) => state.admin;

const headerShortcuts = (state) => state.headerShortcuts;

const logo = (state) => state.logo;

const footer = (state) => state.footer;

const license = (state) => state.license;

const serverSideConfig = (state) => state.serverSideConfig;

const pageTitle = (state) => state.pageTitle;

const refreshRouteKey = (state) => state.refreshRouteKey;

const documentationConfig = (state) => state.documentationConfig;

const onBoardingSteps = (state) => state.onBoardingSteps;
const firstDayOfWeek = (state) => state.firstDayOfWeek;
const weekends = (state) => state.weekends;

const referencesApp = (state) => state.referencesApp;

const showPageError = (state) => state.showPageError;

export default {
    getMenu,
    admin,
    headerShortcuts,
    logo,
    footer,
    serverSideConfig,
    license,
    pageTitle,
    refreshRouteKey,
    documentationConfig,
    onBoardingSteps,
    firstDayOfWeek,
    weekends,
    referencesApp,
    showPageError,
};
