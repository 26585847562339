import {
    SET_STAGES,
    SET_LEADS,
    SET_BOARD_LEADS,
    SET_FILTERED_LEADS,
    SET_BOARD_FILTERED_LEADS,
    RESET_FILTERED_LEADS,
    MOVE_LEAD,
    SET_LEAD_INFO,
    DELETE_LEAD,
    UPDATE_LEAD,
    SET_NEED_UPDATE_STATE,
} from '../mutation-types';

const state = {
    leads: {},
    stages: {},
    filteredLeads: {},
    needUpdate: false,
};

const getters = {
    list(state) {
        return state.leads;
    },
    filteredList(state) {
        return state.filteredLeads;
    },
    stages(state) {
        return state.stages;
    },
};

const actions = {
    loadBoardInfo({ commit }, { simpleParams, advancedParams }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/crm/leads/list--load-data-for-board?${simpleParams}`,
                data: advancedParams,
                method: 'POST',
                success: (response) => {
                    clearTimeout(window.leadsNeedReloadMyTimeout);
                    if (response.result) {
                        let leads = Object.keys(response.message.leads).length > 0 ? response.message.leads : {};
                        let stages = Object.values(response.message.stages);

                        // Fill empty boards
                        stages.forEach((stage) => {
                            if (!isset(leads, stage.id)) {
                                leads[stage.id] = [];
                            }
                        });
                        commit(SET_LEADS, leads);
                        commit(SET_FILTERED_LEADS, { ...leads });
                        commit(SET_STAGES, stages);
                        resolve();
                    }
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    saveItemStatus({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/crm/leads/view--change-status',
                method: 'POST',
                data: {
                    newStatusId: payload.newStatus,
                    leadsIds: [payload.itemId],
                },
                success: (response) => {
                    clearTimeout(window.leadsNeedReloadMyTimeout);
                    if (!response.result) {
                        // Move back if fail
                        reject(response.message);
                    } else {
                        commit(MOVE_LEAD, payload);

                        if (isset(response.message, 'lead')) {
                            commit(SET_LEAD_INFO, {
                                board: payload.newStatus,
                                leadId: payload.leadId,
                                info: response.message.lead,
                            });
                        }
                        resolve(response);
                    }
                },
            });
        });
    },
    moveAllBoard({ commit, state }, { toStatus, fromStatus }) {
        return new Promise((resolve, reject) => {
            if (!isset(state.leads, fromStatus)) {
                resolve();
                return;
            }
            if (!isset(state.leads, toStatus)) {
                commit(SET_BOARD_LEADS, { board: toStatus, items: [] });
            }

            let leadsIds = [];
            state.leads[fromStatus].forEach((lead) => {
                leadsIds.push(lead.id);
            });

            $.ajax({
                url: '/admin/crm/leads/view--change-status',
                method: 'POST',
                data: {
                    newStatusId: toStatus,
                    leadsIds,
                },
                success: (response) => {
                    clearTimeout(window.leadsNeedReloadMyTimeout);
                    if (isset(response, 'result') && !response.result) {
                        reject(response.message);
                        return;
                    }

                    let newBoardLeads = state.leads[toStatus].slice();
                    state.leads[fromStatus].forEach((lead) => {
                        newBoardLeads.push(lead);
                    });

                    commit(SET_BOARD_LEADS, { board: toStatus, items: newBoardLeads });
                    commit(SET_BOARD_LEADS, { board: fromStatus, items: [] });

                    commit(SET_BOARD_FILTERED_LEADS, { board: toStatus, items: newBoardLeads.slice() });
                    commit(SET_BOARD_FILTERED_LEADS, { board: fromStatus, items: [] });

                    resolve(response);
                },
            });
        });
    },
    setNeedUpdateState({ commit }, state) {
        commit(SET_NEED_UPDATE_STATE, state);
    },
};

const mutations = {
    [SET_NEED_UPDATE_STATE](state, show) {
        state.needUpdate = show;
    },
    [SET_LEADS](state, leads) {
        state.leads = leads;
    },
    [SET_BOARD_LEADS](state, payload) {
        if (typeof state.leads[payload.board] === 'undefined') {
            state.leads[payload.board] = [];
        }

        state.leads[payload.board] = payload.leads;
    },
    [SET_FILTERED_LEADS](state, leads) {
        state.filteredLeads = leads;
    },
    [SET_BOARD_FILTERED_LEADS](state, payload) {
        if (typeof state.filteredLeads[payload.board] === 'undefined') {
            state.filteredLeads[payload.board] = [];
        }

        state.filteredLeads[payload.board] = payload.items;
    },
    [RESET_FILTERED_LEADS](state) {
        state.filteredLeads = { ...state.leads };
    },
    [SET_STAGES](state, stages) {
        state.stages = stages;
    },
    [MOVE_LEAD](state, {
        oldStatus, newStatus, leadId, position,
    }) {
        let oldLead = state.leads[oldStatus].find((lead) => parseInt(lead.id) === parseInt(leadId));
        if (!isset(state.leads, newStatus)) {
            state.leads[newStatus] = [];
        }

        if (!empty(oldLead)) {
            moveLead(state.leads, oldStatus, newStatus, state.leads[oldStatus].indexOf(oldLead), oldLead, position);
        }
    },
    [SET_LEAD_INFO](state, { board, leadId, info }) {
        updateLead(state.leads, { board, leadId, info });
        updateLead(state.filteredLeads, { board, leadId, info });
    },
    [DELETE_LEAD](state, { board, leadId }) {
        deleteLead(state.leads, { board, leadId });
        deleteLead(state.filteredLeads, { board, leadId });
    },
    [UPDATE_LEAD](state, { lead }) {
        updateWithMoveLead(state.leads, lead);
        updateWithMoveLead(state.filteredLeads, lead);
    },
};

function findLead(leadsVar, leadId) {
    leadId = parseInt(leadId);

    for (let status in leadsVar) {
        if (!Object.prototype.hasOwnProperty.call(leadsVar, status)) {
            continue;
        }

        let boardLeads = leadsVar[status];
        if (boardLeads.length <= 0) {
            continue;
        }

        for (let index = 0; index < boardLeads.length; index++) {
            if (parseInt(boardLeads[index].id) === leadId) {
                return {
                    board: status,
                    lead: boardLeads[index],
                    index,
                };
            }
        }
    }

    return null;
}

function updateLead(leadsVar, { board, leadId, info }) {
    let lead = leadsVar[board].find((item) => parseInt(item.id) === parseInt(leadId));
    let index = leadsVar[board].indexOf(lead);
    if (index !== -1) {
        leadsVar[board][index] = info;
    }
}

function updateWithMoveLead(leadsVar, lead) {
    let filteredResult = findLead(leadsVar, lead.id);
    if (filteredResult !== null) {
        leadsVar[filteredResult.board].splice(filteredResult.index, 1, { ...lead });

        if (parseInt(filteredResult.board) !== parseInt(lead.stage)) {
            moveLead(leadsVar, filteredResult.board, lead.stage, filteredResult.index, { ...lead }, 0);
        }
    }
}

function deleteLead(leadsVar, { board, leadId }) {
    let lead = leadsVar[board].find((item) => parseInt(item.id) === parseInt(leadId));
    let index = leadsVar[board].indexOf(lead);
    if (index !== -1) {
        leadsVar[board].splice(index, 1);
    } else {
        Object.entries(leadsVar).forEach((column) => {
            const [key, value] = column;
            Object.entries(value).forEach((item) => {
                const [itemKey, itemValue] = item;
                if (parseInt(leadId) === parseInt(itemValue.id)) {
                    leadsVar[key].splice(itemKey, 1);
                }
            });
        });
    }
}

function moveLead(leadsVar, fromStatus, toStatus, index, lead, position) {
    // Delete old lead
    leadsVar[fromStatus].splice(index, 1);

    // Add lead to target column
    leadsVar[lead.stage].splice(position, 0, { ...lead });
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
