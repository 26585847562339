import {
    SIDEBAR_LOAD,
    SIDEBAR_SET_OPTIONS,
} from '@/apps/admin/store/mutation-types';

const state = {
    sidebarState: false,
    sidebarOptions: {
        title: '',
        component: '',
        props: '',
    },
};

const actions = {
    showSidebar({ commit }, sidebarData) {
        commit(SIDEBAR_LOAD, true);
        commit(SIDEBAR_SET_OPTIONS, sidebarData);
    },
    hideSidebar({ dispatch }, callbacks) {
        dispatch('checkUnsavedData', callbacks);
    },
    toggleSidebar({ commit, state }) {
        commit(SIDEBAR_LOAD, !state.sidebarState);
    },
    checkUnsavedData({ commit }, callbacks) {
        let contentContainer = $('.sidebar-wrapper');
        if (window.isHaveUnsavedData(contentContainer)) {
            window.showUnsavedDataDialog(
                contentContainer,
                () => {
                    window.forgetUnsavedData(contentContainer);
                    if (typeof callbacks == 'string') {
                        new Function(callbacks)();
                    }
                    if (typeof callbacks == 'function') {
                        callbacks();
                    }
                    if (callbacks instanceof Array) {
                        callbacks.forEach((callback) => {
                            if (typeof callback == 'string') {
                                new Function(callback)();
                                return true;
                            }
                            if (typeof callback == 'function') {
                                callback();
                            }
                        });
                    }
                    commit(SIDEBAR_LOAD, false);
                },
                () => false,
                window.xApp.$splang.t('common', 'Are you sure you want to close dialog? All unsaved data will be lost.'),
            );
        } else {
            commit(SIDEBAR_LOAD, false);
        }
    },
};

const getters = {
    sidebarState(state) {
        return state.sidebarState;
    },
    sidebarOptions(state) {
        return state.sidebarOptions;
    },
};

const mutations = {
    [SIDEBAR_LOAD](state, sidebarState) {
        state.sidebarState = sidebarState;
    },
    [SIDEBAR_SET_OPTIONS](state, sidebarOptions) {
        state.sidebarOptions = sidebarOptions;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
