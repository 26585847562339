<template>
    <div>
        <chat-box-create-head @back="$emit('back')" />
        <chat-box-messenger-item
            v-for="messenger in messengers"
            :key="messenger.id"
            :name="messenger.name"
            :title="messenger.title"
            @click.native="viewForm(messenger)"
        />
    </div>
</template>

<script>
import ChatBoxCreateHead from '@/components/common/XMessengersChatbox/create/ChatBoxCreateHead';
import ChatBoxMessengerItem from '@/components/common/XMessengersChatbox/create/ChatBoxMessengerItem';

export default {
    name: 'ChatBoxCreate',
    props: {
        messengers: Array,
    },
    methods: {
        viewForm(messenger) {
            this.$emit('create-form', {
                messengerId: messenger.id,
                messengerTitle: messenger.title,
                point: String(messenger.start_conversation.point_meta.point),
                module: messenger.start_conversation.point_meta.module,
            });
        },
    },
    components: { ChatBoxMessengerItem, ChatBoxCreateHead },
};
</script>
