const state = {
    handlers: {},
};

const getters = {
    getCallbacksForEvent: (state) => (name, identifier) => {
        const callbacks = [];
        if (!state.handlers[identifier]) {
            return callbacks;
        }
        state.handlers[identifier].forEach((handler) => {
            Array.from(handler.values()).forEach((callback) => {
                if (callback.name.replace('bound ', '') === name) {
                    callbacks.push(callback);
                }
            });
        });
        return callbacks;
    },
};

const actions = {
    listenChats({ getters }) {
        add_socket_listener('messenger_chat_message', ({ meta, data }) => {
            const { messenger_id, chat_id } = meta;
            getters.getCallbacksForEvent('onNewItem', `${messenger_id}_${chat_id}`).forEach((callback) => (callback)(data));
        });
        add_socket_listener('messenger_chat_close', ({ meta, data }) => {
            const { messenger_id, chat_id } = meta;
            getters.getCallbacksForEvent('onConversationClose', `${messenger_id}_${chat_id}`).forEach((callback) => (callback)(data));
        });
        add_socket_listener('messenger_chat_message_attachment_refresh', ({ meta, data }) => {
            const { messenger_id, chat_id } = meta;
            getters.getCallbacksForEvent('onAttachmentUpdate', `${messenger_id}_${chat_id}`).forEach((callback) => (callback)(data));
        });
        add_socket_listener('messenger_chat_add_activities_for_last_message', ({ meta, data }) => {
            const { messenger_id, chat_id } = meta;
            getters.getCallbacksForEvent('onAddActivitiesForLastMessage', `${messenger_id}_${chat_id}`).forEach((callback) => (callback)(data));
        });
        add_socket_listener('messenger_chat_message_status_update', ({ meta, data }) => {
            const { messenger_id, chat_id } = meta;
            getters.getCallbacksForEvent('statusUpdate', `${messenger_id}_${chat_id}`).forEach((callback) => (callback)(data));
        });
        add_socket_listener('messenger_chat_ticked_linked', ({ meta, data }) => {
            const { messenger_id, chat_id } = meta;
            getters.getCallbacksForEvent('onTicketLinked', `${messenger_id}_${chat_id}`).forEach((callback) => (callback)(data));
        });
        add_socket_listener('messenger_chat_ticked_unlinked', ({ meta, data }) => {
            const { messenger_id, chat_id } = meta;
            getters.getCallbacksForEvent('onTicketUnlinked', `${messenger_id}_${chat_id}`).forEach((callback) => (callback)(data));
        });
        add_socket_listener('messenger_chat_reassign', ({ meta, data }) => {
            const { messenger_id, chat_id } = meta;
            getters.getCallbacksForEvent('onReassign', `${messenger_id}_${chat_id}`).forEach((callback) => (callback)(data));
        });
    },
    addChatHandlers({ commit }, { callbacks, ids }) {
        commit('ADD_CHAT_HANDLERS', { ids, callbacks });
    },
};

const mutations = {
    ADD_CHAT_HANDLERS(state, { ids, callbacks }) {
        if (!state.handlers[`${ids.messenger}_${ids.chat}`]) {
            state.handlers[`${ids.messenger}_${ids.chat}`] = new Map();
            state.handlers[`${ids.messenger}_${ids.chat}`].set(callbacks, callbacks);
        } else {
            state.handlers[`${ids.messenger}_${ids.chat}`].set(callbacks, callbacks);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
