/**
 * From {props} frontend/apps/admin/views/admin/customers/view/communication/ChatView.vue
 * @chatId String
 */

import Message from '@/apps/admin/services/messengers/Message';

export default {
    created() {
        this.initMessengerNotificationInterceptor();
    },
    beforeDestroy() {
        this.removeMessengerNotificationInterceptor();
    },
    methods: {
        removeMessengerNotificationInterceptor() {
            this.$disableNotificationInterceptor(Message.NOTIFICATION_TYPE);
        },
        initMessengerNotificationInterceptor() {
            this.$interceptNotification(Message.NOTIFICATION_TYPE, (data) => this.handler(data, this.chatId));
        },
        handler({ action_button }, chat) {
            if (!action_button || !action_button.redirect_url) {
                return true;
            }
            let notificationChat;
            const { redirect_url } = action_button;
            const { searchParams } = new URL(`${window.location.protocol}//${window.location.hostname}${redirect_url}`);
            if (searchParams) {
                notificationChat = searchParams.get('chat_id');
            }
            return String(chat) !== String(notificationChat);
        },
    },
};
