import { promisifiedAjax } from '@/utils/functions';
import Part from '@/apps/admin/services/messengers/parts/Part';
import LaterHalfPart from '@/apps/admin/services/messengers/parts/LaterHalfPart';
import EarlierHalfPart from '@/apps/admin/services/messengers/parts/EarlierHalfPart';

export default class MessagesRequest {
    static AFTER = 'after';

    static PER_PAGE = 30

    static COUNT = MessagesRequest.PER_PAGE / 2;

    static BEFORE = 'before'

    _url = '';

    _chat = '';

    _central = null;

    _loaded = {
        first: new LaterHalfPart(),
        second: new EarlierHalfPart(),
    }

    constructor(chat, url, centerMessageId) {
        this._url = url;
        this._chat = chat;
        this._central = centerMessageId;
    }

    get() {
        return new Part(this._loaded.first, this._loaded.second);
    }

    async fetchEarly() {
        const config = { central: this._central, direction: MessagesRequest.BEFORE, fullPath: true };
        const url = this._formRequestUrl(config);
        const response = await MessagesRequest._requestHalfPart(url);
        this._loaded.second = new EarlierHalfPart(response);
        this._loaded.first.laterMessage = this._loaded.second.laterMessage;
        return true;
    }

    async fetchLater() {
        const config = { central: this._central, direction: MessagesRequest.AFTER, fullPath: true };
        const url = this._formRequestUrl(config);
        const response = await MessagesRequest._requestHalfPart(url);
        this._loaded.first = new LaterHalfPart(response);
        this._loaded.second.earlierMessage = this._loaded.first.earlierMessage;
        return true;
    }

    async fetch() {
        let url;
        if (!this._central) {
            url = this._formRequestUrl({});
        } else {
            url = this._formRequestUrl({ direction: MessagesRequest.AFTER, include: true, central: this._central });
        }

        const firstResponse = await MessagesRequest._requestHalfPart(url);
        let first = new LaterHalfPart(firstResponse);
        if (first.messages.length <= MessagesRequest.COUNT && first.earlierMessage == null && first.laterMessage == null) {
            this._loaded.first = first;
            return true;
        }

        const secondConfig = { direction: MessagesRequest.BEFORE };
        if (!this._central) {
            secondConfig.central = first.central;
        } else {
            secondConfig.central = this._central;
        }
        url = this._formRequestUrl(secondConfig);
        const secondResponse = await MessagesRequest._requestHalfPart(url);
        let second = new EarlierHalfPart(secondResponse);

        this._loaded.first = first;
        this._loaded.second = second;
        return true;
    }

    static async _requestHalfPart(url) {
        let { data } = await promisifiedAjax({ url });
        return data;
    }

    _formRequestUrl(data) {
        const include = data.include ? '&include-offset=1' : '';
        const perPage = !data.fullPart ? `&per-page=${MessagesRequest.COUNT}` : `&per-page=${MessagesRequest.PER_PAGE}`;
        const pagination = `${data.central ? `&offset=${data.central}` : ''}${data.direction ? `&direction=${data.direction}` : ''}`;
        return `${this._url}?chatId=${this._chat}${pagination}${perPage}${include}`;
    }
}
