import { promisifiedAjax, withGet } from '@/utils/functions';

export default class TaskViewService {
    static async loadData(id) {
        return promisifiedAjax({
            methods: 'GET',
            url: withGet('/admin/scheduling/tasks/view--load-data', { id }),
        });
    }

    static async loadWatchers(id) {
        return promisifiedAjax({
            methods: 'GET',
            url: withGet('/admin/scheduling/tasks/view--get-watchers', { id }),
        });
    }

    static async loadAttachments(id) {
        return promisifiedAjax({
            methods: 'GET',
            url: withGet('/admin/scheduling/tasks/view--get-attachments', { id }),
        });
    }

    static async loadInventoryData(taskId, customerId) {
        return promisifiedAjax({
            method: 'GET',
            url: withGet('/admin/scheduling/tasks/view--load-inventory-data', { id: taskId, customer_id: customerId }),
        });
    }
}
