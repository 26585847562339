import MessengersService from '@/apps/admin/services/messengers/MessengersService';
import MessagesToConversations from '@/apps/admin/services/messengers/MessagesToConversation';
import Part from '@/apps/admin/services/messengers/parts/Part';
import EarlierHalfPart from '@/apps/admin/services/messengers/parts/EarlierHalfPart';
import LaterHalfPart from '@/apps/admin/services/messengers/parts/LaterHalfPart';

export default {
    data() {
        return {
            centralMessage: null,
            currentPart: {
                ...new Part(new LaterHalfPart(), new EarlierHalfPart()).get(),
            },
        };
    },
    methods: {
        async loadPart() {
            const service = new MessengersService(this.senderType);
            this.currentPart = await service.fetchPart(this.chatId, this.centralMessage);

            this.chatOpenedFromStart = this.currentPart.isFirst;
            this.chatOpenedFromEnd = this.currentPart.isLast;

            return true;
        },
        async loadLastPart() {
            this.messagesBottomLoading = true;
            this.centralMessage = null;
            const service = new MessengersService(this.senderType);
            this.currentPart = await service.fetchPart(this.chatId, null);
            this.$nextTick(() => {
                this.scrollEnd(true);
                this.messagesBottomLoading = false;
            });
        },
        async loadEarlier() {
            this.messagesTopLoading = true;
            this.centralMessage = this.currentPart.getEarlierCentral();
            await this.loadEarlierPart();
            this.$nextTick(() => {
                this.scrollToMessage(this.centralMessage, false, 'top');
                this.messagesTopLoading = false;
            });
        },
        async loadLatest() {
            this.messagesBottomLoading = true;
            this.centralMessage = this.currentPart.getLaterCentral();
            await this.loadLaterPart();
            this.$nextTick(() => {
                this.scrollToMessage(this.centralMessage, false, 'bottom');
                this.messagesBottomLoading = false;
            });
        },
        async loadEarlierPart() {
            const service = new MessengersService(this.senderType);
            const part = await service.fetchEarly(this.chatId, this.centralMessage);
            this.mergeParts(part, [
                ...this.currentPart.order,
                ...part.order,
            ]);
        },
        async loadLaterPart() {
            const service = new MessengersService(this.senderType);
            const part = await service.loadLaterPart(this.chatId, this.centralMessage);
            this.mergeParts(part, [
                ...part.order,
                ...this.currentPart.order,
            ]);
        },
        mergeParts(part, order) {
            this.currentPart = {
                ...part,
                messages: [
                    ...part.messages,
                    ...this.currentPart.messages,
                ],
                conversations: {
                    ...part.conversations,
                    ...this.currentPart.conversations,
                },
                order,
            };
        },
        scrollToMessageOrEnd() {
            if (this.$route.query.message_id || this.scrollToMessageOnOpen) {
                this.scrollToMessage(this.$route.query.message_id || this.scrollToMessageOnOpen);
            } else {
                this.scrollEnd();
            }
            this.initState = false;
        },
    },
    computed: {
        messageSearch() {
            return this.$route.query.message_id ?? this.scrollToMessageOnOpen ?? null;
        },
        conversationToRender() {
            const conversations = new MessagesToConversations(
                this.currentPart.messages,
                this.currentPart.conversations,
                this.currentPart.order,
                this.messagesStatuses,
            );
            conversations.process();
            return conversations.get();
        },
    },
    watch: {
        chatboxTitle(newCustomerLeadNameAfterUpdate) {
            this.currentPart.messages.forEach((message) => {
                if (message.type === 'in') {
                    if (typeof message.author === 'string') {
                        message.author = newCustomerLeadNameAfterUpdate;
                        return;
                    }
                    message.author.name = newCustomerLeadNameAfterUpdate;
                }
            });
        },
    },
};
