<template>
    <div class="head-wrapper d-flex p-16 align-items-center justify-content-between">
        <a
            class="chatbox-back me-4"
            href="javascript:void(0)"
            @click="$emit('back')"
        >
            <i class="icon-ic_fluent_arrow_left_24_regular" />
        </a>

        <div class="d-flex align-items-center">
            <h4 v-if="!messengerTitle">
                {{ $t('messengers', 'Create new conversation') }}
            </h4>
            <h4
                v-else
                v-html="$t('messengers', 'Create new{messengerTitle} chat', { messengerTitle })"
            />
        </div>

        <a
            class="chat-box-action"
            href="javascript:void(0)"
            :title="$t('messengers', 'Minimize')"
            @click="$closeChatBox()"
        >
            <i class="icon-ic_fluent_line_horizontal_1_20_regular" />
        </a>
    </div>
</template>

<script>
export default {
    name: 'ChatBoxCreateHead',
    props: {
        messengerName: String,
    },
    computed: {
        messengerTitle() {
            if (!this.messengerName) {
                return '';
            }
            return `&nbsp;${this.messengerName}`;
        },
    },
};
</script>
