import {
    SET_ONE_TIME_PAGE_TARIFFS_FOR_CHANGE,
    SET_ONE_TIME_PAGE_TARIFFS_PAGE_INFO,
} from '@/apps/admin/store/mutation-types';
import { toCamelCase } from '@/utils/functions';
import { stateToGetters } from '@/apps/admin/store/helpers';

const state = {
    className: '',
    attributesMeta: {},
    afAttributesMeta: {},
    transactionsCategories: {},
    isSetTransactionCategory: false,
    model: {},
    customerLabels: {},
    customerCategories: {},
};

const getters = {
    ...stateToGetters(state),
    storeModel(state) {
        return state.model;
    },
};

const actions = {
    loadAddPageInfo({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/admin/tariffs/one-time--get-form-page-info',
                success: (response) => {
                    commit(SET_ONE_TIME_PAGE_TARIFFS_PAGE_INFO, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
    loadEditPageInfo({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: `/admin/tariffs/one-time--get-form-page-info?id=${id}`,
                success: (response) => {
                    commit(SET_ONE_TIME_PAGE_TARIFFS_PAGE_INFO, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
    setOneTimePageTariffsForChange({ commit }, data) {
        commit(SET_ONE_TIME_PAGE_TARIFFS_FOR_CHANGE, data);
    },
};

const mutations = {
    [SET_ONE_TIME_PAGE_TARIFFS_PAGE_INFO](state, info) {
        for (let key in info) {
            state[toCamelCase(key)] = info[key];
        }
        if (!info.attributes_meta) {
            return false;
        }
        state.attributesMeta = info.attributes_meta.attributes;
        state.afAttributesMeta = info.attributes_meta.additional_attributes;
        state.className = info.attributes_meta.className;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
