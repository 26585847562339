import { stateToGetters } from '@/apps/admin/store/helpers';
import { SET_PORTAL_CONFIGS_PAGE_DATA } from '@/apps/admin/store/mutation-types';
import { toCamelCase } from '@/utils/functions';

const state = {
    config: {},
    rawConfig: {},
    attributesMeta: {},
    fields: {},
    partnersList: {},
    partnerConfig: {},
    partnerRawConfig: {},
    partnerAttributesMeta: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadGeneralTabData({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/config/main/portal--general-settings',
                success: (response) => {
                    commit(SET_PORTAL_CONFIGS_PAGE_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    loadAdditionalFieldsData({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/config/main/portal--get-additional-settings',
                success: (response) => {
                    commit(SET_PORTAL_CONFIGS_PAGE_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    loadPartnerTabData({ commit }, partner_id) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/main/portal--get-partner-settings?partner_id=${partner_id}`,
                success: (response) => {
                    commit(SET_PORTAL_CONFIGS_PAGE_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_PORTAL_CONFIGS_PAGE_DATA](state, data) {
        for (let k in data) {
            state[toCamelCase(k)] = data[k];
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
