import { stateToGetters } from '@/apps/admin/store/helpers';
import { toCamelCase, promisifiedAjax } from '@/utils/functions';
import { SET_CONFIGS_FINANCE_SETTINGS_PAGE_DATA } from '@/apps/admin/store/mutation-types';

const state = {
    config: {},
    rawConfig: {},
    attributesMeta: {},
    partner: {},
    billing: {},
    customerLabel: {},
    disableValueBlockingPeriod: '',
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadPageData({ commit }, { partnerId }) {
        return promisifiedAjax({
            url: `/admin/config/finance/settings--get-page-data?partner_id=${partnerId}`,
            method: 'GET',
        }).then((response) => {
            let { config, billing } = response;
            for (let i in config.items) {
                if (Object.prototype.hasOwnProperty.call(config.items[i], 'options')) {
                    config.items[i].options = Object.assign(config.items[i].options, billing[i]);
                }
            }
            commit(SET_CONFIGS_FINANCE_SETTINGS_PAGE_DATA, response);
        });
    },
};

const mutations = {
    [SET_CONFIGS_FINANCE_SETTINGS_PAGE_DATA](state, data) {
        for (let k in data) {
            state[toCamelCase(k)] = data[k];
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
