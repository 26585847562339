<template>
    <x-form
        :id="'admin_tariffs_one_time_form_' + form"
        ref="form"
        :url="formUrl"
        method="POST"
        class="form-horizontal"
        :data="model"
        :class-name="className"
        :attributes-meta="attributesMeta"
        :af-attributes-meta="afAttributesMeta"
        @success="onFormSuccess"
        @error="onFormError"
    >
        <div class="card-block">
            <div class="card-block-header">
                <h4 v-text="$t('tariffs', 'General settings')" />
            </div>
            <div class="card-block-body">
                <div class="row">
                    <label class="col-md-4 col-form-label">
                        {{ getAttributeLabel('title') }}
                    </label>

                    <div class="col-md-8">
                        <x-complex-input
                            v-model="model.title"
                            type="text"
                            :params="{
                                options: {
                                    name: 'OneTimeTariffs[title]',
                                }
                            }"
                            :meta="attributesMeta.title"
                        />
                    </div>
                </div>

                <div class="row">
                    <label class="col-md-4 col-form-label">
                        {{ getAttributeLabel('service_description') }}
                    </label>

                    <div class="col-md-8">
                        <x-complex-input
                            v-model="model.service_description"
                            type="text"
                            :params="{
                                options: {
                                    name: 'OneTimeTariffs[service_description]',
                                },
                            }"
                            :meta="attributesMeta.service_description"
                        />
                    </div>
                </div>

                <div class="row">
                    <label class="col-md-4 col-form-label">
                        {{ getAttributeLabel('enabled') }}
                    </label>

                    <div class="col-md-8 d-flex">
                        <x-complex-input
                            v-model="model.enabled"
                            type="checkbox"
                            :params="{
                                label: availableForServicesLabel,
                                options: {
                                    class: 'availible-for-services',
                                    name: 'OneTimeTariffs[enabled]',
                                }
                            }"
                            :meta="attributesMeta.available_for_services"
                        />
                    </div>
                </div>

                <div class="row">
                    <label class="col-md-4 col-form-label">
                        {{ getAttributeLabel('price') }}
                    </label>

                    <div class="col-md-8">
                        <x-complex-input
                            v-model="model.price"
                            type="number"
                            :params="{
                                allowEmpty: true,
                                numberType: 'decimal',
                                options: {
                                    name: 'OneTimeTariffs[price]',
                                },
                            }"
                            :meta="attributesMeta.price"
                        />
                    </div>
                </div>

                <div class="row">
                    <label class="col-md-4 col-form-label">
                        {{ getAttributeLabel('partners_ids') }}
                    </label>

                    <div class="col-md-8">
                        <x-complex-input
                            v-model="model.partners_ids"
                            type="multiple-select"
                            :params="{
                                options: {
                                    options: partners,
                                    name: 'OneTimeTariffs[partners_ids]',
                                    required: true,
                                },
                            }"
                            :meta="attributesMeta.partners_ids"
                        />
                    </div>
                </div>

                <div class="row">
                    <label class="col-md-4 col-form-label">
                        {{ getAttributeLabel('with_vat') }}
                    </label>

                    <div class="col-md-8">
                        <x-complex-input
                            v-model="model.with_vat"
                            type="checkbox"
                            :params="{
                                options: {
                                    name: 'OneTimeTariffs[with_vat]',
                                },
                            }"
                            :meta="attributesMeta.with_vat"
                        />
                    </div>
                </div>

                <div class="row">
                    <label class="col-md-4 col-form-label">
                        {{ getAttributeLabel('tax_id') }}
                    </label>

                    <div class="col-md-8">
                        <x-complex-input
                            v-model="model.tax_id"
                            type="select"
                            name="taxId"
                            :params="{
                                options: {
                                    options: taxOptions,
                                    name: 'OneTimeTariffs[tax_id]',
                                },
                                templateSelection: true,
                                templateResult: true,
                            }"
                            :meta="attributesMeta.tax_id"
                        />
                        <small class="text-secondary">
                            {{
                                $t('common', 'If a location-based tax is applied, it will take preference and be used by default in the customer\'s financial documents.')
                            }}
                        </small>
                    </div>
                </div>

                <div
                    v-if="isSetTransactionCategory"
                    class="row"
                >
                    <label class="col-md-4 col-form-label">
                        {{ getAttributeLabel('transaction_category_id') }}
                    </label>

                    <div class="col-md-8">
                        <x-complex-input
                            v-model="model.transaction_category_id"
                            type="select"
                            :params="{
                                options: {
                                    options: transactionsCategories,
                                    name: 'OneTimeTariffs[transaction_category_id]',
                                },
                                sortCallback: sortTransactionCategories,
                            }"
                            :meta="attributesMeta.transaction_category_id"
                        />
                    </div>
                </div>

                <div
                    v-for="(afAttribute, name) in afsForAdding"
                    :key="name"
                    class="row"
                >
                    <label class="col-md-4 col-form-label">
                        {{ afAttribute.title }}
                    </label>
                    <div class="col-md-8">
                        <x-additional-field-input
                            v-model="model.additional_attributes[name]"
                            :type="afAttribute.type"
                            :search="false"
                            :params="afAttribute"
                        />
                    </div>
                </div>
            </div>
        </div>

        <availability-for-ordering-block
            v-model="model"
            :attributes-meta="attributesMeta"
            :customer-categories="customerCategories"
            :customer-labels="customerLabels"
            :class-name="'OneTimeTariffs'"
        />

        <div class="row">
            <div class="col-md-12">
                <button
                    :id="'admin_tariff_one_time_button' + (form === 'add' ? '_add' : '_edit')"
                    class="btn btn-primary pull-right"
                    type="submit"
                    @click.prevent="$refs.form.submit()"
                >
                    {{ $t('tariffs', 'Save') }}
                </button>

                <a
                    class="btn btn-cancel pull-right"
                    @click="backToList"
                >{{ $t('tariffs', 'Back to list') }}</a>
            </div>
        </div>
    </x-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { XComplexInput } from '@/components/common/inputs/XComplexInput/';
import { XAdditionalFieldInput } from '@/components/common/inputs/XAdditionalFieldInput';
import { isset, taxFormat } from '@/utils/functions';
import { XForm } from '@/components/common/XForm';
import TariffForceUpdateMixin from '@/apps/admin/views/admin/tariffs/mixins/force-update-mixin';
import AvailabilityForOrderingBlock from '@/apps/admin/views/admin/tariffs/AvailabilityForOrderingBlock.vue';

export default {
    name: 'OneTimePlansForm',
    mixins: [TariffForceUpdateMixin],
    props: {
        form: {
            type: String,
            default: 'add',
        },
    },
    data() {
        return {
            model: {
                additional_attributes: {},
            },
            loadingPartners: false,
            taxOptions: {},
        };
    },
    mounted() {
        this.loadTaxes().then(() => {
            this.taxOptions = this.getActiveTaxesWithCurrentTax(this.model.tax_id);
        });
    },
    methods: {
        ...mapActions('one_time_tariffs', [
            'setOneTimePageTariffsForChange',
            'loadEditPageInfo',
        ]),
        ...mapActions('taxes', [
            'loadTaxes',
        ]),
        getAttributeLabel(attribute) {
            if (isset(this.attributesMeta, [attribute, 'label'])) {
                return this.attributesMeta[attribute].label;
            }

            return attribute;
        },
        onFormSuccess(response) {
            if (response.result) {
                show_success(response.message, 4);

                if (this.form === 'add') {
                    setTimeout(() => {
                        window.switch_page(`/admin/tariffs/one-time--edit?id=${response.options.id}`);
                    }, 1000);
                }

                if (this.form === 'edit') {
                    if (response.options.need_refresh) {
                        this.updateTransactionCategoryId();
                    }
                }
            } else {
                window.showErrors(response.message, 4);
            }
        },
        onFormError(err) {
            console.error('Form err', err);
        },
        getAdditionalAttributes() {
            let model = {};
            for (let attribute in this.attributesMeta) {
                model[attribute] = this.attributesMeta[attribute].default;
                if (this.attributesMeta[attribute].rule === null) {
                    continue;
                }

                if (this.attributesMeta[attribute].rule.type === 'boolean') {
                    model[attribute] = !!this.attributesMeta[attribute].default;
                }

                if (attribute === 'vat_percent') {
                    model[attribute] = taxFormat(this.attributesMeta[attribute].default, true);
                }
            }
            model.additional_attributes = {};
            for (let afAttribute in this.afAttributesMeta) {
                model.additional_attributes[afAttribute] = this.afAttributesMeta[afAttribute].value;
            }

            this.model = model;
        },
        getPageData(newPartners) {
            if (this.loadingPartners || empty(newPartners)) {
                return;
            }
            $.ajax({
                url: '/admin/tariffs--get-tariffs-by-partners',
                data: {
                    partners: newPartners,
                    type: 'one-time',
                    excludeId: this.model.id,
                    checkIsActive: '1',
                },
                success: (response) => {
                    this.setOneTimePageTariffsForChange(response);
                },
                complete: () => {
                    this.loadingPartners = false;
                },
            });
        },
        backToList() {
            window.switch_page('/admin/tariffs/one-time');
        },
    },
    created() {
        this.getAdditionalAttributes();
        this.getPageData();
    },
    computed: {
        ...mapGetters('one_time_tariffs', [
            'storeModel',
            'className',
            'attributesMeta',
            'afAttributesMeta',
            'transactionsCategories',
            'isSetTransactionCategory',
            'customerLabels',
            'customerCategories',
        ]),
        ...mapGetters('partners', [
            'partners',
        ]),
        ...mapGetters('taxes', [
            'getActiveTaxesWithCurrentTax',
        ]),
        formUrl() {
            let url = '/admin/tariffs/one-time--save';
            if (this.form === 'edit') {
                return `${url}?id=${this.$route.query.id}`;
            }
            return url;
        },
        afsForAdding() {
            let result = {};
            for (let k in this.afAttributesMeta) {
                let attribute = this.afAttributesMeta[k];
                if ((this.type !== 'add' || attribute.is_add) && !attribute.hidden) {
                    result[k] = attribute;
                }
            }

            return result;
        },
        availableForServicesLabel() {
            return {
                active: this.$t('tariffs', 'Active'),
                disabled: this.$t('tariffs', 'Archived'),
            };
        },
    },
    components: {
        AvailabilityForOrderingBlock,
        XAdditionalFieldInput,
        XComplexInput,
        XForm,
    },
};
</script>
