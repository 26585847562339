<template>
    <chat-view
        :chat-id="chatId"
        :chatbox="true"
        :customer-id="customerId"
        :chatbox-title="title"
        :unknown-sender="unknownSender"
        :scroll-to-message-on-open="scrollToMessage"
        view-height="350px"
        :messenger="messengerId"
        :messenger-name="messengerName"
        @chat-not-found="onChatNotFound"
        @back="$emit('back')"
        @start-conversation="$emit('start-conversation', $event)"
    />
</template>

<script>
import ChatView from '@/apps/admin/views/admin/common/communication/messengers/ChatView';

export default {
    name: 'ChatBoxChatView',
    props: {
        scrollToMessage: {
            type: [String, Number, Boolean],
            default: false,
        },
        unknownSender: {
            type: Boolean,
            default: false,
        },
        customerId: [Number, String],
        messengerId: [Number, String],
        messengerName: String,
        title: String,
        chatId: [Number, String],
    },
    methods: {
        onChatNotFound() {
            this.$emit('not-found');
        },
    },
    components: {
        ChatView,
    },
};
</script>
