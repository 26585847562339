import {
    SET_STAGES,
    SET_TASKS,
    SET_BOARD_TASKS,
    SET_FILTERED_TASKS,
    SET_BOARD_FILTERED_TASKS,
    RESET_FILTERED_TASKS,
    MOVE_TASK,
    SET_TASK_INFO,
    DELETE_TASK,
    UPDATE_TASK, SET_PROJECT_NAME,
} from '../../mutation-types';

const state = {
    tasks: {},
    stages: {},
    filteredTasks: {},
    projectName: '',
};

const getters = {
    getTitle() {
        return state.projectName;
    },
    list(state) {
        return state.tasks;
    },
    filteredList(state) {
        return state.filteredTasks;
    },
    stages(state) {
        return state.stages;
    },
};

const actions = {
    loadBoardInfo({ commit }, { advancedParams }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/scheduling/boards--reload-board',
                data: advancedParams,
                method: 'POST',
                success: (response) => {
                    if (response.result) {
                        let tasks = Object.keys(response.message.tasks).length > 0 ? response.message.tasks : {};
                        let stages = Object.values(response.message.workflow || {});
                        stages.sort((a, b) => (parseInt(a.position) > parseInt(b.position) ? 1 : -1));

                        // Fill empty boards
                        stages.forEach((stage) => {
                            if (!isset(tasks, stage.id)) {
                                tasks[stage.id] = [];
                            }
                        });
                        commit(SET_PROJECT_NAME, response.message.projectTitle || '');
                        commit(SET_TASKS, tasks);
                        commit(SET_FILTERED_TASKS, { ...tasks });
                        commit(SET_STAGES, stages);
                        resolve();
                    } else {
                        showErrors(response.message, 4);
                    }
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    saveItemStatus({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/scheduling/boards--change-tasks-status?taskId=${payload.itemId}`,
                method: 'POST',
                data: {
                    newStatus: payload.newStatus,
                    tasksIds: [payload.itemId],
                },
                success: (response) => {
                    if (!response.result) {
                        // Move back if fail
                        reject(response.message);
                    } else {
                        commit(MOVE_TASK, payload);

                        if (isset(response.message, 'task')) {
                            commit(SET_TASK_INFO, {
                                board: payload.newStatus,
                                itemId: payload.itemId,
                                info: response.message.tasks,
                            });
                        }
                        resolve(response);
                    }
                },
            });
        });
    },
    moveAllBoard({ commit, state }, { toStatus, fromStatus }) {
        return new Promise((resolve, reject) => {
            if (!isset(state.tasks, fromStatus)) {
                return;
            }
            if (!isset(state.tasks, toStatus)) {
                commit(SET_BOARD_TASKS, { board: toStatus, items: [] });
            }

            let tasksIds = [];
            state.tasks[fromStatus].forEach((task) => {
                tasksIds.push(task.id);
            });

            $.ajax({
                url: '/admin/scheduling/tasks--change-status',
                method: 'POST',
                data: {
                    newStatusId: toStatus,
                    tasksIds,
                },
                success: (response) => {
                    if (isset(response, 'result') && !response.result) {
                        reject(response.message);
                        return;
                    }

                    let newBoardTasks = state.tasks[toStatus].slice();
                    state.tasks[fromStatus].forEach((task) => {
                        newBoardTasks.push(task);
                    });

                    commit(SET_BOARD_TASKS, { board: toStatus, items: newBoardTasks });
                    commit(SET_BOARD_TASKS, { board: fromStatus, items: [] });

                    commit(SET_BOARD_FILTERED_TASKS, { board: toStatus, items: newBoardTasks.slice() });
                    commit(SET_BOARD_FILTERED_TASKS, { board: fromStatus, items: [] });

                    resolve(response.message.message);
                },
            });
        });
    },
};

const mutations = {
    [SET_TASKS](state, tasks) {
        state.tasks = tasks;
    },
    [SET_PROJECT_NAME](state, title) {
        state.projectName = title;
    },
    [SET_BOARD_TASKS](state, payload) {
        if (typeof state.tasks[payload.board] === 'undefined') {
            state.tasks[payload.board] = [];
        }

        state.tasks[payload.board] = payload.items;
    },
    [SET_FILTERED_TASKS](state, tasks) {
        state.filteredTasks = tasks;
    },
    [SET_BOARD_FILTERED_TASKS](state, payload) {
        if (typeof state.filteredTasks[payload.board] === 'undefined') {
            state.filteredTasks[payload.board] = [];
        }

        state.filteredTasks[payload.board] = payload.items;
    },
    [RESET_FILTERED_TASKS](state) {
        state.filteredTasks = { ...state.tasks };
    },
    [SET_STAGES](state, stages) {
        state.stages = stages;
    },
    [MOVE_TASK](state, {
        oldStatus, newStatus, itemId, position,
    }) {
        let oldTask = state.tasks[oldStatus].find((task) => parseInt(task.id) === parseInt(itemId));
        if (!isset(state.tasks, newStatus)) {
            state.tasks[newStatus] = [];
        }

        if (!empty(oldTask)) {
            moveTask(state.tasks, oldStatus, newStatus, state.tasks[oldStatus].indexOf(oldTask), oldTask, position);
        }
    },
    [SET_TASK_INFO](state, { board, itemId, info }) {
        updateTask(state.tasks, { board, itemId, info });
        updateTask(state.filteredTasks, { board, itemId, info });
    },
    [DELETE_TASK](state, { board, itemId }) {
        deleteTask(state.tasks, { board, itemId });
        deleteTask(state.filteredTasks, { board, itemId });
    },
    [UPDATE_TASK](state, { task }) {
        updateWithMoveTask(state.tasks, task);
        updateWithMoveTask(state.filteredTasks, task);
    },
};

function findTask(tasksVar, itemId) {
    itemId = parseInt(itemId);

    for (let status in tasksVar) {
        if (!Object.prototype.hasOwnProperty.call(tasksVar, status)) {
            continue;
        }

        let boardTasks = tasksVar[status];
        if (boardTasks.length <= 0) {
            continue;
        }

        for (let index = 0; index < boardTasks.length; index++) {
            if (parseInt(boardTasks[index].id) === itemId) {
                return {
                    board: status,
                    task: boardTasks[index],
                    index,
                };
            }
        }
    }

    return null;
}

function updateTask(tasksVar, { board, itemId, info }) {
    let tasks = tasksVar[board].find((item) => parseInt(item.id) === parseInt(itemId));
    let index = tasksVar[board].indexOf(tasks);
    if (index !== -1) {
        tasksVar[board][index] = info;
    }
}

function updateWithMoveTask(tasksVar, task) {
    let filteredResult = findTask(tasksVar, task.id);
    if (filteredResult !== null) {
        tasksVar[filteredResult.board].splice(filteredResult.index, 1, { ...task });

        if (parseInt(filteredResult.board) !== parseInt(task.workflow_status_id)) {
            moveTask(tasksVar, filteredResult.board, task.workflow_status_id, filteredResult.index, { ...task }, 0);
        }
    }
}

function deleteTask(tasksVar, { board, itemId }) {
    let task = tasksVar[board].find((item) => parseInt(item.id) === parseInt(itemId));
    let index = tasksVar[board].indexOf(task);
    if (index !== -1) {
        tasksVar[board].splice(index, 1);
    } else {
        Object.entries(tasksVar).forEach((column) => {
            const [key, value] = column;
            Object.entries(value).forEach((item) => {
                const [itemKey, itemValue] = item;
                if (parseInt(itemId) === parseInt(itemValue.id)) {
                    tasksVar[key].splice(itemKey, 1);
                }
            });
        });
    }
}

function moveTask(tasksVar, fromStatus, toStatus, index, task, position) {
    // Delete old task
    tasksVar[fromStatus].splice(index, 1);

    // Add task to target column
    tasksVar[toStatus].splice(position, 0, { ...task });
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
