import { stateToGetters } from '@/apps/admin/store/helpers';
import {
    SET_CUSTOMER_FORM_RELATED_ID,
    SET_CUSTOMER_FORM_RELATED_SERVICE_ID,
    SET_MAIN_FORM_SHOW_WARNING_INCORRECT_VALUES,
    SET_TASK_GEO_DATA_CHANGE_PARAMS,
} from '@/apps/admin/store/mutation-types';

const state = {
    customerFormRelatedId: 0,
    customerFormRelatedServiceId: 0,
    mainFormShowWarningIncorrectValues: {},
    taskGeoDataParamsForChange: {},
};

const getters = {
    ...stateToGetters(state),
};

const mutations = {
    [SET_CUSTOMER_FORM_RELATED_ID](state, newId) {
        state.customerFormRelatedId = newId;
    },
    [SET_CUSTOMER_FORM_RELATED_SERVICE_ID](state, newId) {
        state.customerFormRelatedServiceId = newId;
    },
    [SET_MAIN_FORM_SHOW_WARNING_INCORRECT_VALUES](state, incorrectValues) {
        state.mainFormShowWarningIncorrectValues = incorrectValues;
    },
    [SET_TASK_GEO_DATA_CHANGE_PARAMS](state, params) {
        state.taskGeoDataParamsForChange = params;
    },
};

const actions = {
    setCustomerFormRelatedId({ commit }, newId) {
        commit(SET_CUSTOMER_FORM_RELATED_ID, newId);
    },
    setCustomerFormRelatedServiceId({ commit }, newId) {
        commit(SET_CUSTOMER_FORM_RELATED_SERVICE_ID, newId);
    },
    setMainFormShowWarningIncorrectValues({ commit }, incorrectValues) {
        commit(SET_MAIN_FORM_SHOW_WARNING_INCORRECT_VALUES, incorrectValues);
    },
    setTaskGeoDataChangeParams({ commit }, params) {
        commit(SET_TASK_GEO_DATA_CHANGE_PARAMS, params);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
