const ProfilePage = () => import(/* webpackChunkName: "profile" */ '@/apps/admin/views/admin/profile/ProfilePage');

export default [
    {
        path: '',
        name: 'admin.profile',
        component: ProfilePage,
        meta: {
            breadcrumbs: [
                {
                    title: ['common', 'My Profile'],
                    url: '/admin/profile',
                },
            ],
            title: ['common', 'Profile'],
        },
    },
];
