import XTwigPage from '@/components/base/XTwigPage';

const VoiceDevicesList = () => import(/* webpackChunkName: "voice_devices" */ '@/apps/admin/views/admin/networking/voice-devices/VoiceDevicesList');

export default [
    {
        path: 'add',
        name: 'admin.networking.voice_devices.add',
        component: XTwigPage,
    },
    {
        path: 'list',
        name: 'admin.networking.voice_devices.list',
        component: VoiceDevicesList,
        meta: {
            keepAlive: true,
            componentName: 'VoiceDevicesList',
        },
    },
    {
        path: 'view',
        name: 'admin.networking.voice_devices.view',
        component: XTwigPage,
    },
];
