<template>
    <x-addon-vue-component
        v-if="loaded"
        :url="url"
        :internal-preloader="true"
        :down-data="downData"
        @up-data="onDataFromAddon"
        @processing="processing = $event"
    >
        <div
            v-if="processing"
            class="h-100 d-flex w-100 justify-content-center align-items-center"
        >
            <x-bootstrap-spinner
                size="lg"
            />
        </div>
    </x-addon-vue-component>
</template>

<script>
import { promisifiedAjax } from '@/utils/functions';
import XAddonVueComponent from '@/components/base/XAddonVueComponent/XAddonVueComponent';
import XBootstrapSpinner from '@/components/common/XBootstrapSpinner/XBootstrapSpinner';

export default {
    name: 'XAddonVueCode',
    props: {
        module: String,
        point: [String, Number],
        params: {
            type: Object,
            default: () => ({}),
        },
        pointParams: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            processing: true,
            loaded: false,
            url: '',
        };
    },
    mounted() {
        promisifiedAjax({
            url: '/admin/config/add-on--get-entry-point-data',
            data: {
                module: this.module,
                point: this.point,
                ...this.pointParams,
            },
        }).then(({
            uri,
        }) => {
            this.url = uri;
            this.$nextTick(() => {
                this.loaded = true;
            });
        }).catch((error) => {
            this.$showError(error);
            this.url = '';
        });
    },
    methods: {
        onDataFromAddon(data) {
            if (data.action) {
                this.$emit(data.action, data.data);
            }
        },
    },
    computed: {
        downData() {
            return {
                params: this.params,
                pointParams: this.pointParams,
            };
        },
    },
    components: { XBootstrapSpinner, XAddonVueComponent },
};
</script>
