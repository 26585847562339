import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: '',
        name: 'admin.voice.rates.customer_rates',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'rates',
        name: 'admin.voice.rates.customer_rates.rates',
        component: XTwigPage,
        meta: {
            title: ['voice', 'Customer rates'],
        },
    },
];
