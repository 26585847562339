import { stateToGetters } from '@/apps/admin/store/helpers';
import { isset } from '@/utils/functions';
import { SET_LABELS_ADD_PAGE_DATA, SET_LABELS_DATA } from '../../mutation-types';

const state = {
    adminCanAdd: false,
    dataTableHeaders: [],
    labelModules: [],
    dtColumnDefs: {},
    modelName: '',
    className: {},
    attributesMeta: {},
    model: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadPageLabelsData({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/config/labels--get-page-data',
                success: (response) => {
                    commit(SET_LABELS_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    loadAddPageInfo({ commit }, module) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/labels--get-add-page-info?module=${module}`,
                success: (response) => {
                    commit(SET_LABELS_ADD_PAGE_DATA, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
    loadEditPageInfo({ commit }, { id, module }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/labels--get-add-page-info?id=${id}&module=${module}`,
                success: (response) => {
                    commit(SET_LABELS_ADD_PAGE_DATA, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
};

const mutations = {
    [SET_LABELS_DATA](state, data) {
        for (let k in data) {
            state[k] = data[k];
        }
    },
    [SET_LABELS_ADD_PAGE_DATA](state, info) {
        state.attributesMeta = info.attributesMeta.attributes;
        state.className = info.attributesMeta.className;

        if (isset(info, 'model')) {
            state.model = info.model;
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
