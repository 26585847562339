<template>
    <div
        v-if="attachment"
        class="chat-box-attachment-preview"
    >
        <template v-if="attachment['data-is-pws'] && attachment['data-pws-type'] === 'image'">
            <img
                :src="attachment['link']"
                :alt="attachment['data-pws-type']"
            >
            <p>{{ $t('messengers', 'Image') }}</p>
        </template>
        <template v-else>
            <p>{{ attachment['name'] }}</p>
        </template>
    </div>
</template>
<script>
export default {
    name: 'ChatBoxAttachmentPreview',
    props: {
        attachment: Object,
    },
};
</script>
