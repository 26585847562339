import { FIRST_ITERATION } from '@/apps/admin/services/config/networking/acs/provisioning/refresh_device_constants';
import { FullDeviceRefreshService } from '@/apps/admin/services/config/networking/acs/provisioning/refresh_devices';
import { baseDeviceProcess } from '@/apps/admin/services/config/networking/acs/provisioning/refresh_device_processing';
import { getUrlParam } from '@/utils/functions';

const state = {};
const getters = {};
const actions = {
    async processFullDevice(obj, data) {
        let fullDeviceRefreshProcessing = (new FullDeviceRefreshService(getUrlParam('id'), data.fullDevice))
            .setStep(FIRST_ITERATION);

        return baseDeviceProcess(fullDeviceRefreshProcessing);
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
