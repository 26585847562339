<template>
    <a
        href="javascript:void(0)"
        @click="downloadFile(link)"
    >
        {{ name }} ({{ size }})
    </a>
</template>

<script>
import ChatMessageAttachmentMixin from '@/apps/admin/mixins/messengers/message/chat-message-attachment-mixin';

export default {
    name: 'ChatAttachmentDocument',
    mixins: [ChatMessageAttachmentMixin],
};
</script>
