// Library: https://github.com/InventPartners/bootstrap-responsive-tabs/blob/master/jquery.responsivetabs.js
(function ($) {
    function storeTabs($tabs, $destination)
    {
        // measure width
        $tabs.each(function () {
            let width = $(this).outerWidth(true);
            $(this).data('width', width);
        });
        $tabs.prependTo($destination);
    }

    function makeTabsResponsive($element)
    {
        // Fixed responsive tabs for different layouts. See issue https://jira-splynx.atlassian.net/browse/SPL-8899
        if (!$element.hasClass('ui-tabs-nav')) {
            $element = $element.find('ul[role="tablist"]');
        }
        let $tabs = $element.find('li');
        let $firstTab = $tabs.first();
        let individualTabHeight = $firstTab.outerHeight();
        let tabsHeight = $element.outerHeight() - 5;
        if (tabsHeight > individualTabHeight) {
            // get y pos of first tab
            var firstTabPos = $firstTab.offset();
            var thisTabPos;
            $tabs.each(function () {
                var $thisTab = $(this);
                thisTabPos = $thisTab.offset();
                if (thisTabPos.top > firstTabPos.top) {
                    var $dropdown = $element.find('.responsivetabs-more');
                    if (!$dropdown.length) {
                        // Fix for bootstrap 5. See issue https://jira-splynx.atlassian.net/browse/SPL-8399
                        var dropdownMarkup = '<li class="dropdown responsivetabs-more ui-state-default ui-corner-top">'
                            + '<a href="#" role="button" class="text-decoration-none" data-bs-toggle="dropdown" data-toggle="dropdown">...</a>'
                            + '<ul class="dropdown-menu dropdown-menu-right dropdown-menu-tab">'
                            + '</ul></li>';
                        $dropdown = $(dropdownMarkup);
                        $element.append($dropdown);
                    }
                    var $previousTab = $thisTab.prev();
                    var $followingTabs = $thisTab.nextAll().not('.dropdown');

                    var $destination = $('.dropdown-menu', $dropdown);

                    if (!$thisTab.hasClass('dropdown')) {
                        storeTabs($followingTabs, $destination);
                        storeTabs($thisTab, $destination);
                    }
                    storeTabs($previousTab, $destination);
                    return;
                }
            });
            } else {

            // check if enough space to move a menu item back out of "..."
            // get parent width
            let parentWidth = $element.parent().width();
            let tabSetWidth = 0;
            let xPxAvailable;

            // calculate total width of tab set (can't just use width of ul because it is 100% by default)
            $element.children('li').each(function () {
                tabSetWidth += $(this).outerWidth(true);
            });

            // calculate available horizontal space
            xPxAvailable = parentWidth - tabSetWidth;

            $element.find('.dropdown-menu li').each(function () {
                if ($(this).data('width') <= xPxAvailable) {
                    $(this).insertBefore($element.find('.responsivetabs-more'));
                    xPxAvailable -= $(this).data('width');
                } else {
                    return false;
                }
            });

            // if no menu items left, remove "..."
            if (!$element.find('.dropdown-menu li').length) {
                $element.find('.responsivetabs-more').remove();
            }
        }
    }

    $.fn.responsiveTabs = function () {
        $.each(this, function (index, value) {
            let tab = $(this);
            tab.find('ul[role="tablist"]').on('click', (e) => {
                splynx_event_bus.emit('jq-tab-change');
            });
            setTimeout(function () {
                makeTabsResponsive(tab);
            },50);
            $(window).resize(function () {
                setTimeout(function () {
                    makeTabsResponsive(tab);
                },50);
            });
        });
        $(window).trigger('resize');
        return this;
    };

})(jQuery);
