<template>
    <div class="chat-box-messenger-item d-flex align-items-center">
        <div
            class="chat-box-item-icon me-12"
        >
            <img
                :src="`/images/messengers/chat-box/${name}.svg`"
                :alt="name"
            >
        </div>
        <h4>
            {{ title }}
        </h4>
    </div>
</template>

<script>
export default {
    name: 'ChatBoxMessengerItem',
    props: {
        title: String,
        name: String,
    },
    computed: {
        messengerFirstLetter() {
            return this.messengerName[0]
                .toUpperCase();
        },
    },
};
</script>
