<template>
    <div
        :style="{ transform: `translateY(-${move}px)`, transition: 'transform .2s ease' }"
        class="chat-box-circle"
    >
        <i class="icon-ic_fluent_chat_multiple_24_regular" />
        <slot
            name="counter"
            :value="counter"
        />
    </div>
</template>

<script>
import UseThrottler from '@/apps/admin/mixins/common/use-throttler';

export default {
    name: 'ChatBoxButton',
    mixins: [
        UseThrottler,
    ],
    props: {
        counter: Number,
    },
    data() {
        return {
            move: 0,
            observer: null,
        };
    },
    mounted() {
        splynx_event_bus.on('after_switch_page', this.handleButtonOverlappingCheck);
        window.removeEventListener('scroll', this.handleButtonOverlappingCheck);
        window.addEventListener('scroll', this.handleButtonOverlappingCheck);
        this.handleButtonOverlappingCheck();
    },
    methods: {
        handleButtonOverlappingCheck() {
            this.throttle(() => {
                const fixedRight = 39;
                const fixedBottom = 16;
                const size = 60;
                const maxAllowedMove = 100;
                const targetRect = {
                    left: window.innerWidth - fixedRight - size,
                    right: window.innerWidth - fixedRight,
                    top: window.innerHeight - fixedBottom - size,
                    bottom: window.innerHeight - fixedBottom,
                };
                let maxMove = 0;
                const selectors = [
                    'button:not(.chat-send):not(.ms-choice)',
                    'input[type="submit"]',
                    'a.btn',
                    '.messages-scroll-down-button',
                    '#spl_admins_on_page_wrapper',
                ];
                const buttons = document.querySelectorAll(selectors.join(', '));

                function isIntersecting(rect1, rect2) {
                    return !(rect1.right < rect2.left
                 || rect1.left > rect2.right
                 || rect1.bottom < rect2.top
                 || rect1.top > rect2.bottom);
                }

                function calculateIntersectionMove(targetRect, buttonRect) {
                    const moveRight = targetRect.right - buttonRect.left + 10;
                    const moveLeft = buttonRect.right - targetRect.left + 10;
                    const moveUp = buttonRect.bottom - targetRect.top + 10;
                    const moveDown = targetRect.bottom - buttonRect.top + 10;
                    return Math.max(moveRight, moveLeft, moveUp, moveDown);
                }

                buttons.forEach((button) => {
                    const buttonRect = button.getBoundingClientRect();
                    if (isIntersecting(targetRect, buttonRect)) {
                        const move = calculateIntersectionMove(targetRect, buttonRect);
                        if (move > maxMove) {
                            maxMove = move;
                        }
                    }
                });

                if (maxMove > maxAllowedMove) {
                    maxMove = maxAllowedMove;
                }

                if (maxMove > 0) {
                    let additionalMove = 0;
                    let newTargetRect = {
                        ...targetRect,
                        top: targetRect.top - maxMove,
                        bottom: targetRect.bottom - maxMove,
                    };

                    buttons.forEach((button) => {
                        const buttonRect = button.getBoundingClientRect();
                        if (isIntersecting(newTargetRect, buttonRect)) {
                            const move = calculateIntersectionMove(newTargetRect, buttonRect);
                            if (move > additionalMove) {
                                additionalMove = move;
                            }
                        }
                    });

                    if (additionalMove > 0) {
                        maxMove += additionalMove;
                        if (maxMove > maxAllowedMove) {
                            maxMove = maxAllowedMove;
                        }
                    }

                    this.move = maxMove;
                } else {
                    this.move = 0;
                }
            }, 1000, 'moveButton');
        },
    },
};
</script>
