import XTwigPage from '@/components/base/XTwigPage';

const ConfigCustomization = () => import(/* webpackChunkName: "config_customization" */ '@/apps/admin/views/admin/config/main/customization/ConfigCustomization');
const CapConfig = () => import(/* webpackChunkName: "config_cap" */ '@/apps/admin/views/admin/config/main/cap/CapConfig');
const PortalConfigPage = () => import(/* webpackChunkName: "config_portal" */ '@/apps/admin/views/admin/config/main/portal/PortalConfigPage');

export default [
    {
        delimiter: '--',
        path: 'settings',
        name: 'admin.config.main.settings',
        component: XTwigPage,
    },
    {
        path: 'localization',
        name: 'admin.config.main.localization',
        component: XTwigPage,
    },
    {
        path: 'api',
        name: 'admin.config.main.api',
        component: XTwigPage,
    },
    {
        path: 'maps',
        name: 'admin.config.main.maps',
        component: XTwigPage,
    },
    {
        path: 'cap',
        name: 'admin.config.main.cap',
        component: CapConfig,
        meta: {
            breadcrumbs: [
                { title: ['config', 'Config'], url: '/admin/config' },
                { title: ['config', 'Main'], url: '/admin/config#main' },
                { title: ['config', 'CAP'], url: '/admin/config/main/cap' },
            ],
            title: ['config', 'CAP settings'],
        },
    },
    {
        path: 'mail',
        name: 'admin.config.main.mail',
        component: XTwigPage,
    },
    {
        path: 'portal',
        name: 'admin.config.main.portal',
        component: PortalConfigPage,
        meta: {
            breadcrumbs: [
                { title: ['config', 'Config'], url: '/admin/config' },
                { title: ['config', 'Main'], url: '/admin/config#main' },
                { title: ['config', 'Portal'], url: '/admin/config/main/portal' },
            ],
            title: ['config', 'Portal settings'],
        },
    },
    {
        path: 'customization',
        name: 'admin.config.main.customization',
        component: ConfigCustomization,
        meta: {
            breadcrumbs: [
                { title: ['config', 'Config'], url: '/admin/config' },
                { title: ['config', 'Main'], url: '/admin/config#main' },
                { title: ['config', 'Site customization'], url: '/admin/config/main/customization' },
            ],
            title: ['config', 'Site customization'],
        },
    },
];
