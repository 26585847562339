import XTwigPage from '@/components/base/XTwigPage';

const InternetPlansList = () => import(/* webpackChunkName: "internet_plans" */ '@/apps/admin/views/admin/tariffs/InternetPlansList');
const InternetPlansAddEdit = () => import(/* webpackChunkName: "internet_plans" */ '@/apps/admin/views/admin/tariffs/internet/InternetPlansAddEdit');

export default [
    {
        path: '',
        name: 'admin.tariffs.internet',
        component: InternetPlansList,
        meta: {
            breadcrumbs: [
                { title: ['common', 'Tariffs plans'], url: '/admin/tariffs/internet' },
                { title: ['common', 'Internet'], url: '/admin/tariffs/internet' },
            ],
            title: ['tariffs', 'Tariffs'],
            keepAlive: true,
            componentName: 'InternetPlansList',
        },
    },
    {
        delimiter: '--', // Use -- instead of / as path delimiter. Custom prop. Not from vue-router.
        path: 'add',
        name: 'admin.tariffs.internet.add',
        component: InternetPlansAddEdit,
        meta: {
            edit: false,
            breadcrumbs: [
                { title: ['common', 'Tariffs plans'], url: '/admin/tariffs/internet' },
                { title: ['common', 'Internet'], url: '/admin/tariffs/internet' },
                { title: ['common', 'Add'], url: '/admin/tariffs/internet--add' },
            ],
            title: ['tariffs', 'Tariffs'],
        },
    },
    {
        delimiter: '--',
        path: 'edit',
        name: 'admin.tariffs.internet.edit',
        component: InternetPlansAddEdit,
        meta: {
            edit: true,
            breadcrumbs: [
                { title: ['common', 'Tariffs plans'], url: '/admin/tariffs/internet' },
                { title: ['common', 'Internet'], url: '/admin/tariffs/internet' },
            ],
            title: ['tariffs', 'Tariffs'],
        },
    },
    {
        delimiter: '--',
        path: 'change-tariff-on-services',
        name: 'admin.tariffs.internet.change_tariff_on_services',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'table',
        name: 'admin.tariffs.internet.table',
        component: XTwigPage,
        meta: {
            title: ['tariffs', 'Tariff plan table'],
        },
    },
];
