import XTwigPage from '@/components/base/XTwigPage';
import ListRoutes from '@/apps/admin/router/routes/admin/customers/cards/list';
import ViewRoutes from '@/apps/admin/router/routes/admin/customers/cards/view';

export default [
    {
        path: 'generate',
        name: 'admin.customers.cards.generate',
        component: XTwigPage,
    },
    {
        path: 'series',
        name: 'admin.customers.cards.series',
        component: XTwigPage,
    },
    {
        path: 'search',
        name: 'admin.customers.cards.search',
        component: XTwigPage,
    },
    {
        path: 'list',
        children: ListRoutes,
    },
    {
        path: 'view',
        children: ViewRoutes,
    },
];
