import XTwigPage from '@/components/base/XTwigPage';
import ViewRoutes from '@/apps/admin/router/routes/admin/networking/routers/view';

const RoutersList = () => import(/* webpackChunkName: "routers_list" */ '@/apps/admin/views/admin/networking/routers/RoutersList');

export default [
    {
        path: 'add',
        name: 'admin.networking.routers.add',
        component: XTwigPage,
    },
    {
        path: 'list',
        name: 'admin.networking.routers.list',
        component: RoutersList,
        meta: {
            keepAlive: true,
            componentName: 'RoutersList',
        },
    },
    {
        path: 'view',
        children: ViewRoutes,
    },
];
