<template>
    <div class="d-flex justify-content-between align-items-end">
        <div class="d-flex flex-column">
            <h3>{{ phone }}</h3>
            <span v-if="assigned"><span class="text-secondary">{{ $t('messengers', 'Assigned to') }}</span>&nbsp;{{ assigned }}</span>
        </div>
        <div
            class="chat-head-actions"
        >
            <div class="btn-group dropdown">
                <button
                    class="btn btn-primary dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {{ $t('common', 'Actions') }}
                </button>
                <ul
                    class="dropdown-menu"
                >
                    <li
                        class="dropdown-item"
                    >
                        <a
                            href="javascript:void(0)"
                            @click="$emit('set-mass-action-select-enabled', !massActionSelectEnabled)"
                        >
                            {{ massActionSelectEnabled ? $t('messengers', 'Deselect messages') : $t('messengers', 'Select messages') }}
                        </a>
                    </li>
                    <li
                        v-for="action of actions"
                        :key="action.name"
                        class="dropdown-item"
                    >
                        <a
                            href="javascript:void(0)"

                            data-submit="true"
                            data-activities="hide"
                            @click="$emit(action.name)"
                        >
                            {{ action.title }}{{ action.selectedCount }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import HeadMassActionsMixin from '@/apps/admin/mixins/messengers/head/head-mass-actions-mixin';

export default {
    name: 'ChatHeadMinimal',
    mixins: [HeadMassActionsMixin],
    props: {
        phone: {
            type: String,
            default: '',
        },
        assigned: {
            type: String,
            default: '',
        },
        showActions: Boolean,
        actions: Array,
    },
};
</script>
