export default {
    props: {
        id: [Number, String],
        type: String,
        isPws: Boolean,
        audioFormat: String,
        downloadUrl: [String, Boolean],
        ratio: String,
        title: String,
        url: String,
        size: String,
        link: String,
        name: String,
    },
    computed: {
        elementId() {
            return `message_attachment_${this.id}`;
        },
    },
    methods: {
        downloadFile(link) {
            return window.download_by_iframe(link);
        },
        attachmentsUpdated() {
            window.initPhotoSwiper();
        },
    },
    mounted() {
        setTimeout(() => {
            this.attachmentsUpdated(document.querySelector('.splynx-photo-swiper'));
        }, 0);
    },
};
