import { empty, promisifiedAjax } from '@/utils/functions';

/**
 * Dependencies:
 * data:
 *  - model
 *   - related_customer_id
 * methods:
 *  - setAddressAction
 *  - setCoordinateAction
 *  - renderTemplates
 *  - setGeoDataSource
 */
export default {
    data() {
        return {
            showRelatedServices: false,
            serviceRelated: {},
        };
    },
    methods: {
        changeRelatedServices() {
            this.renderTemplates();
        },
        getRelated() {
            let customerId = this.model.related_customer_id || 0;
            promisifiedAjax({
                url: `/admin/scheduling/tasks--load-services?customerId=${customerId}`,
            }).then((response) => {
                if (empty(response)) {
                    this.showRelatedServices = false;
                    this.serviceRelated = { 0: this.$t('scheduling', 'No services') };
                } else {
                    this.serviceRelated = response;
                    this.showRelatedServices = true;
                }
            });
        },
    },
};
