export default class Part {
    earlierPart = {}

    laterPart = {}

    central = null

    conversations = {}

    messages = []

    order = []

    isLast = false

    isFirst = false

    constructor(laterPart, earlierPart) {
        this.laterPart = laterPart;
        this.earlierPart = earlierPart;

        this.central = this.laterPart.central;
        this.isLast = this.laterPart.laterMessage == null;
        this.isFirst = this.earlierPart.earlierMessage == null;
    }

    getEarlierCentral() {
        return this.earlierPart.earliestMessage;
    }

    getLaterCentral() {
        return this.laterPart.latestMessage;
    }

    get() {
        const merged = {};
        merged.isFirst = this.isFirst;
        merged.isLast = this.isLast;
        merged.getEarlierCentral = this.getEarlierCentral.bind(this);
        merged.getLaterCentral = this.getLaterCentral.bind(this);
        merged.earlierMessage = this.earlierPart.earlierMessage;
        merged.laterMessage = this.laterPart.laterMessage;
        merged.messages = [
            ...this.laterPart.get().messages,
            ...this.earlierPart.get().messages,
        ];
        merged.conversations = {
            ...this.laterPart.get().conversations,
            ...this.earlierPart.get().conversations,
        };
        merged.order = [
            ...this.laterPart.get().order,
            ...this.earlierPart.get().order,
        ];
        return merged;
    }
}
