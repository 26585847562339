<template>
    <div class="chat-message-activities mt-8 mb-8">
        <div
            v-for="(activity, index) of activities"
            :key="index + 'activity'"
        >
            <div v-html="activity.content" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChatMessageActivities',
    props: {
        activities: Array,
    },
};
</script>
