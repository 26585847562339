import {
    SET_PERMISSIONS_INFO,
    SET_PERMISSIONS_TABS,
    ADD_CHANGED_ELEMENTS,
    RESET_CHANGED_ELEMENTS,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';
import { isArray, isObject, isset } from '@/utils/functions';

const state = {
    id: null,
    type: null,
    tabsContent: [],
    changedElements: [],
};

const getters = {
    ...stateToGetters(state),
    url(state) {
        let param = state.type === 'roles' ? 'name' : 'id';
        return `/admin/administration/${state.type}--permissions?${param}=${state.id}`;
    },
    saveList(state) {
        let data = [];

        state.changedElements.forEach((elem) => {
            let { controller, action } = elem;
            let rule = elem.checked ? 'allow' : 'deny';

            if (!empty(controller) && !empty(action) && !empty(rule)) {
                data.push({
                    controller,
                    action,
                    rule,
                });
            }
        });

        return data;
    },
};

const actions = {
    loadData({ commit, getters }, data) {
        commit(SET_PERMISSIONS_INFO, data);
        commit(RESET_CHANGED_ELEMENTS);

        return new Promise((resolve, reject) => {
            $.ajax({
                url: getters.url,
                method: 'GET',
                success: (response) => {
                    commit(SET_PERMISSIONS_TABS, response);
                    resolve();
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },
    addChangedElement({ state, commit }, data) {
        changeElement(state.tabsContent[data.index].items, data.item);
        commit(ADD_CHANGED_ELEMENTS, { ...data.item });
    },
    selectUnselectAllChildren({ state, commit }, data) {
        let result = [];
        let changeAllChildren = (array, item, value) => {
            array.forEach((el) => {
                if (el.controller === item.controller && el.action === item.action) {
                    el.checked = value;
                    result.push({ ...el });
                    let change = (child) => {
                        child.checked = value;
                        result.push({ ...child });
                        if (isset(child, 'items') && child.items.length > 0) {
                            child.items.forEach(change);
                        }
                    };
                    el.items.forEach(change);
                }
                if (isset(el, 'items') && el.items.length > 0) {
                    changeAllChildren(el.items, item, value);
                }
            });
        };
        changeAllChildren(state.tabsContent[data.index].items, data.item, data.value);
        commit(ADD_CHANGED_ELEMENTS, result);
    },
    changeAllElements({ state, commit }, data) {
        let result = [];
        let change = (array, value) => {
            array.forEach((el) => {
                if (el.checked !== value) {
                    el.checked = value;
                    result.push({ ...el });
                }
                if (isset(el, 'items') && el.items.length > 0) {
                    change(el.items, value);
                }
            });
        };
        change(state.tabsContent[data.index].items, data.value);
        commit(ADD_CHANGED_ELEMENTS, result);
    },
    saveData({ commit, getters }) {
        if (empty(getters.saveList)) {
            show_error(window.xApp.$splang.t('common', 'Not changes'), 4);
            return;
        }

        $.ajax({
            type: 'POST',
            url: getters.url,
            dataType: 'json',
            data: {
                data: JSON.stringify(getters.saveList),
            },
            success(response) {
                if (response.result) {
                    show_success(response.message, 4);
                    commit(RESET_CHANGED_ELEMENTS);
                } else {
                    showErrors(response.message, 4);
                }
            },
        });
    },
};

const mutations = {
    [SET_PERMISSIONS_INFO](state, data) {
        for (let k in data) {
            state[k] = data[k];
        }
    },
    [SET_PERMISSIONS_TABS](state, data) {
        let tabs = data.controllers_map;
        state.tabsContent = isObject(tabs) ? Object.values(tabs) : tabs;
    },
    [ADD_CHANGED_ELEMENTS](state, result) {
        let add = (data) => {
            if (!state.changedElements.some((el) => el.controller === data.controller && el.action === data.action)) {
                state.changedElements.push({ ...data });
            } else {
                let index = state.changedElements.map((elem) => elem['elem-id']).indexOf(data['elem-id']);
                state.changedElements.splice(index, 1);
            }
        };
        if (isArray(result)) {
            result.forEach(add);
        } else {
            add(result);
        }
    },
    [RESET_CHANGED_ELEMENTS](state) {
        state.changedElements = [];
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};

function changeElement(array, data) {
    array.forEach((el) => {
        if (el.controller === data.controller && el.action === data.action) {
            el.checked = data.checked;
        }
        if (isset(el, 'items') && el.items.length > 0) {
            changeElement(el.items, data);
        }
    });
}
