import Throttler from '@/utils/classes/promise-queue';
import Message from '@/apps/admin/services/messengers/Message';

export default {
    data() {
        return {
            initState: true,
            messagesTopLoading: false,
            messagesBottomLoading: false,
            prevScrollPos: 0,
            scrollLoadPartThrottler: null,
            autoScrollThrottler: null,
            chatOpenedFromStart: false,
            chatOpenedFromEnd: false,
            displayScrollDownButton: false,
        };
    },
    mounted() {
        this.autoScrollThrottler = new Throttler();
        this.scrollLoadPartThrottler = new Throttler();

        this.$watch(
            () => this.autoScrollThrottler.currentPromise,
            (queue) => {
                if (queue === null) {
                    this.scrollHandler(this.preLastMessageInFocus);
                }
            },
        );

        this.$watch(
            () => this.scrollLoadPartThrottler.currentPromise,
            (queue) => {
                if (queue === null) {
                    let data = this.scrollLoadPartThrottler.resolveData.filter((item) => !!item);
                    this[data[data.length - 1].action]();
                }
            },
        );
    },
    beforeDestroy() {
        this.$refs.scroll?.removeEventListener('scroll', this.onScroll);
    },
    computed: {
        lastMessageInFocus() {
            return this.visibleInContainer(this.$refs.scroll, this.lastMessageElement);
        },
        preLastMessageInFocus() {
            return this.visibleInContainer(this.$refs.scroll, this.preLastMessageElement);
        },
        preLastMessageElement() {
            return document.querySelector(`#${this.uid}.messenger-chat-block #message_${this.preLastMessage.id}`);
        },
        lastMessageElement() {
            return document.querySelector(`#${this.uid}.messenger-chat-block #message_${this.lastMessage.id}`);
        },
        lastMessage() {
            return this.currentPart.messages
                .find((message) => message.id === this.currentPart.order[0]);
        },
        preLastMessage() {
            return this.currentPart.messages
                .find((message) => message.id === this.currentPart.order[1]);
        },
        isFirstPart() {
            return this.currentPart.isFirst || this.chatOpenedFromStart;
        },
        isLastPart() {
            return this.currentPart.isLast || this.chatOpenedFromEnd;
        },
    },
    methods: {
        // Callbacks
        callbackDown(height, lastHeight, current) {
            if (current >= height) {
                this.scrollLoadPartThrottler.addToHandlers(() => new Promise((resolve) => {
                    setTimeout(() => { resolve({ action: 'loadDownArea' }); }, 50);
                }));
            }
        },
        callbackUp(firstHeight, current) {
            if (current <= 0) {
                this.scrollLoadPartThrottler.addToHandlers(() => new Promise((resolve) => {
                    setTimeout(() => { resolve({ action: 'loadUpArea' }); }, 50);
                }));
            }
        },
        loadUpArea() {
            if (!this.isFirstPart) {
                this.loadEarlier();
            }
        },
        loadDownArea() {
            if (!this.isLastPart) {
                this.loadLatest();
            }
        },
        async scrollDown() {
            if (this.isLastPart) {
                this.scrollEnd(true);
            } else {
                await this.loadLastPart();
                this.chatOpenedFromStart = false;
                this.chatOpenedFromEnd = true;
            }
        },
        onScroll() {
            const currentScrollPos = this.$refs.scroll.scrollTop;
            const messages = document.querySelectorAll(`#${this.uid}.messenger-chat-block .message`);
            const { lastFiveHeight, firstFiveHeight } = this.calculateElementHeights(messages);

            if (this.prevScrollPos > currentScrollPos) {
                this.callbackUp(firstFiveHeight, this.$refs.scroll.scrollTop);
            } else if (this.prevScrollPos < currentScrollPos) {
                this.callbackDown(
                    this.$refs.scroll?.scrollHeight,
                    lastFiveHeight,
                    this.$refs.scroll.scrollTop + this.$refs.scroll.clientHeight,
                );
            }

            this.prevScrollPos = this.$refs.scroll?.scrollTop;
        },
        calculateElementHeights(elements) {
            let totalHeight = 0;
            let firstFiveHeight = 0;
            let lastFiveHeight = 0;
            if (elements.length >= 5) {
                for (let i = 0; i < 5; i++) {
                    firstFiveHeight += elements[i].offsetHeight;
                }
                for (let i = elements.length - 5; i < elements.length; i++) {
                    lastFiveHeight += elements[i].offsetHeight;
                }
                for (let i = 0; i < elements.length; i++) {
                    totalHeight += elements[i].offsetHeight;
                }
            } else {
                firstFiveHeight = totalHeight;
                lastFiveHeight = totalHeight;
            }
            return {
                totalHeight,
                firstFiveHeight,
                lastFiveHeight,
            };
        },
        initScrollListeners() {
            this.prevScrollPos = this.$refs.scroll?.scrollTop;
            this.$refs.scroll?.addEventListener('scroll', this.onScroll);
        },
        messagesChanged() {
            if (this.initState || this.messagesTopLoading || this.messagesBottomLoading) {
                return false;
            }
            this.autoScrollThrottler.addToHandlers(() => new Promise((resolve) => {
                setTimeout(() => { resolve({}); }, 50);
            }));
        },
        visibleInContainer(view, target) {
            if (!view || !target) {
                return false;
            }
            const containerBottom = view.scrollTop + view.clientHeight;
            const targetBottom = target.offsetTop + target.clientHeight;
            return target.offsetTop >= view.scrollTop && targetBottom <= containerBottom;
        },
        scrollToMessage(id, smooth = false, to = 'center') {
            const message = document.querySelector(`#${this.uid} #message_${id}`);
            if (!message) {
                return false;
            }
            this.scrollToChildren(this.$refs.scroll, message, to, smooth);
        },
        scrollEnd(smooth = false) {
            if (!this.$refs.scroll) {
                return false;
            }
            const { scrollHeight } = this.$refs.scroll;
            const config = { top: scrollHeight };
            if (smooth) {
                config.behavior = 'smooth';
            }
            this.$refs.scroll.scrollTo(config);
        },
        scrollHandler(isScroll) {
            if (this.messagesTopLoading || this.messagesBottomLoading) {
                return false;
            }
            if (isScroll || this.currentPart.messages[this.currentPart.messages.length - 1].type === Message.TYPE_OUT) {
                this.scrollEnd(true);
            } else {
                this.$showInformation(this.$t('messengers', 'Below is a new message'), 4);
            }
        },
        showScrollButton() {
            this.$refs.scroll?.addEventListener('scroll', () => {
                const { scrollTop, clientHeight, scrollHeight } = this.$refs.scroll;
                this.displayScrollDownButton = scrollTop + clientHeight < scrollHeight - clientHeight;
            });
        },
        scrollToChildren(HTMLContainer, target, scrollPosition = 'bottom', smooth = true) {
            const containerVisibleHeight = HTMLContainer.clientHeight;
            let childrenScrollPosition;
            if (scrollPosition === 'top') {
                childrenScrollPosition = target.offsetTop;
            } else if (scrollPosition === 'bottom') {
                childrenScrollPosition = target.offsetTop - containerVisibleHeight + target.clientHeight;
            } else {
                childrenScrollPosition = target.offsetTop - (containerVisibleHeight / 2) + (target.clientHeight / 2);
            }
            const config = {
                top: childrenScrollPosition,
            };
            if (smooth) {
                config.behavior = 'smooth';
            }
            HTMLContainer.scrollTo(config);
            setTimeout(() => {
                // Fix mobile white screen
                HTMLContainer.scrollTo(config);
            }, 100);
        },
    },
    watch: {
        // From ChatView.vue
        'currentPart.messages': {
            deep: false,
            handler: 'messagesChanged',
        },
    },
};
