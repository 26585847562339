import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: '',
        name: 'admin.tariffs.pricing',
        component: XTwigPage,
    },
    {
        path: 'groups',
        name: 'admin.tariffs.pricing.groups',
        component: XTwigPage,
        meta: {
            title: ['tariffs', 'Groups'],
        },
    },
    {
        path: 'preview',
        name: 'admin.tariffs.pricing.preview',
        component: XTwigPage,
        meta: {
            title: ['tariffs', 'Preview'],
        },
    },
];
