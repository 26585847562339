import { promisifiedAjax } from '@/utils/functions';

export default class HardwareService {
    static async getPageData() {
        return promisifiedAjax({ url: '/admin/networking/network-sites/hardware--get-page-data' });
    }

    static async getPageDataForHardwareView(id) {
        return promisifiedAjax({ url: `/admin/networking/monitoring/view--get-entity?id=${id}` });
    }

    static async getPageDataForCustomerServices() {
        return promisifiedAjax({ url: '/admin/networking/monitoring/services--get-page-data' });
    }

    static async loadServicesList(table, id, status = 'all') {
        return table.ajax.url(`/admin/networking/monitoring/services--get-services-list?id=${id}&status=${status}`).load();
    }

    static async relinkAccessDevice(currentDeviceId, relinkToDeviceId) {
        return promisifiedAjax({ url: '/admin/networking/monitoring/view--relink', data: { id: currentDeviceId, relinkId: relinkToDeviceId } });
    }
}
