function sendFormData(url, formData) {
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            method: 'POST',
            data: formData,
            cache: false,
            processData: false,
            contentType: false,
            type: 'POST',
            success(data) {
                resolve(data);
            },
            failed(data) {
                reject(data);
            },
        });
    });
}

export {
    sendFormData,
};

export default {
    sendFormData,
};
