/**
 * Dependencies:
 * props:
 *  - statusId
 *  - newRecord
 *  - locationId
 * data:
 *  - model
 *   - workflow_status_id
 *   - location_id
 */
export default {
    methods: {
        /** @method Call it after loadData */
        __changesMixin_loadData() {
            if (this.statusId && this.newRecord) this.changeStatus(this.model.workflow_status_id);
            if (this.locationId && this.newRecord) this.changeLocation(this.model.location_id);
        },
        changeStatus() {
            this.$nextTick(() => {
                this.model.workflow_status_id = this.statusId;
            });
        },
        changeLocation() {
            this.$nextTick(() => {
                this.model.location_id = this.locationId;
            });
        },
    },
};
