import { mapActions } from 'vuex';

export default {
    data() {
        return {
            messagesStatuses: {},
        };
    },
    created() {
        this.initStatusSocketListeners();
    },
    methods: {
        ...mapActions('chats_listener', ['addChatHandlers']),
        initStatusSocketListeners() {
            this.addChatHandlers({ callbacks: [this.statusUpdate], ids: { chat: this.chatId, messenger: this.messenger } });
        },
        statusUpdate({ new_status, message_id }) {
            this.messagesStatuses = {
                ...this.messagesStatuses,
                [message_id]: new_status,
            };
        },
    },
};
