const FULL_DEVICE = 'full';
const EMPTY_DEVICE = 'full';

const SINGLE_LOADING_TYPE = 'single_loading_type';
const DOUBLE_LOADING_TYPE = 'double_loading_type';

const FIRST_ITERATION = 'first_iteration';
const SECOND_ITERATION = 'second_iteration';

export {
    FULL_DEVICE,
    EMPTY_DEVICE,
    SINGLE_LOADING_TYPE,
    DOUBLE_LOADING_TYPE,
    FIRST_ITERATION,
    SECOND_ITERATION,
};
