import {
    SET_CUSTOMER_STATEMENT_INFO, CLEAR_CUSTOMER_STATEMENT_INFO,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';

const state = {
    hideAttributesForListByDefault: [],
    types: {},
    headerForTable: [],
    columns: [],
    columnDefs: [],
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadModalInfo({ commit }, { customer_id, type }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing/transactions--info-statement?id=${customer_id}&type=${type}`,
                method: 'GET',
                success: (response) => {
                    commit(SET_CUSTOMER_STATEMENT_INFO, response);
                    resolve();
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },
};

const mutations = {
    [SET_CUSTOMER_STATEMENT_INFO](state, info) {
        for (let key in info) {
            state[key] = info[key];
        }
    },
    [CLEAR_CUSTOMER_STATEMENT_INFO](state) {
        state.hideAttributesForListByDefault = [];
        state.types = {};
        state.headerForTable = [];
        state.columns = [];
        state.columnDefs = [];
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
