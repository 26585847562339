<template>
    <div
        id="search_by_user_documentation"
        class="sidebar-content-inner"
    >
        <p v-html="$t('common', 'Use the {d_key} key at any time to open this window', {'d_key': '<code>d</code>'})" />
        <br>
        <div class="search-wrapper">
            <div class="input-group">
                <input
                    ref="searchInput"
                    v-model="searchQuery"
                    type="text"
                    autocomplete="off"
                    class="fast_search_in form-control search-input search"
                    :placeholder="$t('common', 'Search...')"
                    autofocus
                >

                <button
                    type="button"
                    :class="['btn', 'btn-outline-secondary', 'btn-icon']"
                    @click="searchTimeoutProcessor(1)"
                >
                    <i
                        v-if="searchResults.isLoading"
                        class="fa fa-circle-o-notch fa-spin"
                    />
                    <i
                        v-else
                        class="icon-ic_fluent_search_24_regular"
                    />
                </button>
            </div>
        </div>

        <div class="admin-help-panel-header">
            <strong>
                {{ headerForBlock }}
            </strong>
            <a
                v-show="isShowExportLink"
                class="admin-help-link-btn btn btn-outline-secondary btn-icon pull-right"
                target="_blank"
                :title="t('common', 'Open documentation in new window')"
                @click="goToDocumentation(exportLink)"
            >
                <i class="icon-ic_fluent_window_new_24_regular" />
            </a>
            <a
                v-show="isShowBackButton"
                class=" btn btn-outline-secondary btn-icon-text pull-right me-4"
                href="javascript:void(0)"
                @click="back"
            >
                <i class="icon-ic_fluent_arrow_hook_up_left_24_regular" />
                {{ t('common', 'back') }}
            </a>
        </div>
        <div v-if="visibleSearchResults">
            <div
                v-if="searchResults.isLoading"
                class="loader"
            >
                <x-bootstrap-spinner
                    :size="'lg'"
                />
            </div>
            <x-documentation-render
                v-else
                :content="content"
                :allow-redirect="false"
                @load-by-path="loadPageByPath"
            />
        </div>
        <div v-else>
            <div class="admin-help-content">
                <div
                    v-for="(page, index) in searchResults.items"
                    :key="index"
                    class="card search-page-block"
                    @click="loadPageByPath(page.path)"
                >
                    <div
                        class="search-block-header"
                        v-html="page.title"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import XBootstrapSpinner from '@/components/common/XBootstrapSpinner';
import XDocumentationRender from '@/components/base/XDocumentationRender/XDocumentationRender';
import { empty } from '@/utils/functions';

export default {
    name: 'XHelpPanel',
    data() {
        return {
            title: t('common', 'Loading..'),
            content: '',
            suffixForDescription: '...',
            lengthForDescription: 200,
            path: '',
            currentPath: '',
            history: [],
            lastSplynxUrl: null,
            showExportLink: true,
            searchQuery: null,
            searchTimoutId: null,
            pageSizeForSearch: 10,
            searchResults: {
                isLoading: false,
                count: 0,
                disableButtonMore: false,
                isEmpty: false,
                items: [],
            },
            previousSearchQuery: {},
        };
    },
    methods: {
        searchTimeoutProcessor(page = 1) {
            if (this.searchQuery === null || this.searchQuery === this.previousSearchQuery) {
                return;
            }
            if (this.searchTimoutId !== null) {
                clearTimeout(this.searchTimoutId);
            }
            this.previousSearchQuery = this.searchQuery;
            this.searchTimoutId = setTimeout(this.makeSearchAjaxQuery, 300, page);
        },
        makeSearchAjaxQuery(page) {
            this.searchTimoutId = null;
            if (this.searchQuery.length <= 1) {
                return;
            }
            if (page == 1) {
                this.resetVariableSearchResults();
            }
            this.searchResults.isLoading = true;

            let queryString = this.searchQuery.replace('#', '%23');

            this.history = [];
            let requestUrl = `${this.getApiUrl()}/pages/search`;

            let that = this;
            this.history.push({
                requestUrl,
                data: {
                    query: queryString,
                    lang: this.lang,
                },
                method: 'GET',
                search: true,
            });
            this.lastSplynxUrl = requestUrl;
            this.currentPath = `page/search?text=${queryString}`;

            if (this.lang !== '') {
                $.ajax({
                    url: requestUrl,
                    method: 'GET',
                    data: {
                        query: queryString,
                        lang: this.lang,
                        version: this.version,
                    },
                    dataType: 'json',
                    success(response) {
                        that.searchResults.count = response.count;
                        that.searchResults.isEmpty = true;

                        if (response.items) {
                            response.items.forEach((item) => {
                                that.searchResults.items.push(item);
                            });
                        }

                        if (that.showExportLink === false) {
                            if (typeof window.history !== 'undefined') {
                                // Update current page url
                                window.history.pushState({}, document.title, that.exportLink);
                            }
                        }
                        that.searchResults.isLoading = false;
                    },
                    error(response) {
                        console.error('Open page ', requestUrl, 'failed with response:', response);
                        that.currentPath = '';
                    },
                });
            }
        },
        clearSearchQuery() {
            this.searchQuery = null;
            this.searchTimoutId = null;
            this.resetVariableSearchResults();
        },
        resetVariableSearchResults() {
            this.searchResults = {
                count: 0,
                disableButtonMore: false,
                isEmpty: false,
                items: [],
            };
        },
        processKeyboard(e) {
            if (e.keyCode === 13) { // Enter
                this.searchTimeoutProcessor();
            }
        },
        back() {
            // Remove current page from history
            this.history.pop();
            if (this.history.length > 0) {
                let history = this.history.pop();
                if (history.search == true) {
                    this.searchQuery = history.data.query;
                    this.searchTimeoutProcessor();
                    return;
                }
                this.loadPage(history.requestUrl, history.data, history.method);
            } else {
                this.loadPageByPath('home');
            }
        },
        ltrim(char, s) {
            if (s.length < 1 || typeof s !== 'string') {
                return s;
            }
            if (s[0] === char) {
                return s.slice(1);
            }
            return s;
        },
        rtrim(char, s) {
            if (s.length < 1 || typeof s !== 'string') {
                return s;
            }
            if (s[s.length - 1] === char) {
                return s.slice(0, s.length - 1);
            }

            return s;
        },
        wikiDomain() {
            return `${this.rtrim('/', this.apiUrl)}`;
        },
        getApiUrl() {
            return `${this.wikiDomain()}/api`;
        },
        loadPage(requestUrl, data, method) {
            let that = this;
            this.history.push({
                requestUrl,
                data,
                method,
                search: false,
            });
            this.lastSplynxUrl = requestUrl;

            if (this.lang !== '') {
                $.ajax({
                    url: `${requestUrl}?lang=${this.lang}&version=${this.version}`,
                    method,
                    data,
                    dataType: 'json',
                    success(response) {
                        that.title = response.title;
                        that.content = response.rendered_content;
                        that.currentPath = response.path;
                        that.clearSearchQuery();
                        if (that.showExportLink === false) {
                            if (typeof window.history !== 'undefined') {
                                // Update current page url
                                window.history.pushState({}, document.title, that.exportLink);
                            }
                        }
                        that.previousSearchQuery = {};
                    },
                    error(response) {
                        console.error('Open page ', requestUrl, 'failed with response:', response);
                        that.title = t('common', 'Not found!');
                        that.content = t('common', 'Page not found!');
                        that.currentPath = '';
                    },
                });
            }
        },
        loadPageByPath(path) {
            let url = this.prepareLinkForLoadingByPath(path);
            const hashIndex = url.indexOf('#');
            if (hashIndex !== -1) {
                const basePath = url.substring(0, hashIndex);
                const currentUrl = this.prepareLinkForLoadingByPath(this.currentPath);
                if (basePath === currentUrl) {
                    const elementHash = url.substring(hashIndex);

                    const selectors = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
                    for (let key in selectors) {
                        const element = this.$el.querySelector(`${selectors[key]}${elementHash}`);
                        if (!empty(element)) {
                            element.scrollIntoView({ behavior: 'smooth' });
                            return;
                        }
                    }

                    return;
                }
            }
            let requestUrl = `${this.getApiUrl()}/pages/by-path/${url}`;
            this.loadPage(requestUrl, {
                lang: this.lang,
                version: this.version,
            }, 'GET');
        },
        prepareLinkForLoadingByPath(link) {
            let url = link.replace(this.version, '').replace(`/${this.lang}/`, '').replace('/en/', '');
            return this.ltrim('/', url);
        },
        loadPageByUrl(url) {
            this.history = [];
            let requestUrl = `${this.getApiUrl()}/pages/by-url`;
            this.loadPage(requestUrl, { url: this.ltrim('/', url) }, 'POST');
        },
        isIndexPage() {
            return this.currentPath === 'home';
        },
        reopen() {
            let currentAddress = this.getCurrentUrl();
            if (currentAddress !== this.lastSplynxUrl) {
                this.loadPageByUrl(currentAddress);
            }
        },
        getCurrentUrl() {
            return xApp.$route.fullPath;
        },
        goToDocumentation(href) {
            window.open(href);
        },
    },
    computed: {
        ...mapGetters([
            'documentationConfig',
        ]),
        isShowBackButton() {
            return !this.isIndexPage();
        },
        isShowExportLink() {
            if (!this.visibleSearchResults) {
                return false;
            }
            return this.showExportLink === true;
        },
        exportLink() {
            return `${this.apiUrl}/${this.currentPath}`;
        },
        apiUrl() {
            return this.documentationConfig.api_url;
        },
        lang() {
            return this.documentationConfig.lang;
        },
        version() {
            return this.documentationConfig.version;
        },
        visibleSearchResults() {
            return !this.searchResults.isEmpty && this.isSearchResult;
        },
        isSearchResult() {
            return empty(this.searchResults.items);
        },
        headerForBlock() {
            if (this.visibleSearchResults) {
                return this.title;
            }

            if (this.searchResults.count > 0) {
                return this.t('common', 'Found {count} matches;', {
                    count: this.searchResults.count,
                });
            }

            return this.t('common', 'Found 0 matches, try changing the query');
        },
    },
    mounted() {
        if (this.path) {
            this.loadPageByPath(this.path);
        } else {
            this.loadPageByUrl(this.getCurrentUrl());
        }
        document.getElementById('search_by_user_documentation').addEventListener('keydown', this.processKeyboard);
    },
    components: {
        XBootstrapSpinner,
        XDocumentationRender,
    },
};
</script>
