<template>
    <x-view-error
        application="admin"
        :error="{ code: 404 }"
    />
</template>

<script>
import XViewError from '@/components/base/XViewError/XViewError';

export default {
    name: 'NotFoundView',
    components: { XViewError },
};
</script>
