var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.classes},[(_vm.active)?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.canAdminWrite),expression:"canAdminWrite"}],staticClass:"position-absolute chat-field-attach",on:{"click":_vm.openAttachmentDialog}},[_c('i',{staticClass:"icon-ic_fluent_attach_24_regular"})]),_c('x-complex-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.canAdminWrite),expression:"canAdminWrite"}],ref:"input",attrs:{"type":"textarea","params":{
                options: {
                    rows: _vm.rows,
                    style: 'width: 100%',
                    placeholder: _vm.$t('messengers', 'Write your message'),
                },
            },"native-event-handlers":{
                'keyup': _vm.onKeyup,
            }},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),(!_vm.canAdminWrite)?_c('button',{staticClass:"btn btn-primary ms-16 nowrap",attrs:{"disabled":_vm.loading,"type":"button"},on:{"click":_vm.openReassignToMeDialog}},[_vm._v(" "+_vm._s(_vm.$t('messengers', 'Assign to me'))+" ")]):_c('button',{class:_vm.buttonSendClasses,attrs:{"disabled":_vm.loading,"type":"button"},on:{"click":_vm.send}},[_vm._v(" "+_vm._s(_vm.$t('messengers', 'Send'))+" ")])]:[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.loading,"type":"button"},on:{"click":function($event){return _vm.$emit('start-conversation')}}},[_vm._v(" "+_vm._s(_vm.$t('messengers', 'Start conversation'))+" ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }