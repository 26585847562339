const BundlePlansList = () => import(/* webpackChunkName: "bundle_plans" */ '@/apps/admin/views/admin/tariffs/BundlePlansList');
const BundleTariffPage = () => import(/* webpackChunkName: "bundle_plans" */ '@/apps/admin/views/admin/tariffs/bundles/BundleTariffPage');

export default [
    {
        path: '',
        name: 'admin.tariffs.bundles',
        component: BundlePlansList,
        meta: {
            breadcrumbs: [
                { title: ['common', 'Tariffs plans'], url: '/admin/tariffs/bundles' },
                { title: ['common', 'Bundles'], url: '/admin/tariffs/bundles' },
            ],
            title: ['tariffs', 'Bundles'],
            keepAlive: true,
            componentName: 'BundlePlansList',
        },
    },
    {
        delimiter: '--', // Use -- instead of / as path delimiter. Custom prop. Not from vue-router.
        path: 'add',
        name: 'admin.tariffs.bundles.add',
        component: BundleTariffPage,
        meta: {
            edit: false,
            breadcrumbs: [
                { title: ['common', 'Tariffs plans'], url: '/admin/tariffs/bundles' },
                { title: ['common', 'Bundles'], url: '/admin/tariffs/bundles' },
                { title: ['common', 'Add'] },
            ],
            title: ['tariffs', 'Bundles'],
            componentName: 'BundleTariffPage',
        },
    },
    {
        delimiter: '--',
        path: 'edit',
        name: 'admin.tariffs.bundles.edit',
        component: BundleTariffPage,
        meta: {
            edit: true,
            breadcrumbs: [
                { title: ['common', 'Tariffs plans'], url: '/admin/tariffs/bundles' },
                { title: ['common', 'Bundles'], url: '/admin/tariffs/bundles' },
            ],
            title: ['tariffs', 'Bundles'],
            componentName: 'BundleTariffPage',
        },
    },
];
