import License from '@/apps/admin/views/admin/license/License';
import EnterLicense from '@/apps/admin/views/admin/license/EnterLicense';
import { LAYOUT_EMPTY } from '@/constants';

export default [
    {
        name: 'admin.license',
        path: '/',
        component: License,
        meta: {
            layout: LAYOUT_EMPTY,
        },
    },
    {
        delimiter: '--',
        name: 'admin.license.enter',
        path: 'enter',
        component: EnterLicense,
        meta: {
            layout: LAYOUT_EMPTY,
        },
    },
];
