import { stateToGetters } from '@/apps/admin/store/helpers';
import MessengersService from '@/apps/admin/services/messengers/MessengersService';
import { SET_CUSTOMER_MESSENGERS_LIST, SET_CUSTOMER_MESSENGERS_HEADERS, SET_CUSTOMER_MESSENGERS_COLUMNS } from '@/apps/admin/store/mutation-types';

const state = {
    admin: window?.spl_config?.spl_user_id || '',
    messengers: [],
    columns: [],
    headers: [],
};

const mutations = {
    [SET_CUSTOMER_MESSENGERS_LIST](state, data) {
        state.messengers = data;
    },
    [SET_CUSTOMER_MESSENGERS_COLUMNS](state, data) {
        state.columns = data;
    },
    [SET_CUSTOMER_MESSENGERS_HEADERS](state, data) {
        state.headers = data;
    },
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    async getConversationsPageData({ commit }) {
        const service = new MessengersService('customer');
        const data = await service.getConversationsPageData();
        commit(SET_CUSTOMER_MESSENGERS_LIST, data.messengers);
        commit(SET_CUSTOMER_MESSENGERS_COLUMNS, data.columns);
        commit(SET_CUSTOMER_MESSENGERS_HEADERS, data.tableHeaders);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
