<template>
    <div class="d-flex flex-column justify-content-between h-100">
        <chat-box-create-head
            :messenger-name="messengerTitle"
            @back="$emit('back', onlyMessenger)"
        />
        <x-addon-vue-code
            :module="module"
            :point="point"
            :params="{ messengerId: messengerId, chatBox: true, phone }"
            :point-params="{ customer_id: customerId }"
            @back="$emit('back', onlyMessenger)"
            @go-to-chat="$emit('go-to-chat', $event)"
        />
    </div>
</template>

<script>
import ChatBoxCreateHead from '@/components/common/XMessengersChatbox/create/ChatBoxCreateHead';
import XAddonVueCode from '@/components/dialogs/add-ons/XAddonVueCode';

export default {
    name: 'ChatBoxCreateForm',
    props: {
        onlyMessenger: Boolean,
        messengerId: [String, Number],
        messengerTitle: String,
        module: String,
        point: String,
        phone: String,
        customerId: [String, Number],
    },
    components: {
        XAddonVueCode,
        ChatBoxCreateHead,
    },
};
</script>
