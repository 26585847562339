import XTwigPage from '@/components/base/XTwigPage';

const BankStatementsHistory = () => import(/* webpackChunkName: "bank_statements" */ '@/apps/admin/views/admin/finance/bank-statements/BankStatementsHistory');

export default [
    {
        path: 'process',
        name: 'admin.finance.bank_statements.process',
        component: XTwigPage,
    },
    {
        path: 'history',
        name: 'admin.finance.bank_statements.history',
        component: BankStatementsHistory,
        meta: {
            keepAlive: true,
            componentName: 'BankStatementsHistory',
        },
    },
    {
        path: 'view',
        name: 'admin.finance.bank_statements.view',
        component: XTwigPage,
    },
    {
        path: 'view--view-unprocessed',
        name: 'admin.finance.bank_statements.view_unprocessed',
        component: XTwigPage,
    },
    {
        path: 'view--pair',
        name: 'admin.finance.bank_statements.pair',
        component: XTwigPage,
    },
];
