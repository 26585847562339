import { SET_ACTIVE_TAB } from './mutation-types';

export default {
    switchTab({ commit }, value) {
        commit(SET_ACTIVE_TAB, value);
    },
    clearTab({ commit }) {
        commit(SET_ACTIVE_TAB, '');
    },
};
