const InventoryProductsView = () => import(/* webpackChunkName: "inventory_products_view" */ '@/apps/admin/views/admin/inventory/products/InventoryProductsView.vue');
const InventoryProductsList = () => import(/* webpackChunkName: "inventory_products" */ '@/apps/admin/views/admin/inventory/products/InventoryProductsList');

export default [
    {
        path: 'view',
        name: 'admin.inventory.products.view',
        component: InventoryProductsView,
        meta: {
            componentName: 'InventoryProductsView',
            breadcrumbs: [
                { title: ['inventory', 'Inventory'], url: '/admin/inventory/dashboard' },
                { title: ['inventory', 'Products'], url: '/admin/inventory/products' },
            ],
            title: ['inventory', 'Product'],
        },
    },
    {
        path: '',
        name: 'admin.inventory.products',
        component: InventoryProductsList,
        meta: {
            keepAlive: true,
            componentName: 'InventoryProductsList',
        },
    },
];
