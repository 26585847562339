var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-block"},[_c('div',{staticClass:"card-block-header"},[_c('h4',{domProps:{"textContent":_vm._s(_vm.$t('tariffs', 'Plan availability'))}})]),_c('div',{staticClass:"card-block-body"},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.getAttributeLabel('show_on_customer_portal'))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('x-complex-input',{attrs:{"value":_vm.value.show_on_customer_portal,"type":"checkbox","params":{
                        options: {
                            name: _vm.className + '[show_on_customer_portal]',
                        }
                    },"meta":_vm.attributesMeta.show_on_customer_portal},on:{"input":function($event){return _vm.inputHandler('show_on_customer_portal', $event)}}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.getAttributeLabel('hide_on_admin_portal'))+" "),_c('a',{directives:[{name:"x-tooltip",rawName:"v-x-tooltip:helpTooltip.top",value:(_vm.HideOnAdminPortalTooltip),expression:"HideOnAdminPortalTooltip",arg:"helpTooltip",modifiers:{"top":true}}],staticClass:"btn-icon-sm",attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"icon-ic_fluent_info_24_regular"})])]),_c('div',{staticClass:"col-md-8"},[_c('x-complex-input',{attrs:{"value":_vm.value.hide_on_admin_portal,"type":"checkbox","params":{
                        options: {
                            name: _vm.className + '[hide_on_admin_portal]',
                        }
                    },"meta":_vm.attributesMeta.hide_on_admin_portal},on:{"input":function($event){return _vm.inputHandler('hide_on_admin_portal', $event)}}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.getAttributeLabel('available_for_locations'))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('x-complex-input',{attrs:{"value":_vm.value.available_for_locations,"type":"multiple-select","params":{
                        options: {
                            options: _vm.getLocations,
                            name: _vm.className + '[available_for_locations]',
                        },
                    },"meta":_vm.attributesMeta.available_for_locations},on:{"input":function($event){return _vm.inputHandler('available_for_locations', $event)}}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.getAttributeLabel('customer_categories'))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('x-complex-input',{attrs:{"value":_vm.value.customer_categories,"type":"multiple-select","params":{
                        options: {
                            options: _vm.customerCategories,
                            name: _vm.className + '[customer_categories]',
                        },
                    },"meta":_vm.attributesMeta.customer_categories},on:{"input":function($event){return _vm.inputHandler('customer_categories', $event)}}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('tariffs', 'Customer labels'))+" "),_c('a',{directives:[{name:"x-tooltip",rawName:"v-x-tooltip:helpTooltip.top",value:(_vm.CustomersLabelTooltip),expression:"CustomersLabelTooltip",arg:"helpTooltip",modifiers:{"top":true}}],staticClass:"btn-icon-sm",attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"icon-ic_fluent_info_24_regular"})])]),_c('div',{staticClass:"col-md-8"},[_c('x-complex-input',{attrs:{"value":_vm.value.customerModuleLabels,"type":"multipleTags","params":{
                        options: {
                            id: 'admin_tariffs_test_',
                            options: _vm.customerLabels.options,
                            name: _vm.className + '[customerModuleLabels]',
                            tagsForRender: _vm.customerLabels.render,
                            placeholder: _vm.$t('customers', 'Start typing label name'),
                        },
                    },"meta":_vm.attributesMeta.customerModuleLabels},on:{"input":function($event){return _vm.inputHandler('customerModuleLabels', $event)}}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }