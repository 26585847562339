import { empty } from '@/utils/functions';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            errorMessages: [],
        };
    },
    methods: {
        getWarningType(type) {
            switch (type) {
                case 'parent':
                    return 'parent_task_id';
                case 'subtask':
                    return 'related_subtask';
                case 'related':
                    return 'related_tasks';
                case 'ticket':
                    return 'linked_ticket';
                default: {
                    return false;
                }
            }
        },
        getWarningMessage(attr) {
            this.errorMessages[attr] = [];
            const dataStore = this.isSidebar ? this.warningsSidebarTask : this.warningsTask;
            const warnings = dataStore[attr];

            if (this.warningCheckAsList(warnings)) {
                for (const key in warnings) {
                    if (Object.prototype.hasOwnProperty.call(warnings, key) && warnings[key].partner) {
                        this.errorMessages[attr].push(this.$t('scheduling', 'partner'));
                        break;
                    }
                }
            } else {
                if (warnings.partner) {
                    this.errorMessages[attr].push(this.$t('scheduling', 'partner'));
                }
                if (warnings.project) {
                    this.errorMessages[attr].push(this.$t('scheduling', 'parent project'));
                }
            }

            return this.$t('scheduling', 'Incorrect: {errors}!', {
                errors: this.errorMessages[attr].join(this.$t('scheduling', ' and ')),
            });
        },
        showWarningIncorrectValues(attr, item) {
            const dataStore = this.isSidebar ? this.warningsSidebarTask : this.warningsTask;
            const warnings = dataStore[attr];

            if (this.warningCheckAsList(warnings)) {
                const itemId = item.id.toString();

                if (warnings[itemId] && warnings[itemId].partner) {
                    return true;
                }
            } else {
                return !empty(dataStore) && (warnings?.partner || warnings?.project);
            }

            return false;
        },

        warningCheckAsList(warnings) {
            return typeof warnings === 'object' && !Object.prototype.hasOwnProperty.call(warnings, 'project') && !Object.prototype.hasOwnProperty.call(warnings, 'partner');
        },
    },
    computed: {
        ...mapGetters('task_view_warnings', [
            'warningsTask',
            'warningsSidebarTask',
        ]),
    },
};
