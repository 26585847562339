import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: '',
        name: 'admin.customers.cards.list',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'all',
        name: 'admin.customers.cards.list.all',
        component: XTwigPage,
        meta: {
            title: ['customers', 'All vouchers'],
        },
    },
    {
        delimiter: '--',
        path: 'online',
        name: 'admin.customers.cards.list.online',
        component: XTwigPage,
        meta: {
            title: ['customers', 'Online vouchers'],
        },
    },
];
