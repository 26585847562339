<template>
    <div class="message-divider">
        <div
            v-if="date"
            class="divider-date"
        >
            {{ date }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChatDivider',
    props: {
        date: {
            type: String,
            default: '',
        },
    },
};
</script>
