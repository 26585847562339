import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: '',
        name: 'admin.networking.routers.view',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'sectors',
        name: 'admin.networking.routers.view.sectors',
        component: XTwigPage,
        meta: {
            title: ['networking', 'Сonnection rules'],
        },
    },
    {
        delimiter: '--',
        path: 'mikrotik',
        name: 'admin.networking.routers.view.mikrotik',
        component: XTwigPage,
        meta: {
            title: ['networking', 'MikroTik'],
        },
    },
    {
        delimiter: '--',
        path: 'mikrotik-log',
        name: 'admin.networking.routers.view.mikrotik_log',
        component: XTwigPage,
        meta: {
            title: ['networking', 'MikroTik log'],
        },
    },
    {
        delimiter: '--',
        path: 'map',
        name: 'admin.networking.routers.view.map',
        component: XTwigPage,
        meta: {
            title: ['networking', 'Map'],
        },
    },
];
