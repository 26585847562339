import { XTwigPage } from '@/components/base/XTwigPage';
import ViewRoutes from '@/apps/admin/router/routes/admin/networking/monitoring/view';

const HardwareList = () => import(/* webpackChunkName: "hardware" */ '@/apps/admin/views/admin/networking/hardware/HardwareList');
const HardwareBackupsList = () => import(/* webpackChunkName: "hardware" */ '@/apps/admin/views/admin/networking/hardware/backups/HardwareBackupsList');

export default [
    {
        path: 'add',
        name: 'admin.networking.monitoring.add',
        component: XTwigPage,
    },
    {
        path: 'list',
        name: 'admin.networking.monitoring.list',
        component: HardwareList,
        meta: {
            keepAlive: true,
            componentName: 'HardwareList',
        },
    },
    {
        path: 'view',
        children: ViewRoutes,
    },
    {
        path: 'graphs-manage',
        name: 'admin.networking.monitoring.graphs_manage',
        component: XTwigPage,
        meta: {
            title: ['networking', 'Graphs'],
        },
    },
    {
        path: 'backups',
        name: 'admin.networking.monitoring.backups',
        component: HardwareBackupsList,
        meta: {
            keepAlive: true,
            componentName: 'HardwareBackupsList',
        },
    },
];
