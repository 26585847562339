import XTwigPage from '@/components/base/XTwigPage';

const SchedulingTasksList = () => import(/* webpackChunkName: "scheduling_tasks" */ '@/apps/admin/views/admin/scheduling/tasks/SchedulingTasksList');
const TaskView = () => import('@/apps/admin/views/admin/scheduling/view/TaskView.vue');

export default [
    {
        path: '',
        name: 'admin.scheduling.tasks',
        component: SchedulingTasksList,
        meta: {
            componentName: 'SchedulingTasksList',
            breadcrumbs: [
                { title: ['scheduling', 'Scheduling'], url: '/admin/scheduling/dashboard' },
                { title: ['scheduling', 'Tasks'], url: '/admin/scheduling/tasks' },
            ],
            title: ['scheduling', 'Tasks'],
        },
    },
    {
        delimiter: '--', // Use -- instead of / as path delimiter. Custom prop. Not from vue-router.
        path: 'add',
        name: 'admin.scheduling.tasks.add',
        component: XTwigPage,
    },
    {
        path: 'view',
        name: 'admin.scheduling.tasks.view',
        component: TaskView,
        meta: {
            componentName: 'TaskView',
            breadcrumbs: [
                { title: ['scheduling', 'Scheduling'], url: '/admin/scheduling/dashboard' },
                { title: ['scheduling', 'Tasks'], url: '/admin/scheduling/tasks' },
            ],
            title: ['scheduling', 'Task view'],
            queryKeyRefresh: true,
        },
    },
];
