<script>
import { XTwigPage } from '@/components/base/XTwigPage';

export default {
    name: 'ModulesList',
    extends: XTwigPage,

    activated() {
        $('#admin_config_modules_list_table').DataTable().ajax.reload();
    },
};
</script>
