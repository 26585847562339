<template>
    <div class="entity-offers">
        <a
            v-for="searchEntity, index in filteredSearchEntity"
            :id="'entity_' + index"
            :key="'entity' + searchEntity"
            :class="{'entity-offer-item': true, 'active': position.arrayPosition == index}"
            @click.prevent="choiceEntity(searchEntity)"
            v-text="searchEntity"
        />
    </div>
</template>

<script>
export default {
    name: 'XGlobalSearchOffers',
    delimiters: ['${', '}'],
    props: {
        filteredSearchEntity: Array,
        choiceEntity: Function,
    },
    data() {
        return {
            position: {
                x: 0,
                y: 0,
                arrayPosition: -1,
            },
            map: [],
        };
    },
    watch: {
        filteredSearchEntity: {
            immediate: true,
            handler(entitiesList) {
                Vue.nextTick(() => {
                    this.calculateMap(entitiesList);
                });
            },
        },
    },
    mounted() {
        window.addEventListener('keydown', this.processKeyboard);
    },
    destroyed() {
        window.removeEventListener('keydown', this.processKeyboard);
    },
    methods: {
        processKeyboard(event) {
            const allowedButtons = {
                13: 13, 37: 37, 38: 38, 39: 39, 40: 40,
            };
            if (allowedButtons[event.keyCode] === undefined) {
                return;
            }

            if (event.keyCode === 13) {
                this.choiceEntity(this.filteredSearchEntity[this.position.arrayPosition]);
                return;
            }

            const getRightPosition = () => this.map[this.position.y].length - 1;

            const startUsingKeyboardWhile = (customFunction) => {
                if (this.position.arrayPosition !== -1) {
                    return;
                }
                this.$set(this.position, 'arrayPosition', 0);
                if (customFunction !== undefined) {
                    customFunction();
                }
            };

            // left button
            if (event.keyCode === 37) {
                if (this.position.arrayPosition === -1) {
                    startUsingKeyboardWhile();
                }

                if (this.position.x > 0) {
                    this.position.x--;
                } else {
                    // move to previous line
                    this.position.y--;
                    // if it is first line and first element need move to end of list
                    if (this.position.y < 0) {
                        this.position.y = this.map.length - 1;
                    }
                    this.position.x = getRightPosition();
                }
            }

            // right button
            if (event.keyCode === 39) {
                startUsingKeyboardWhile(() => {
                    this.position.x = -1;
                });

                if (this.position.x < this.map[this.position.y].length - 1) {
                    this.position.x++;
                } else {
                    this.position.x = 0;
                    // move to next line
                    this.position.y++;
                    // if it is last line and last element need move to start of list
                    if (this.position.y > this.map.length - 1) {
                        this.position.y = 0;
                    }
                }
            }

            let fixXWhileMoveByY = () => {
                if (this.map[this.position.y][this.position.x] === undefined) {
                    this.position.x = getRightPosition();
                }
            };

            // up button
            if (event.keyCode === 38) {
                startUsingKeyboardWhile(() => {
                    this.position.y = this.map.length;
                });

                if (this.position.y > 0) {
                    this.position.y--;
                } else {
                    this.position.y = 0;
                }

                fixXWhileMoveByY();
            }

            // down button
            if (event.keyCode === 40) {
                startUsingKeyboardWhile(() => {
                    this.position.y = -1;
                });

                if (this.position.y < this.map.length - 1) {
                    this.position.y++;
                } else {
                    this.position.y = this.map.length - 1;
                }
                fixXWhileMoveByY();
            }

            this.$set(this.position, 'arrayPosition', this.map[this.position.y][this.position.x]);
        },
        calculateMap(entitiesList) {
            let map = [];
            let oldValue = -1;
            let iteration = -1;
            for (let itemKey in entitiesList) {
                if (!Object.prototype.hasOwnProperty.call(entitiesList, itemKey)) {
                    continue;
                }

                let element = document.getElementById(`entity_${itemKey}`);
                if (oldValue < element.offsetTop) {
                    map.push([]);
                    oldValue = element.offsetTop;
                    iteration++;
                }
                map[iteration].push(itemKey);
            }
            this.map = map;
            this.position = {
                x: 0,
                y: 0,
                arrayPosition: -1,
            };
        },
    },
};
</script>
