import { SAVE_ADVANCED_OPENED, SAVE_BEFORE_URL_STATE, SAVE_FILTER_STATE } from '@/components/common/XFilter/store/mutation-types';

export default {
    saveAdvancedOpenedState({ commit }, {
        filterName,
        newState,
    }) {
        commit(SAVE_ADVANCED_OPENED, {
            filterName,
            newState,
        });
    },
    saveFilterState({ commit }, {
        filterName,
        newState,
    }) {
        commit(SAVE_FILTER_STATE, {
            filterName, newState,
        });
    },
    saveBeforeUrlState({ commit }, {
        filterName,
        newState,
    }) {
        commit(SAVE_BEFORE_URL_STATE, {
            filterName, newState,
        });
    },
};
