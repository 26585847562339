import XTwigPage from '@/components/base/XTwigPage';
import LeadsDashboard from '@/apps/admin/views/admin/crm/leads/LeadsDashboard';
import QuotesList from '@/apps/admin/views/admin/crm/leads/quotes/QuotesList';
import LeadsRoutes from './crm/leads';
import CustomersRoutes from './crm/customers';

export default [
    {
        path: 'dashboard',
        name: 'admin.crm.dashboard',
        component: LeadsDashboard,
        meta: {
            keepAlive: true,
            componentName: 'LeadsDashboard',
        },
    },
    {
        path: 'leads',
        children: LeadsRoutes,
    },
    {
        path: 'quotes',
        name: 'admin.crm.quotes',
        component: QuotesList,
        meta: {
            keepAlive: true,
            componentName: 'QuotesList',
        },
    },
    {
        path: 'map',
        name: 'admin.crm.map',
        component: XTwigPage,
    },
    {
        path: 'customers',
        children: CustomersRoutes,
    },
];
