import { empty } from '@/utils/functions';

export default {
    data() {
        return {
            fieldsForUpdate: ['update_service_price', 'force_update_service_price', 'update_service_name', 'force_update_service_name'],
        };
    },
    computed: {
        showCustomPeriod() {
            if (this.model?.billing_types) {
                return this.model.billing_types.includes('prepaid_monthly');
            }
            return false;
        },
    },
    methods: {
        setUpdatingFalseValue() {
            if (this.type === 'edit') {
                this.fieldsForUpdate.forEach((item) => {
                    this.model[item] = false;
                    if (!empty(this.attributesMeta[item])) {
                        this.attributesMeta[item].default = false;
                    }
                });
            }
        },
        sortTransactionCategories(options) {
            const index = options.findIndex((obj) => obj.id === '-1');

            if (index !== -1) {
                const indexObject = options.splice(index, 1)[0];
                options.splice(1, 0, indexObject);
            }

            return options;
        },
        updateTransactionCategoryId(updateModel = true, loadTariffs = true) {
            this.loadEditPageInfo({ id: this.$route.query.id, loadTariffs }).finally(() => {
                if (updateModel) {
                    this.model.transaction_category_id = this.storeModel.transaction_category_id;
                }

                setTimeout(() => {
                    this.$refs.form.resetDataFormChanged();
                }, 0);
            });
        },
    },
};
