import Vue from 'vue';
import VueRouter from 'vue-router';
import Locale from '@/locale';
import Emitter from '@/utils/emmiter';
import Vuex, { mapMutations } from 'vuex';
import { REFRESH_ROUTE } from '@/apps/admin/store/mutation-types';
import VModal from 'vue-js-modal';
import XModalWrapperPlugin from '@/plugins/XModalWrapper';
import XErrorPlugin from '@/plugins/XErrorPlugin';
import XNotificationsPlugin from '@/plugins/XNotificationsPlugin';
import XChatBoxPlugin from '@/plugins/XChatBoxPlugin';
import store from './store';
import router from './router';
import App from './App.vue';

Vue.use(XModalWrapperPlugin);
Vue.use(XChatBoxPlugin);
Vue.use(VueRouter);
Vue.use(VModal, { componentName: 'VModal', dialog: false });
Vue.use(Vuex);
Vue.use(XErrorPlugin);
Vue.use(XNotificationsPlugin);

window.splynx_event_bus = new Emitter();

if (typeof window.spl_config === 'undefined') {
    window.spl_config = {
        language: 'en',
        spl_user_id: null,
        menuStructure: [],
        search_entities: null,
        header: {
            logo: '',
        },
        admin: null,
        adminOnPage: null,
    };
}

let locale = new Locale(window.spl_config.language, window.spl_config.listTranslateCategories);

require('./bootstrap');

// Set moment locale
moment.locale(window.spl_config.language);

window.xApp = new Vue({
    router,
    store: new Vuex.Store(store),
    splang: locale.splang,
    render: (h) => h(App),
    beforeCreate() {
        this.initAjaxInterceptors();
    },
    methods: {
        ...mapMutations([REFRESH_ROUTE]),
        /**
         * Call this method for refresh current route view (not all page, only route view)
         * For example call this after "show/hide column" modal
         */
        refreshRoute() {
            this.REFRESH_ROUTE();
        },
    },
});
xApp.$mount('#app');

Vue.config.devtools = process.env.NODE_ENV === 'development';
