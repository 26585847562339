// eslint-disable-next-line
var value_of_kb = 1000;
// eslint-disable-next-line
var time_format = '24H';

window.minToHm = function (value, formatted) {
    let minutes = value % 60;
    let hours = Math.floor(value / 60);

    minutes = (minutes < 10 ? '0' : '') + minutes;
    hours = (hours < 10 ? '0' : '') + hours;
    let ampmSwitch;

    if (formatted && time_format == '12H') {
        ampmSwitch = (hours > 12) ? ' PM' : ' AM';

        if (hours == 24) {
            ampmSwitch = ' AM';
        }

        if (hours == 12) {
            ampmSwitch = ' PM';
        }

        if (hours > 12) {
            hours -= 12;
        }

        if (hours == 0) {
            hours = 12;
        }
    } else {
        ampmSwitch = '';
    }
    return `${hours}:${minutes}${ampmSwitch}`;
};

window.HmToMin = function (value) {
    value = value.split(':');
    return (parseInt(value[0], 10) * 60) + parseInt(value[1], 10);
};

function formatBytes(bytes, decimals) {
    if (bytes == 0) return '0 Byte';
    let k = value_of_kb; // or 1024 for binary
    let dm = decimals || 3;
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

window.showInNM = function (value) {
    return formatBytes(value * value_of_kb, 2);
};

window.convertToBytes = function (value) {
    return Math.round(value) * value_of_kb;
};
