const AutoCdrProcessingList = () => import(/* webpackChunkName: "config_voice" */ '@/apps/admin/views/admin/config/voice/auto-cdr-processing/AutoCdrProcessingList');
const ImportDataSource = () => import(/* webpackChunkName: "config_voice" */ '@/apps/admin/views/admin/config/voice/import-data-source/ImportDataSource');

export default [
    {
        path: 'auto-cdr-processing',
        name: 'admin.config.voice.auto_cdr_processing',
        component: AutoCdrProcessingList,
        meta: {
            keepAlive: true,
            componentName: 'AutoCdrProcessingList',
        },
    },
    {
        path: 'import-data-source',
        name: 'admin.config.voice.import-data-source',
        component: ImportDataSource,
        meta: {
            keepAlive: true,
            componentName: 'ImportDataSource',
        },
    },
];
