<template>
    <div class="main">
        <div class="container center-content flex-column">
            <div class="row welcome-holder">
                <div class="col-md-12">
                    <h2 class="text-center">
                        Splynx
                    </h2>
                    <div class="card mt-24">
                        <div class="card-header ps-24 pe-24 pb-0">
                            <h4>License has expired!</h4>
                        </div>
                        <div class="card-body p-24">
                            <form
                                id="splynx_buy_form"
                                ref="splynx_buy_form"
                                target="_blank"
                                :action="billingUrl"
                                method="POST"
                            >
                                <input
                                    v-if="showLicenseInput"
                                    type="hidden"
                                    name="license"
                                    :value="licenseKey"
                                >
                                <p
                                    v-if="showByForm"
                                    class="color-dark"
                                >
                                    You can
                                    <button
                                        id="splynx_buy_form_button"
                                        type="button"
                                        class="btn btn-success"
                                        @click="activateLicense"
                                    >
                                        Activate
                                    </button>
                                    a subscription.
                                </p>
                                <p
                                    v-else
                                    class="color-dark"
                                >
                                    The license for this instance has expired - please contact a Super Administrator for more information.
                                    Should the license be renewed - please click the reload button below.
                                </p>
                                <p class="color-dark mt-24">
                                    If you have active subscription please
                                    <button
                                        id="splynx_license_reload_button"
                                        type="button"
                                        class="btn btn-info"
                                        @click="reloadLicense"
                                    >
                                        Reload
                                    </button>
                                    license information.
                                </p>
                            </form>

                            <div class="row mt-24">
                                <div class="col-md-12">
                                    <p class="color-dark">
                                        Contact if needed:<br>
                                        <a
                                            class="btn btn-link"
                                            href="mailto:info@splynx.com"
                                        >info@splynx.com</a><br>
                                        <a
                                            class="btn btn-link"
                                            href="https://splynx.com/"
                                        >https://splynx.com/</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import LicenseChecker from '@/apps/admin/services/license';

export default {
    name: 'AdminLicense',
    components: {},
    data() {
        let licenseChecker = new LicenseChecker();
        return {
            ...licenseChecker.getLicenseInfo(),
            licenseChecker,
        };
    },
    computed: {
        showLicenseInput() {
            return this.licenseKey ?? false;
        },
    },
    beforeCreate() {
    },
    mounted() {
    },
    methods: {
        reloadLicense() {
            $.ajax({
                type: 'GET',
                url: '/admin/tools--update-license',
                dataType: 'json',
                success: (data) => {
                    if (data.result) {
                        window.show_success('Success! Page will be reloaded in 2 seconds!', 2000);
                        setTimeout(() => {
                            LicenseChecker.reloadLicenseRoute();
                        }, 2000);
                    } else {
                        window.show_error('Error when trying reload license!', 3000);
                    }
                },
            });
        },
        activateLicense() {
            $.get('/admin/tools--check-license');
            this.$refs.splynx_buy_form.submit();
        },
    },
};
</script>
