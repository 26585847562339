class UserError extends Error {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
    }
}
export { UserError };
export default {
    UserError,
};
