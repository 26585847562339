import {
    CLEAR_RECURRING_INVOICE_MODAL_INFO,
    SET_RECURRING_INVOICE_MODAL_INFO,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';
import { isset, toCamelCase } from '@/utils/functions';

const state = {
    defaultBillingType: '0',
    settingsBillingType: '0',
    dateForStartNextCharge: '',
    fallbackDateForStartNextCharge: '',
    model: {},
    needShowPlansSelect: false,
    taxName: 'VAT',
    taxesArray: {},
    billingTypes: {},
    attributesMeta: {},
    toBillAttributesMeta: {},
    afAttributesMeta: {},
    lastProcess: {},
    modelItems: [],
    taxTotal: 0,
    billingDue: 0,
    totalWithoutTax: 0,
    totalWithTax: 0,
    perTax: [],
    balanceAfter: '',
    totalWithTaxUnformatted: 0,
    readOnly: false,
    vatPercent: 0,
    taxId: 0,
    lastCancelledCharge: [],
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadModalInfo({ commit }, customer_id) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing/overview--modal-info-recurring-invoice?customer_id=${customer_id}`,
                method: 'GET',
                success: (response) => {
                    commit(SET_RECURRING_INVOICE_MODAL_INFO, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    getNewNumber(obj, { customer_id, date_created }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing/invoices--number?customer_id=${customer_id}&date_created=${date_created}`,
                method: 'GET',
                success: (response) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_RECURRING_INVOICE_MODAL_INFO](state, info) {
        for (let key in info) {
            if (key === 'attributesMeta') {
                state[key] = info[key].attributes;
                if (isset(info[key], ['toBill'])) {
                    state.toBillAttributesMeta = info[key].toBill.attributes;
                }
                state.afAttributesMeta = info[key].additional_attributes;
                continue;
            }
            state[toCamelCase(key)] = info[key];
        }
    },
    [CLEAR_RECURRING_INVOICE_MODAL_INFO](state) {
        state.model = {};
        state.modelItems = [];
        state.taxTotal = 0;
        state.totalWithoutTax = 0;
        state.totalWithTax = 0;
        state.perTax = [];
        state.balanceAfter = '';
        state.totalWithTaxUnformatted = 0;
        state.billingDue = 0;
        state.dateForStartNextCharge = '';
        state.fallbackDateForStartNextCharge = '';
        state.lastProcess = {};
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
