<template>
    <div class="dropdown chat-message-actions">
        <span
            class="icon-wrap cursor-pointer text-primary"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
            <i class="icon-ic_fluent_chevron_down_24_regular" />
        </span>
        <ul class="dropdown-menu">
            <li
                v-for="action of actions"
                :key="action.name"
                class="dropdown-item"
            >
                <span
                    class="btn-link"
                    @click="performAction(action.name, action.params)"
                >{{ action.title }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ChatMessageActions',
    props: {
        actions: {
            type: Array,
            default: () => ([]),
        },
    },
    methods: {
        performAction(name, data) {
            this.$emit('perform', { name, data });
        },
    },
};
</script>
