import XTwigPage from '@/components/base/XTwigPage';

const Ipv4NetworksList = () => import(/* webpackChunkName: "ipv4" */ '@/apps/admin/views/admin/networking/ipv4/Ipv4NetworksList');

export default [
    {
        path: 'add',
        name: 'admin.networking.ipv4.add',
        component: XTwigPage,
    },
    {
        path: 'list',
        name: 'admin.networking.ipv4.list',
        component: Ipv4NetworksList,
        meta: {
            keepAlive: true,
            componentName: 'Ipv4NetworksList',
        },
    },
    {
        path: 'view',
        name: 'admin.networking.ipv4.view',
        component: XTwigPage,
    },
];
