import {
    STATISTICS_RESET_SESSION_RECORD,
    STATISTICS_SET_SESSION_RECORD,
    STATISTICS_SET_SESSION_RECORD_ATTRIBUTES_META,
} from '@/apps/admin/store/mutation-types';
import { emptyObject, promisifiedAjax } from '@/utils/functions';

const state = {
    statisticRecord: null,
    statisticRecordAttributesMeta: null,
};

const getters = {
    recordForPrinting(state) {
        return state.statisticRecord;
    },
    recordForPrintingAttributesMeta(state) {
        return state.statisticRecordAttributesMeta;
    },
};

const mutations = {
    [STATISTICS_RESET_SESSION_RECORD](state) {
        state.statisticRecord = null;
    },
    [STATISTICS_SET_SESSION_RECORD](state, data) {
        state.statisticRecord = data;
    },
    [STATISTICS_SET_SESSION_RECORD_ATTRIBUTES_META](state, data) {
        state.statisticRecordAttributesMeta = data;
    },
};

const actions = {
    fetchDataForModal({ commit }, searchConditions) {
        return new Promise((resolve, reject) => {
            promisifiedAjax({
                url: `/admin/customers/statistics/internet-statistics--online-info?${$.param(searchConditions)}`,
            }).then((response) => {
                if (response.result && !emptyObject(response.message)) {
                    commit(STATISTICS_SET_SESSION_RECORD, response.message.model);
                    commit(STATISTICS_SET_SESSION_RECORD_ATTRIBUTES_META, response.message.attributesMeta.attributes);
                    resolve();
                    return;
                }

                reject(new Error('Invalid value in response'));
            }).catch((result) => {
                reject(result);
            });
        });
    },
};

export default {
    namespaced: true, state, getters, mutations, actions,
};
