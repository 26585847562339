import { stateToGetters } from '@/apps/admin/store/helpers';
import { SET_TASK_VIEW_CHECKLIST } from '@/apps/admin/store/mutation-types';

const state = {
    checklistStore: [],
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    updateChecklist({ commit }, list) {
        commit(SET_TASK_VIEW_CHECKLIST, list);
    },
};

const mutations = {
    [SET_TASK_VIEW_CHECKLIST](state, list) {
        state.checklistStore = [...list];
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
