<template>
    <div class="d-flex linked-ticket-url">
        <router-link
            :to="url"
            class="btn-icon-text linked-ticket-link"
        >
            <i class="icon-ic_fluent_ticket_diagonal_16_regular" />
            <span>#{{ id }} {{ subject }}</span>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'MessageLinkedTicket',
    props: {
        id: [String, Number],
        url: String,
        subject: String,
        messageType: String,
    },
};
</script>
