import XTwigPage from '@/components/base/XTwigPage';
import OneTimePlansAddEdit from '@/apps/admin/views/admin/tariffs/one-time/OneTimePlansAddEdit.vue';

const OneTimePlansList = () => import(/* webpackChunkName: "one_time_plans" */ '@/apps/admin/views/admin/tariffs/OneTimePlansList');

export default [
    {
        path: '',
        name: 'admin.tariffs.one_time',
        component: OneTimePlansList,
        meta: {
            breadcrumbs: [
                { title: ['common', 'Tariffs plans'], url: '/admin/tariffs/one-time' },
                { title: ['common', 'One-Time'], url: '/admin/tariffs/one-time' },
            ],
            title: ['tariffs', 'Tariffs'],
            keepAlive: true,
            componentName: 'OneTimePlansList',
        },
    },
    {
        delimiter: '--', // Use -- instead of / as path delimiter. Custom prop. Not from vue-router.
        path: 'add',
        name: 'admin.tariffs.one_time.add',
        component: OneTimePlansAddEdit,
        meta: {
            edit: false,
            breadcrumbs: [
                { title: ['common', 'Tariffs plans'], url: '/admin/tariffs/one-time' },
                { title: ['common', 'One-Time'], url: '/admin/tariffs/one-time' },
                { title: ['common', 'Add'], url: '/admin/tariffs/one-time--add' },
            ],
            title: ['tariffs', 'Tariffs'],
        },
    },
    {
        delimiter: '--',
        path: 'edit',
        name: 'admin.tariffs.one_time.edit',
        component: OneTimePlansAddEdit,
        meta: {
            edit: true,
            breadcrumbs: [
                { title: ['common', 'Tariffs plans'], url: '/admin/tariffs/one-time' },
                { title: ['common', 'One-Time'], url: '/admin/tariffs/one-time' },
            ],
            title: ['tariffs', 'Tariffs'],
        },
    },
    {
        delimiter: '--',
        path: 'table',
        name: 'admin.tariffs.one_time.table',
        component: XTwigPage,
        meta: {
            title: ['tariffs', 'Tariff plan table'],
        },
    },
];
