export default class Ticket {
    static isValidTicket({ id, url, subject }) {
        return id && url && subject;
    }

    constructor({
        id, url, subject, created_at, source, reporter,
    } = {}) {
        this.id = id ?? null;
        this.url = url ?? null;
        this.subject = subject ?? null;
        this.created_at = created_at;
        this.source = source;
        this.reporter = reporter;
    }
}
