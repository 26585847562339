import { promisifiedAjax } from '@/utils/functions';

export default class GeoData {
    constructor(url, data) {
        this.url = url;
        this.data = data;
    }

    async getGeoData() {
        return promisifiedAjax({
            // TODO backend needed
            url: this.url,
            method: 'POST',
            data: this.data,
        });
    }

    async getMapConfig() {
        return promisifiedAjax({
            url: this.url,
        });
    }
}
