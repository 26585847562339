import { getUrlParam } from '@/utils/functions';
import { ACS_PROVISIONING_SET_AVAILABLE_DEVICES_FOR_REFRESH } from '@/apps/admin/store/mutation-types';

const state = {
    allGroupDevices: {},
};
const getters = {
    availableDevices(state) {
        return state.allGroupDevices;
    },
    availableDevicesIsEmpty(state) {
        return Object.keys(state.allGroupDevices).length <= 0;
    },
};
const actions = {
    loadGroupDevices({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/networking/acs--load-provision-wizard-info?groupId=${getUrlParam('id')}`,
                success(response) {
                    if (response.result) {
                        commit(ACS_PROVISIONING_SET_AVAILABLE_DEVICES_FOR_REFRESH, response.groupDevices);
                        resolve();
                    } else {
                        commit(ACS_PROVISIONING_SET_AVAILABLE_DEVICES_FOR_REFRESH, {});
                        reject();
                    }
                },
                failed() {
                    reject();
                },
            });
        });
    },
};
const mutations = {
    [ACS_PROVISIONING_SET_AVAILABLE_DEVICES_FOR_REFRESH](state, data) {
        state.allGroupDevices = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
