import Ticket from '@/apps/admin/services/tickets/Ticket';
import MessageLocation from '@/apps/admin/services/messengers/MessageLocation';
import { deepCopyObj } from '@/utils/functions';
import Message from './Message';
import Conversation from './Conversation';
import MessageAuthor from './MessageAuthor';

export default class MessagesToConversations {
    order = [];

    conversations = [];

    messages = [];

    _statusesMap = {};

    constructor(messages, conversations, order, statusesMap = {}) {
        this._statusesMap = deepCopyObj(statusesMap);
        this.messages = this.constructor._format(deepCopyObj(messages));
        this.conversations = this.constructor._format(deepCopyObj(conversations));
        this.order = this.constructor._format(deepCopyObj(order));

        this.messages = this.messages.map((data) => {
            data.ticket = new Ticket(data.ticket);
            data.location = new MessageLocation(data.location);
            return data;
        });

        /* todo: Get Author structure from BE */
        this.messages = this.messages.map((data) => {
            if (data.author instanceof MessageAuthor) {
                return new Message(data).withMappedStatus(this._statusesMap);
            }
            // Temp logic
            const id = data.type === 'in' ? window.xApp.$route.query.id : window.spl_config.admin.id;
            const role = data.type === 'in' ? MessageAuthor.ROLE_CUSTOMER : MessageAuthor.ROLE_ADMIN;
            data.author = new MessageAuthor({
                id,
                role,
                name: data.author,
            });
            return new Message(data).withMappedStatus(this._statusesMap);
        });
        this.conversations = this.conversations.map((data) => new Conversation(data));
    }

    static _format(data) {
        if (data instanceof Array) {
            return data ?? [];
        }
        if (typeof data === 'object') {
            return Object.values(data) ?? [];
        }
        return data;
    }

    process() {
        const messageOrderMap = {};
        this.order.forEach((messageId, index) => {
            messageOrderMap[messageId] = index;
        });
        this.messages.sort((first, second) => {
            const orderFirst = messageOrderMap[first.id];
            const orderSecond = messageOrderMap[second.id];
            return orderSecond - orderFirst;
        });
        this.conversations.forEach((conversation) => {
            const conversationMessages = this.messages.filter((message) => message.conversationId === conversation.id);
            conversationMessages.sort((first, second) => {
                const orderFirst = messageOrderMap[first.id];
                const orderSecond = messageOrderMap[second.id];
                return orderSecond - orderFirst;
            });
            conversation.messages = conversationMessages.filter(({ id }) => this.order.includes(id));
        });
        this.conversations.sort((a, b) => {
            const earliestMessageFirst = a.messages[0] ?? {};
            const earliestMessageSecond = b.messages[0] ?? {};
            const orderFirst = messageOrderMap[earliestMessageFirst.id];
            const orderSecond = messageOrderMap[earliestMessageSecond.id];
            return orderSecond - orderFirst;
        });
        return this;
    }

    get() {
        return this.conversations;
    }
}
