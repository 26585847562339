<template>
    <div
        class="chat-box-wrapper"
    >
        <chat-box-button
            v-show="active"
            ref="button"
            :counter="commonUnread"
            @click.native="$openChatBox()"
        >
            <template #counter="{ value }">
                <transition name="fade">
                    <div
                        v-if="commonUnread > 0"
                        class="chat-box-counter"
                    >
                        {{ value }}
                    </div>
                </transition>
            </template>
        </chat-box-button>

        <chat-box />
    </div>
</template>

<script>
import ChatBoxButton from '@/components/common/XMessengersChatbox/ChatBoxButton';
import ChatBox from '@/components/common/XMessengersChatbox/ChatBox';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'XMessengersChatBoxWrapper',
    props: {
        active: {
            type: Boolean,
            default: true,
        },
    },
    mounted() {
        this.initChatBoxUpdating();
    },
    methods: {
        ...mapActions('chatbox', [
            'initChatBoxUpdating',
        ]),
        playNotifyAnimation() {
            const element = this.$refs.button.$el;
            element.classList.add('bounce');
            setTimeout(() => {
                element.classList.remove('bounce');
            }, 1000);
        },
    },
    computed: {
        ...mapGetters('chatbox', [
            'notifyTrigger',
            'commonUnread',
        ]),
    },
    watch: {
        notifyTrigger(newValue) {
            if (newValue) {
                this.playNotifyAnimation();
            }
        },
    },
    components: { ChatBox, ChatBoxButton },
};
</script>
