export default {
    data() {
        return {
            curlyBrackets: /({{)(( )*([a-z])*(\d)*(\.)*(\[)*(')*(\])*(_)*)+(}})/,
        };
    },
    methods: {
        doesHasCurlyBrackets(val) {
            return val && this.curlyBrackets.test(val);
        },
    },
};
