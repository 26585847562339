import { DOUBLE_LOADING_TYPE } from './refresh_device_constants';

async function baseDeviceProcess(refreshDeviceObject) {
    return new Promise((resolve, reject) => {
        refreshDeviceObject.setProvisionConfigLoadingType(DOUBLE_LOADING_TYPE)
            .refresh()
            .then((response) => {
                show_success(response.message, 4);
                return resolve(response);
            })
            .catch((result) => {
                if (result.message !== undefined) {
                    show_error(result.message, 4);
                }
                return reject(result);
            });
    });
}

export {
    baseDeviceProcess,
};

export default baseDeviceProcess;
