<template>
    <div
        class="help-wrapper"
    >
        <x-translate
            tag="false"
            category="tariffs"
            message="If a customer's location, category, and/or label is different from the tariff's values, the tariff will not be available to add as a service."
        />
    </div>
</template>

<script>

export default {
    name: 'HideOnAdminPortalTooltip',
};
</script>

<style>
.help-wrapper {
    display: inline-block;
}
</style>
