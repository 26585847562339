import {
    CONFIG_SETTINGS_TYPE_PAGE,
    CONFIG_SETTINGS_SEARCH_QUERY,
    CONFIG_SETTINGS_BLOCKS,
    CONFIG_SETTINGS_FILTER_LIST,
    CONFIG_SETTINGS_QUERY_RESULT,
    CONFIG_SETTINGS_ACTIVE_FILTER_TITLES,
    CONFIG_SETTINGS_BASIC_STATE,
    CONFIG_SETTINGS_BASIC_ACTIVE_TITLES,
    CONFIG_SETTINGS_FILTER_DEFAULT_SHORTCUTS,
    CONFIG_SETTINGS_BASIC_TRANSLATE_ALL,
    CONFIG_SETTINGS_BASIC_TRANSLATE_BASIC,
} from '@/apps/admin/store/mutation-types';

const state = {
    type: '',
    configBlocks: [],
    configSettingsFiltersList: [],
    configSearchQuery: '',
    results: [],
    sendRequest: true,
    allHidden: false,
    basicState: 'All',
    basicTranslateAll: 'All',
    basicTranslateBasic: 'Basic',
    filterDefaultShortcuts: [],
    filterActiveTitles: [],
    basicActiveTitles: [],
};

const actions = {
    loadPageData({ state, commit, dispatch }, type) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/settings--get-page-data?type=${type}`,
                success: (response) => {
                    commit(CONFIG_SETTINGS_BLOCKS, response.blocks);
                    resolve();
                },
                error: () => {
                    reject();
                },
                complete() {
                    if (state.type === 'config') {
                        if (state.basicState === state.basicTranslateBasic) {
                            commit(CONFIG_SETTINGS_ACTIVE_FILTER_TITLES, state.basicActiveTitles);
                            dispatch('showBasicBlocks', state.basicTranslateBasic);
                        } else if (state.filterActiveTitles.length > 0) {
                            dispatch('filterRememberedActiveTitles');
                        } else {
                            dispatch('generateBlocksNames', state.basicTranslateAll);
                        }
                    }
                    resolve();
                },
            });
        });
    },
    keyUpHandler({ dispatch, commit, state }, configSearchQuery) {
        if (state.configSearchQuery === configSearchQuery) {
            return;
        }

        let keyUpTimer = null;

        if (keyUpTimer) {
            clearTimeout(keyUpTimer);
            keyUpTimer = null;
        }

        if (configSearchQuery.length >= 3) {
            keyUpTimer = setTimeout(() => {
                dispatch('makeSearchRequest', configSearchQuery);
            }, 300);
        } else {
            commit(CONFIG_SETTINGS_QUERY_RESULT, []);
        }

        commit(CONFIG_SETTINGS_SEARCH_QUERY, configSearchQuery);
    },
    makeSearchRequest({ dispatch, commit, state }, configSearchQuery) {
        if (empty(configSearchQuery) || state.type === 'admin') {
            return;
        }
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/settings--search?text=${encodeURI(configSearchQuery)}`,
                method: 'GET',
                success: (response) => {
                    commit(CONFIG_SETTINGS_QUERY_RESULT, response);
                    dispatch('filterBySearchResults', configSearchQuery);
                    resolve();
                },
                error: () => {
                    reject();
                },

            });
        });
    },
    filterBySearchResults({ dispatch, state }, configSearchQuery) {
        let { results } = state;

        configSearchQuery = configSearchQuery.toLowerCase();
        dispatch('filterByText', configSearchQuery);

        state.configBlocks.forEach((block) => {
            block.items.forEach((item) => {
                results.forEach((resultItem) => {
                    if (resultItem.module === block.anchor && resultItem.path === item.anchor) {
                        block.hidden = false;
                        item.hidden = false;
                        state.allHidden = false;
                        state.filterActiveTitles.push(block.title);
                    }
                });
            });
        });
        dispatch('generateBlocksNames');
    },
    showAll({ commit, state, dispatch }) {
        if (state.type === 'config') {
            commit(CONFIG_SETTINGS_ACTIVE_FILTER_TITLES, [state.basicTranslateAll]);
            commit(CONFIG_SETTINGS_BASIC_STATE, state.basicTranslateAll);
            dispatch('generateBlocksNames', state.basicTranslateAll);
        }
        state.allHidden = false;
        state.configBlocks.forEach((block) => {
            block.hidden = false;
            block.items.forEach((item) => {
                item.hidden = false;
            });
        });
    },
    filterByText({ dispatch, state }, configSearchQuery) {
        state.allHidden = true;
        state.configBlocks.forEach((block) => {
            if (block.title.toLowerCase().indexOf(configSearchQuery) !== -1) {
                block.hidden = false;
                state.allHidden = false;
                block.items.forEach((item) => {
                    item.hidden = false;
                });
                return;
            }

            block.hidden = true;

            block.items.forEach((item) => {
                if (item.title.toLowerCase().indexOf(configSearchQuery) !== -1) {
                    item.hidden = false;
                    block.hidden = false;
                    state.allHidden = false;
                } else {
                    item.hidden = true;
                }
            });
        });

        if (state.type === 'config') {
            dispatch('generateBlocksNames');
        }
    },
    filterByBlocks({ state, dispatch }, title) {
        state.allHidden = true;
        state.configBlocks.forEach((block) => {
            if (state.filterActiveTitles.toString().indexOf(block.title) !== -1) {
                block.hidden = false;
                state.allHidden = false;
                block.items.forEach((item) => {
                    item.hidden = false;
                });
                return;
            }

            block.hidden = true;

            block.items.forEach((item) => {
                item.hidden = true;
            });
        });
        dispatch('generateBlocksNames', title);
    },
    showBasicBlocks({ state, dispatch }) {
        state.allHidden = true;
        state.configBlocks.forEach((block) => {
            if (state.basicActiveTitles.toString().indexOf(block.title) !== -1) {
                block.hidden = true;
                state.allHidden = false;
                block.items.forEach((item) => {
                    item.hidden = !item.basic;
                    if (!item.hidden) {
                        block.hidden = false;
                    }
                });
                return;
            }

            block.hidden = true;

            block.items.forEach((item) => {
                item.hidden = true;
            });
        });
        state.filterActiveTitles = [];
        dispatch('generateBlocksNames', state.basicTranslateBasic);
    },
    filterRememberedActiveTitles({ state, dispatch }) {
        state.filterActiveTitles.forEach((title) => {
            if (title !== state.basicTranslateAll) {
                dispatch('filterByBlocks');
            }
        });
    },
    generateBlocksNames({ state, commit }, special) {
        let filterDefault = state.filterDefaultShortcuts.map((item) => {
            let active = special === item.title ? !item.hidden : false;

            return {
                title: item.title,
                button_class: item.button_class,
                active,
            };
        });

        let filters = state.configBlocks.map((item) => {
            let active = special === state.basicTranslateAll || special === state.basicTranslateBasic ? false : !item.hidden;
            return {
                title: item.title,
                button_class: item.button_class,
                active,
            };
        });

        commit(CONFIG_SETTINGS_FILTER_LIST, filterDefault.concat(filters));
    },
    searchHandler({ dispatch }, configSearchQuery) {
        if (configSearchQuery.length === 0) {
            dispatch('showAll');
        } else {
            dispatch('filterByText', configSearchQuery);
        }
    },
    mainFilterClickHandler({ dispatch, state, commit }, title) {
        if (title === state.basicTranslateAll) {
            dispatch('showAll');
            return;
        }

        if (title === state.basicTranslateBasic) {
            commit(CONFIG_SETTINGS_ACTIVE_FILTER_TITLES, state.basicActiveTitles);
            commit(CONFIG_SETTINGS_BASIC_STATE, state.basicTranslateBasic);
            dispatch('showBasicBlocks', state.basicTranslateBasic);
            return;
        }

        if (state.filterActiveTitles.toString().indexOf(title) === -1) {
            commit(CONFIG_SETTINGS_ACTIVE_FILTER_TITLES, state.filterActiveTitles.concat([title]));
            commit(CONFIG_SETTINGS_BASIC_STATE, state.basicTranslateAll);
        } else {
            let titles = state.filterActiveTitles.filter((item) => title !== item);

            /* if (titles.length <= 6 && titles.toString().indexOf(state.basicTranslateBasic) > -1) {
                titles = titles.filter(item => state.basicTranslateBasic !== item)
            } */

            if (titles.length === 0 || (titles.length === 1 && titles.toString().indexOf(state.basicTranslateAll) === 0)) {
                dispatch('showAll');
                return;
            }

            commit(CONFIG_SETTINGS_ACTIVE_FILTER_TITLES, titles);
        }

        dispatch('filterByBlocks');
    },
};

const getters = {
    configBlocks(state) {
        return state.configBlocks;
    },
    configSettingsFiltersList(state) {
        return state.configSettingsFiltersList;
    },
    configSearchQuery(state) {
        return state.configSearchQuery;
    },
    results(state) {
        return state.results;
    },
    allHidden(state) {
        return state.allHidden;
    },
    filterActiveTitles(state) {
        return state.filterActiveTitles;
    },
    basicState(state) {
        return state.basicState;
    },
    type(state) {
        return state.type;
    },
    basicActiveTitles(state) {
        return state.basicActiveTitles;
    },
    filterDefaultShortcuts(state) {
        return state.filterDefaultShortcuts;
    },
    basicTranslateAll(state) {
        return state.basicTranslateAll;
    },
    basicTranslateBasic(state) {
        return state.basicTranslateBasic;
    },
};

const mutations = {
    [CONFIG_SETTINGS_BLOCKS](state, configBlocks) {
        state.configBlocks = configBlocks;
    },
    [CONFIG_SETTINGS_FILTER_LIST](state, configSettingsFiltersList) {
        state.configSettingsFiltersList = configSettingsFiltersList;
    },
    [CONFIG_SETTINGS_SEARCH_QUERY](state, configSearchQuery) {
        state.configSearchQuery = configSearchQuery;
    },
    [CONFIG_SETTINGS_QUERY_RESULT](state, results) {
        state.results = results;
    },
    [CONFIG_SETTINGS_ACTIVE_FILTER_TITLES](state, filterActiveTitles) {
        state.filterActiveTitles = filterActiveTitles;
    },
    [CONFIG_SETTINGS_BASIC_STATE](state, basicState) {
        state.basicState = basicState;
    },
    [CONFIG_SETTINGS_TYPE_PAGE](state, type) {
        state.type = type;
    },
    [CONFIG_SETTINGS_BASIC_ACTIVE_TITLES](state, basicActiveTitles) {
        state.basicActiveTitles = basicActiveTitles;
    },
    [CONFIG_SETTINGS_FILTER_DEFAULT_SHORTCUTS](state, filterDefaultShortcuts) {
        state.filterDefaultShortcuts = filterDefaultShortcuts;
    },
    [CONFIG_SETTINGS_BASIC_TRANSLATE_ALL](state, basicTranslateAll) {
        state.basicTranslateAll = basicTranslateAll;
    },
    [CONFIG_SETTINGS_BASIC_TRANSLATE_BASIC](state, basicTranslateBasic) {
        state.basicTranslateBasic = basicTranslateBasic;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
