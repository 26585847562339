import { promisifiedAjax, withGet } from '@/utils/functions';

export default class FileUploadCheckAccessService {
    static async loadCheckAccess(params) {
        let url = '';
        if (params) {
            url = withGet('/admin/scheduling/tasks/view--get-access-file-upload-permissions', params);
        } else {
            url = '/admin/scheduling/tasks/view--get-access-file-upload-permissions';
        }
        return promisifiedAjax({
            url,
        });
    }
}
