export default {
    data() {
        return {
            templatesForRender: {},
        };
    },
    methods: {
        changeRenderForTemplates(key, value) {
            this.templatesForRender[key] = value;
        },
    },
};
