import { SET_PARTNERS } from '../mutation-types';

const state = {
    partners: {},
};

const getters = {
    partners(state) {
        return state.partners;
    },
    partnersWithDefaultOption() {
        let defaultPartner = {};
        defaultPartner['0'] = window.xApp.$splang.t('common', 'Default');

        for (let k in state.partners) {
            defaultPartner[k] = state.partners[k];
        }

        return defaultPartner;
    },
    partnersWithAnyOption() {
        let defaultPartner = {};
        defaultPartner['0'] = window.xApp.$splang.t('common', 'Any');

        for (let k in state.partners) {
            defaultPartner[k] = state.partners[k];
        }

        return defaultPartner;
    },
    partnersWithAllOption(state) {
        let allPartners = {};
        allPartners['0'] = window.xApp.$splang.t('common', 'All');

        for (let k in state.partners) {
            allPartners[k] = state.partners[k];
        }

        return allPartners;
    },
};

const actions = {
    loadPartners({ commit }) {
        return new Promise((resolve, reject) => {
            /**
             * TODO: uncomment this code when partners will use vue
             * if (Object.keys(state.partners).length) {
             *  resolve();
             *  return;
             *}
             */
            $.ajax({
                method: 'GET',
                url: '/admin/load-data--get-all-partners-list',
                success: (response) => {
                    commit(SET_PARTNERS, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
};

const mutations = {
    [SET_PARTNERS](state, partners) {
        state.partners = partners;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
