<template>
    <button
        v-if="!renderAdditionalActions"
        v-bind="mainButtonAttributes"
        v-on="mainButtonActions"
    >
        {{ mainButtonTitle }}
    </button>

    <div v-else>
        <div :class="buttonGroupClass">
            <button
                v-if="splitButtons"
                type="button"
                v-bind="mainButtonAttributes"
                v-on="mainButtonActions"
            >
                {{ mainButtonTitle }}
            </button>

            <button
                type="button"
                v-bind="dropdownOptions"
                data-bs-toggle="dropdown"
            >
                {{ dropdownButtonTitle }}
            </button>

            <ul
                class="dropdown-menu"
                data-popper-placement="bottom"
            >
                <li
                    v-for="(liButton, key) in additionalActions"
                    :key="key"
                    class="dropdown-item"
                >
                    <a
                        :class="renderAdditionalActionsClasses(liButton)"
                        v-bind="getButtonBinds(liButton)"
                        :data-test-selector="liButton.title"
                        href="javascript:void(0);"
                        v-on="liButton.events"
                    >
                        {{ liButton.title }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { empty, emptyObject, isset } from '@/utils/functions';

export default {
    name: 'XButton',
    props: {
        title: {
            type: String,
            default() {
                return window.xApp.$splang.t('config', 'Actions');
            },
        },
        button: {
            type: Object,
            default() {
                return {};
            },
        },
        additionalActions: {
            type: [Array, Object],
            default() {
                return {};
            },
        },
    },
    computed: {
        renderAdditionalActions() {
            if (typeof this.additionalActions === 'object') {
                return !emptyObject(this.additionalActions);
            }
            return !empty(this.additionalActions);
        },
        mainButtonTitle() {
            if (!emptyObject(this.button)) {
                if (this.button.title === undefined) {
                    console.error("Missing required 'title' attribute for 'button' property");
                    return;
                }
                return this.button.title;
            }

            return this.title;
        },
        mainButtonAttributes() {
            return this.button.options;
        },
        mainButtonActions() {
            return this.button.events;
        },
        dropdownOptions() {
            let options = this.button.dropdownButtonOptions;

            if (options.class !== undefined) {
                let classes = options.class;
                if (typeof classes === 'string') {
                    classes += ' dropdown-toggle dropdown-toggle-split';
                    if (this.splitButtons) {
                        classes += ' btn-icon';
                    }
                } else {
                    classes['dropdown-toggle'] = true;
                    classes['dropdown-toggle-split'] = true;
                    if (this.splitButtons) {
                        classes['btn-icon'] = true;
                    }
                }

                options.class = classes;
            } else if (this.splitButtons) {
                options.class = 'btn-icon';
            }

            return options;
        },
        buttonGroupClass() {
            let classes = this.splitButtons ? ['btn-group'] : [];

            if (isset(this.button.dropdownButtonOptions, ['position'])) {
                if (this.button.dropdownButtonOptions.position === 'top') {
                    classes.push('dropup');
                } else if (this.button.dropdownButtonOptions.position === 'down') {
                    classes.push('dropdown');
                }
            }
            return classes;
        },
        splitButtons() {
            if (isset(this.button.dropdownButtonOptions, ['splitButtons'])) {
                return this.button.dropdownButtonOptions.splitButtons;
            }

            return true;
        },
        dropdownButtonTitle() {
            if (this.splitButtons) {
                return '';
            }

            if (isset(this.button.dropdownButtonOptions, ['title'])) {
                return this.button.dropdownButtonOptions.title;
            }

            return window.xApp.$splang.t('config', 'Actions');
        },
    },
    methods: {
        renderAdditionalActionsClasses(button) {
            let classes = {};
            if (button.options && button.options.class) {
                for (let buttonClass in button.options.class) {
                    if (!Object.prototype.hasOwnProperty.call(button.options.class, buttonClass)) {
                        continue;
                    }
                    classes[buttonClass] = button.options.class[buttonClass];
                }
            }

            return classes;
        },
        getButtonBinds(button) {
            let options = { ...button.options };
            if (options && options.class !== undefined) {
                delete options.class;
            }

            return options;
        },
    },
};
</script>
