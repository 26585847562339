import { stateToGetters } from '@/apps/admin/store/helpers';
import {
    SET_CUSTOM_GEO_DATA_CHANGES,
    SET_SCHEDULING_PERMISSIONS,
    SET_TASK_VIEW_ACCESS,
    SET_TASK_VIEW_IS_LEAD,
    SET_TASK_VIEW_META,
    SET_TASK_VIEW_MODEL,
    SET_TASK_VIEW_TAB_COUNTERS,
    SET_TASK_VIEW_WORKLOG_TIMER_DATA,
} from '@/apps/admin/store/mutation-types';

const state = {
    model: {},
    attributes_meta: {
        additional_attributes: {},
        attributes: {},
    },
    permissions: {},
    is_lead: false,
    timerData: {},
    tabsCounters: {},
    checkAccessController: '',
    checkAccessAction: '',
};

const getters = {
    attributesMeta(state) {
        return state.attributes_meta;
    },
    ...stateToGetters(state),
};

const actions = {
    updateAccess({ commit }, { check_access_controller, check_access_action }) {
        commit(SET_TASK_VIEW_ACCESS, { check_access_controller, check_access_action });
    },
    updateModel({ commit }, model) {
        commit(SET_TASK_VIEW_MODEL, model);
    },
    updateIsLead({ commit }, is_lead) {
        commit(SET_TASK_VIEW_IS_LEAD, is_lead);
    },
    updateMeta({ commit }, meta) {
        commit(SET_TASK_VIEW_META, meta);
    },
    updateWorklogTimer({ commit }, timerData) {
        commit(SET_TASK_VIEW_WORKLOG_TIMER_DATA, timerData);
    },
    updateTabsCounters({ commit }, tabsCounters) {
        commit(SET_TASK_VIEW_TAB_COUNTERS, tabsCounters);
    },
    setCustomGeoDataChanges({ commit }, changed) {
        commit(SET_CUSTOM_GEO_DATA_CHANGES, changed);
    },
    updatePermissions({ commit }, permissions) {
        commit(SET_SCHEDULING_PERMISSIONS, permissions);
    },
};

const mutations = {
    [SET_TASK_VIEW_ACCESS](state, { check_access_controller, check_access_action }) {
        state.checkAccessController = check_access_controller;
        state.checkAccessAction = check_access_action;
    },
    [SET_TASK_VIEW_META](state, meta) {
        state.attributes_meta = { ...meta };
    },
    [SET_TASK_VIEW_MODEL](state, model) {
        state.model = { ...model };
    },
    [SET_TASK_VIEW_IS_LEAD](state, is_lead) {
        state.is_lead = is_lead;
    },
    [SET_SCHEDULING_PERMISSIONS](state, permissions) {
        state.permissions = permissions;
    },
    [SET_TASK_VIEW_WORKLOG_TIMER_DATA](state, timerData) {
        state.timerData = { ...state.timerData, ...timerData };
    },
    [SET_TASK_VIEW_TAB_COUNTERS](state, tabsCounters) {
        let counters = {};
        for (const counter in tabsCounters) {
            counters[counter] = {
                value: tabsCounters[counter],
                color: 'secondary',
            };
        }
        state.tabsCounters = { ...state.tabsCounters, ...counters };
    },
    [SET_CUSTOM_GEO_DATA_CHANGES](state, changed) {
        state.customGeoDataChanges = changed;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
