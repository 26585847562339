import XTwigPage from '@/components/base/XTwigPage';

const Ipv6NetworksList = () => import(/* webpackChunkName: "ipv6" */ '@/apps/admin/views/admin/networking/ipv6/Ipv6NetworksList');

export default [
    {
        path: 'add',
        name: 'admin.networking.ipv6.add',
        component: XTwigPage,
    },
    {
        path: 'list',
        name: 'admin.networking.ipv6.list',
        component: Ipv6NetworksList,
        meta: {
            keepAlive: true,
            componentName: 'Ipv6NetworksList',
        },
    },
    {
        path: 'view',
        name: 'admin.networking.ipv6.view',
        component: XTwigPage,
    },
];
