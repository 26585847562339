<template>
    <div
        :key="chatId"
        class="chat-box-item"
        @click="$emit('view', viewData)"
    >
        <div class="chat-box-item-date">
            {{ date }}
        </div>
        <div class="d-flex align-items-center">
            <div
                class="chat-box-item-icon"
            >
                <img
                    :src="`/images/messengers/chat-box/${messengerName}.svg`"
                    alt=""
                >
                <transition name="fade">
                    <small
                        v-if="counter > 0"
                        data-test-selector="counter"
                    >
                        {{ counter }}
                    </small>
                </transition>
            </div>
            <div class="chat-box-item-info">
                <h5>
                    {{ title || phone }}
                </h5>
                <p v-if="preview">
                    {{ preview }}
                </p>
                <chat-box-attachment-preview
                    data-test-selector="last-attachment"
                    :attachment="attachments[0]"
                />
            </div>
        </div>
        <div class="flex-grow mt-8 w-100">
            <label
                v-if="!assignee"
                class="badge bg-secondary"
            >{{ $t('messengers', 'Unassigned') }}</label>
        </div>
    </div>
</template>

<script>
import ChatBoxAttachmentPreview from '@/components/common/XMessengersChatbox/ChatBoxAttachmentPreview';

export default {
    name: 'ChatBoxItem',
    props: {
        messengerName: String,
        messengerId: [Number, String],
        customerId: [Number, String],
        customerLeadName: String,
        chatId: [Number, String],
        title: String,
        phone: String,
        preview: String,
        attachments: [Array, Object],
        date: String,
        assignee: [Number, String],
        counter: [Number, String],
    },
    computed: {
        viewData() {
            return {
                chatId: this.chatId,
                customerId: this.customerId,
                isUnknownSender: !this.customerId,
                messengerId: this.messengerId,
                messengerName: this.messengerName,
                title: this.title,
            };
        },
    },
    components: { ChatBoxAttachmentPreview },
};
</script>
