import XTwigPage from '@/components/base/XTwigPage';
import NetworkSitesRoutes from './networking/network-sites';
import RoutersRoutes from './networking/routers';
import AcsRoutes from './networking/acs';
import VoiceDevicesRoutes from './networking/voice-devices';
import MonitoringRoutes from './networking/monitoring';
import Ipv4Routes from './networking/ipv4';
import Ipv6Routes from './networking/ipv6';

const CpeList = () => import(/* webpackChunkName: "cpe_list" */ '@/apps/admin/views/admin/networking/cpe/CpeList');

export default [
    {
        path: 'routers',
        children: RoutersRoutes,
    },
    {
        path: 'network-sites',
        children: NetworkSitesRoutes,
    },
    {
        path: 'cpe',
        name: 'admin.networking.cpe',
        component: CpeList,
        meta: {
            keepAlive: true,
            componentName: 'CpeList',
        },
    },
    {
        path: 'acs',
        children: AcsRoutes,
    },
    {
        path: 'voice-devices',
        children: VoiceDevicesRoutes,
    },
    {
        path: 'monitoring',
        children: MonitoringRoutes,
    },
    {
        path: 'ipv4',
        children: Ipv4Routes,
    },
    {
        path: 'ipv6',
        children: Ipv6Routes,
    },
    {
        path: 'map',
        name: 'admin.networking.map',
        component: XTwigPage,
    },
];
