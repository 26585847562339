import {
    CLEAR_INVOICE_MODAL_INFO,
    SET_INVOICE_MODAL_INFO,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';
import { toCamelCase } from '@/utils/functions';

const state = {
    vatPercent: 0,
    taxId: 0,
    model: {},
    needShowPlansSelect: false,
    taxName: 'VAT',
    taxesArray: {},
    attributesMeta: {},
    afAttributesMeta: {},
    modelItems: [],
    taxTotal: 0,
    totalWithoutTax: 0,
    totalWithTax: 0,
    perTax: [],
    balanceAfter: '',
    typeRecurringInvoice: '',
    linkToCustomer: '',
    linkToCreditNotes: '',
    readOnly: false,
    relations: [],
    relationsTotal: 0,
    relationsTotalFormatted: '0',
    additionalAttributes: [],
    actionPermissions: {},
    existsUnusedTransactions: false,
    billingDue: 0,
    isAddingItemsBlocked: false,
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadModalInfo({ commit }, { customer_id, invoice_id }) {
        let data = {
            customer_id,
        };
        if (invoice_id) {
            data.invoice_id = invoice_id;
        }

        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/customers/billing/invoices--get-modal-info',
                method: 'GET',
                data,
                success: (response) => {
                    let result = {};
                    Object.keys(response).forEach((key) => {
                        result[toCamelCase(key)] = response[key];
                    });
                    commit(SET_INVOICE_MODAL_INFO, result);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    getNewNumber(obj, { customer_id, date_created }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing/invoices--number?customer_id=${customer_id}`,
                method: 'GET',
                data: {
                    date_created,
                },
                success: (response) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    loadUnchargedTransactions(obj, { customer_id }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing/invoices--get-uncharged-transactions?customer_id=${customer_id}`,
                success: (response) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_INVOICE_MODAL_INFO](state, info) {
        for (let key in info) {
            if (key === 'attributesMeta') {
                state[key] = info[key].attributes;
                state.afAttributesMeta = info[key].additional_attributes;
                continue;
            }
            if (key === 'model') {
                state.readOnly = info[key].status === 'paid';
            }
            state[key] = info[key];
        }
    },
    [CLEAR_INVOICE_MODAL_INFO](state) {
        state.model = {};
        state.modelItems = [];
        state.taxTotal = 0;
        state.totalWithoutTax = 0;
        state.totalWithTax = 0;
        state.perTax = [];
        state.readOnly = false;
        state.balanceAfter = '';
        state.billingDue = 0;
        state.isAddingItemsBlocked = false;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
