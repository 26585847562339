import { ACS_PROVISIONING_INIT_COMPONENT } from '@/apps/admin/store/mutation-types';
import { getUrlParam } from '@/utils/functions';
import { sendFormData } from '@/utils/senders';

const state = {
    attributeLabels: null,
    delimiters: null,
    modelId: null,
};

const getters = {
    attributeLabels(state) {
        return state.attributeLabels;
    },
    delimiters(state) {
        return state.delimiters;
    },
    importModelId(state) {
        return state.modelId;
    },
};

const actions = {
    initImportComponent({ commit }) {
        return new Promise((resolve, reject) => {
            let groupId = getUrlParam('id');
            $.ajax({
                url: `/admin/config/networking/acs--provision-flow-import-load-data?groupId=${groupId}`,
                success(data) {
                    commit(ACS_PROVISIONING_INIT_COMPONENT, data);
                    resolve();
                },
                failed() {
                    reject(window.xApp.$splang.t('config', 'Error while load importing data. Please refresh this page.'));
                },
            });
        });
    },
    processFile({ state }, formData) {
        return new Promise((resolve, reject) => {
            sendFormData(`/admin/config/networking/acs--provision-flow-import-process-file?json=yes&id=${state.modelId}`, formData)
                .then((data) => {
                    resolve(data);
                })
                .catch(() => {
                    reject(window.xApp.$splang.t('config', 'Error while processing file'));
                });
        });
    },
};

const mutations = {
    [ACS_PROVISIONING_INIT_COMPONENT](state, data) {
        state.attributeLabels = data.attributeLabels;
        state.delimiters = data.delimiters;
        state.modelId = data.modelId;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
