import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: 'cdr-import',
        name: 'admin.voice.processing.cdr_import',
        component: XTwigPage,
    },
    {
        path: 'cdr-export',
        name: 'admin.voice.processing.cdr_export',
        component: XTwigPage,
    },
    {
        path: 'cdr-history',
        name: 'admin.voice.processing.cdr_history',
        component: XTwigPage,
    },
    {
        path: 'cdr-errors',
        name: 'admin.voice.processing.cdr_errors',
        component: XTwigPage,
    },
    {
        path: 'cdr-errors--view-errors',
        name: 'admin.voice.processing.cdr_errors.view',
        component: XTwigPage,
    },
];
