/**
 * Dependencies:
 * props:
 *  - adminCanEdit
 *  - isArchived
 * methods:
 *  - hideSidebar
 * $refs:
 *  - form
 *   methods:
 *    - submit
 */
export default {
    data() {
        return {
            redirectToTaskPage: false,
        };
    },
    methods: {
        goToTaskPage() {
            if (!this.adminCanEdit || this.model.is_archived) {
                this.hideSidebar();
                switch_page(`/admin/scheduling/tasks/view?id=${this.id}`);
                return;
            }

            this.redirectToTaskPage = true;
            this.$refs.form.submit();
        },
    },
};
