import {
    SET_SCHEDULING_RESOURCES,
    SET_SCHEDULING_RESOURCES_CUSTOMER_OPTIONS,
    SET_SCHEDULING_RESOURCES_SIDEBAR_CUSTOMER_DATA,
    SET_SCHEDULING_RESOURCES_SIDEBAR_SERVICE_DATA,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';

const state = {
    resources: {
        class_name: '',
        options: {},
        breadcrumbs: {},
        sidebar_data: {},
    },
};

const getters = {
    ...stateToGetters(state),
    className(state) {
        return state.resources.class_name;
    },
    options(state) {
        return state.resources.options;
    },
    breadcrumbs() {
        return state.resources.breadcrumbs;
    },
};

const actions = {
    updateResources({ commit }, resources) {
        commit(SET_SCHEDULING_RESOURCES, resources);
    },
    updateCustomerOptions({ commit }, options) {
        commit(SET_SCHEDULING_RESOURCES_CUSTOMER_OPTIONS, options);
    },
    updateSidebarCustomerData({ commit }, data) {
        commit(SET_SCHEDULING_RESOURCES_SIDEBAR_CUSTOMER_DATA, data);
    },
    updateSidebarServiceData({ commit }, data) {
        commit(SET_SCHEDULING_RESOURCES_SIDEBAR_SERVICE_DATA, data);
    },
};

const mutations = {
    [SET_SCHEDULING_RESOURCES](state, resources) {
        state.resources = { ...resources };
    },
    [SET_SCHEDULING_RESOURCES_CUSTOMER_OPTIONS](state, options) {
        state.resources.options.customer_options = options;
    },
    [SET_SCHEDULING_RESOURCES_SIDEBAR_CUSTOMER_DATA](state, data) {
        state.resources.sidebar_data.customer = { ...data };
    },
    [SET_SCHEDULING_RESOURCES_SIDEBAR_SERVICE_DATA](state, data) {
        state.resources.sidebar_data.service = { ...data };
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
