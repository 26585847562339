import to_bill_log_edit_date from './to_bill_log_edit_date';
import overview_tab_info from './overview_tab_info';
import preview_reminder from './preview_reminder';

export default {
    namespaced: true,
    modules: {
        to_bill_log_edit_date,
        overview_tab_info,
        preview_reminder,
    },
};
