<template>
    <textarea
        ref="textarea"
        style="display: none"
        :value="value"
        data-test-selector="x-ace-component"
        @input="inputHandler"
    />
</template>

<script>
let ace = require('brace');

window.ace = ace;
require('brace/mode/twig');
require('brace/theme/github');
require('brace/snippets/twig');
require('brace/ext/language_tools');
require('brace/ext/searchbox');

export default {
    name: 'XAce',
    props: {
        mode: {
            type: String,
            default: 'twig',
        },
        value: String,
        encode: {
            type: Boolean,
            default: false,
        },
        height: {
            type: Number,
            default: 250,
        },
    },
    data() {
        return {
            editor: null,
        };
    },
    watch: {
        value(newVal, oldVal) {
            let code = this.getCode();
            if (newVal !== oldVal && newVal !== code) {
                if (this.encode) {
                    newVal = decodeURIComponentExtended(newVal);
                }
                this.editor.getSession().setValue(newVal);
            }
        },
    },
    mounted() {
        let textarea = $(this.$refs.textarea);

        let editDiv = $('<div>', {
            position: 'absolute',
            height: this.height,
            'data-testid': 'ace-container',
            class: 'col-lg-12 admin_config_modules_edit_form_code_editor',
        }).insertBefore(textarea);
        editDiv.css({ 'min-width': '100%', 'font-size': '14px' });

        this.editor = ace.edit(editDiv[0]);
        this.editor.$blockScrolling = Infinity;
        this.editor.getSession().setMode(`ace/mode/${this.mode}`);
        this.editor.setTheme('ace/theme/github');

        this.editor.getSession().on('change', () => {
            let code = this.getCode();
            textarea.val(code).change();
            this.$emit('input', code);
        });
        // Set editor value to textarea
        this.editor.getSession().setValue(decodeURIComponentExtended(textarea.val()));
        textarea.attr('is_ace', 'true');
        if (process.env.NODE_ENV === 'test') {
            window.currentAceEditor = this;
        }
    },
    methods: {
        getCode() {
            let code = this.editor.getSession().getValue();
            if (this.encode) {
                code = encodeURIComponent(code);
            }

            return code;
        },
        inputHandler(event) {
            this.$emit('input', event.target.value);
        },
    },
};
</script>

<style scoped>
</style>
