import { stateToGetters } from '@/apps/admin/store/helpers';
import { SET_CONFIGS_PAGE_DATA } from '@/apps/admin/store/mutation-types';
import { toCamelCase } from '@/utils/functions';

const state = {
    config: {
        className: '',
        items: [],
    },
    rawConfig: {
        translate: true,
    },
    attributesMeta: {},
    existsModule: true,
};

const getters = {
    ...stateToGetters(state),
    attributesMeta(state) {
        if (Object.keys(state.attributesMeta).length) {
            return state.attributesMeta.attributes;
        }
        return {};
    },
};

const actions = {
    loadPageData({ commit }, { module }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/modules/list--get-main-data?module=${module}`,
                success: (response) => {
                    commit(SET_CONFIGS_PAGE_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        }).then(() => {}).catch(() => {});
    },
};

const mutations = {
    [SET_CONFIGS_PAGE_DATA](state, data) {
        for (let k in data) {
            state[toCamelCase(k)] = data[k];
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
