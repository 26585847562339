import VoiceRoutes from './tariffs/voice';
import InternetRoutes from './tariffs/internet';
import RecurringRoutes from './tariffs/recurring';
import OneTimeRoutes from './tariffs/one-time';
import BundlesRoutes from './tariffs/bundles';
import FupRoutes from './tariffs/fup';
import PricingRoutes from './tariffs/pricing';

export default [
    {
        path: 'internet',
        children: InternetRoutes,
    },
    {
        path: 'fup',
        children: FupRoutes,
    },
    {
        path: 'voice',
        children: VoiceRoutes,
    },
    {
        path: 'pricing',
        children: PricingRoutes,
    },
    {
        path: 'recurring',
        children: RecurringRoutes,
    },
    {
        path: 'one-time',
        children: OneTimeRoutes,
    },
    {
        path: 'bundles',
        children: BundlesRoutes,
    },
];
