/**
 * Dependencies:
 * @props boolean getWatchersTrigger
 * @data object model
 * @data string model<id>
 * @computed object adminsOptions
 */
import TaskView from '@/apps/admin/services/scheduling/TaskView/TaskView';

export default {
    data() {
        return {
            selectedWatchers: [],
        };
    },
    mounted() {
        this.fetchWatchers();
    },
    computed: {
        watchersList() {
            return this.adminsOptions;
        },
    },
    methods: {
        fetchWatchers() {
            TaskView.loadWatchers(this.model.id).then((response) => {
                this.selectedWatchers = response;
            });
        },
    },
};
