<template>
    <div class="card-block">
        <div class="card-block-header">
            <h4 v-text="$t('tariffs', 'Plan availability')" />
        </div>
        <div class="card-block-body">
            <div class="row">
                <label class="col-md-4 col-form-label">
                    {{ getAttributeLabel('show_on_customer_portal') }}
                </label>

                <div class="col-md-8">
                    <x-complex-input
                        :value="value.show_on_customer_portal"
                        type="checkbox"
                        :params="{
                            options: {
                                name: className + '[show_on_customer_portal]',
                            }
                        }"
                        :meta="attributesMeta.show_on_customer_portal"
                        @input="inputHandler('show_on_customer_portal', $event)"
                    />
                </div>
            </div>

            <div class="row">
                <label class="col-md-4 col-form-label">
                    {{ getAttributeLabel('hide_on_admin_portal') }}
                    <a
                        v-x-tooltip:helpTooltip.top="HideOnAdminPortalTooltip"
                        href="javascript:void(0)"
                        class="btn-icon-sm"
                    >
                        <i class="icon-ic_fluent_info_24_regular" />
                    </a>
                </label>

                <div class="col-md-8">
                    <x-complex-input
                        :value="value.hide_on_admin_portal"
                        type="checkbox"
                        :params="{
                            options: {
                                name: className + '[hide_on_admin_portal]',
                            }
                        }"
                        :meta="attributesMeta.hide_on_admin_portal"
                        @input="inputHandler('hide_on_admin_portal', $event)"
                    />
                </div>
            </div>

            <div class="row">
                <label class="col-md-4 col-form-label">
                    {{ getAttributeLabel('available_for_locations') }}
                </label>

                <div class="col-md-8">
                    <x-complex-input
                        :value="value.available_for_locations"
                        type="multiple-select"
                        :params="{
                            options: {
                                options: getLocations,
                                name: className + '[available_for_locations]',
                            },
                        }"
                        :meta="attributesMeta.available_for_locations"
                        @input="inputHandler('available_for_locations', $event)"
                    />
                </div>
            </div>

            <div class="row">
                <label class="col-md-4 col-form-label">
                    {{ getAttributeLabel('customer_categories') }}
                </label>

                <div class="col-md-8">
                    <x-complex-input
                        :value="value.customer_categories"
                        type="multiple-select"
                        :params="{
                            options: {
                                options: customerCategories,
                                name: className + '[customer_categories]',
                            },
                        }"
                        :meta="attributesMeta.customer_categories"
                        @input="inputHandler('customer_categories', $event)"
                    />
                </div>
            </div>

            <div class="row">
                <label class="col-md-4 col-form-label">
                    {{ $t('tariffs', 'Customer labels') }}
                    <a
                        v-x-tooltip:helpTooltip.top="CustomersLabelTooltip"
                        href="javascript:void(0)"
                        class="btn-icon-sm"
                    >
                        <i class="icon-ic_fluent_info_24_regular" />
                    </a>
                </label>

                <div class="col-md-8">
                    <x-complex-input
                        :value="value.customerModuleLabels"
                        type="multipleTags"
                        :params="{
                            options: {
                                id: 'admin_tariffs_test_',
                                options: customerLabels.options,
                                name: className + '[customerModuleLabels]',
                                tagsForRender: customerLabels.render,
                                placeholder: $t('customers', 'Start typing label name'),
                            },
                        }"
                        :meta="attributesMeta.customerModuleLabels"
                        @input="inputHandler('customerModuleLabels', $event)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import XComplexInput from '@/components/common/inputs/XComplexInput/XComplexInput';
import CustomersLabelTooltip from '@/apps/admin/views/admin/tariffs/tooltips/CustomersLabelTooltip';
import { emptyObject, isset } from '@/utils/functions';
import HideOnAdminPortalTooltip from '@/apps/admin/views/admin/tariffs/tooltips/HideOnAdminPortalTooltip';

export default {
    name: 'AvailabilityForOrderingBlock',
    props: {
        attributesMeta: {
            type: Object,
            default: () => ({}),
        },
        value: {
            type: Object,
            default: () => ({}),
        },
        className: {
            type: String,
            default: '',
        },
        customerCategories: {
            type: Object,
            default: () => ({}),
        },
        customerLabels: {
            type: Object,
            default: () => ({}),
        },
        locationsFromPageData: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...mapGetters('locations', [
            'locations',
        ]),
        getLocations() {
            return emptyObject(this.locationsFromPageData) ? this.locations : this.locationsFromPageData;
        },
        CustomersLabelTooltip() {
            return {
                component: CustomersLabelTooltip,
            };
        },
        HideOnAdminPortalTooltip() {
            return {
                component: HideOnAdminPortalTooltip,
            };
        },
    },
    methods: {
        getAttributeLabel(attribute) {
            if (isset(this.attributesMeta, [attribute, 'label'])) {
                return this.attributesMeta[attribute].label;
            }

            return attribute;
        },
        inputHandler(keyName, value) {
            let newValue = this.value;
            newValue[keyName] = value;
            this.$emit('input', newValue);
        },
    },
    components: {
        XComplexInput,
    },
};
</script>
