<template>
    <div class="sidebar-content-inner">
        <transition name="fade">
            <div
                v-if="showMarkLink"
                id="mark_all_read"
                class="row justify-end mb-16"
                data-test-selector="mark-link"
            >
                <div class="col-md-12 text-end">
                    <a
                        href="javascript:void(0)"
                        class="mark-all-read"
                        data-test-selector="mark-all-read"
                        @click="clearNotifications"
                    >
                        {{ $t('common', 'Mark all as read') }}
                    </a>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div
                v-if="!notificationsCount"
                class="no-notification"
                data-test-selector="no-notification"
            >
                {{ $t('common', 'There is no notification in here') }}
            </div>
        </transition>
        <div
            id="notification-list-mobile"
            class="list-group list-group-alt"
        >
            <transition-group name="fade">
                <div
                    v-for="(notification, index) of notificationsList.slice().reverse()"
                    :key="`key-${index}`"
                    class="notification-item"
                >
                    <i
                        v-if="notification.type !== 'text'"
                        :class="{
                            'icon-ic_fluent_info_12_regular color-info': notification.type === 'info',
                            'icon-ic_fluent_checkmark_circle_24_regular color-success': notification.type === 'success',
                            'icon-ic_fluent_warning_24_regular color-warning': notification.type === 'warning',
                            'icon-ic_fluent_error_circle_24_regular color-danger': notification.type === 'error',
                        }"
                    />
                    <div class="activity-item">
                        <div
                            :class="{'activity': true, 'without-icon': notification.type === 'text', 'clickable': !!notification.action}"
                            @click="notification.action ? doAction(notification.action) : false"
                            v-html="notification.message"
                        />
                        <div
                            :data-id="index"
                            class="notification-remove"
                            @click="removeNotification($event)"
                        >
                            <i class="icon-ic_fluent_dismiss_24_regular" />
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { parseAnonymousSwitchPage } from '@/utils/functions';

export default {
    name: 'XNotificationsPanel',
    data() {
        return {
        };
    },
    methods: {
        doAction(notificationAction) {
            let actionIsStringFunction = false;
            if (typeof notificationAction === 'string' && notificationAction.startsWith('function')) {
                actionIsStringFunction = true;
            }
            if (typeof notificationAction != 'function' && !actionIsStringFunction) {
                switch_page(notificationAction);
                this.hideSidebar();
                return true;
            }

            notificationAction = parseAnonymousSwitchPage(notificationAction);
            notificationAction(null, true);

            this.hideSidebar();
        },
        removeNotification(e) {
            let id = e.currentTarget.getAttribute('data-id');
            let list = Array.from(this.notificationsList);
            let index = (list.length - 1) - id;
            list.splice(index, 1);
            this.changeNotificationList(list);
        },
        clearNotifications() {
            this.changeNotificationList([]);
        },
        ...mapActions('notifications', [
            'changeNotificationList',
        ]),
        ...mapActions('sidebar', [
            'hideSidebar',
        ]),
    },
    computed: {
        notificationsCount() {
            return this.notificationsList.length;
        },
        showMarkLink() {
            return !!this.notificationsList.length;
        },
        ...mapGetters('notifications', [
            'notificationsList',
        ]),
    },
};
</script>
