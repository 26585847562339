import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: 'customers-quotes-convertation',
        name: 'admin.crm.customers.customers_quotes_convertation',
        component: XTwigPage,
    },
    {
        path: 'customers-crm',
        name: 'admin.crm.customers.customers_crm',
        component: XTwigPage,
    },
    {
        path: 'customers-quotes',
        name: 'admin.crm.customers.customers_quotes',
        component: XTwigPage,
    },
];
