import XTwigPage from '@/components/base/XTwigPage';
import { makeIcons } from '@/utils/router/make-icons';
import { LAYOUT_EMPTY } from '@/constants';
import TariffsRoutes from './admin/tariffs';
import ConfigRoutes from './admin/config';
import AdministrationRoutes from './admin/administration';
import CrmRoutes from './admin/crm';
import CustomersRoutes from './admin/customers';
import TicketsRoutes from './admin/tickets';
import FinanceRoutes from './admin/finance';
import InventoryRoutes from './admin/inventory';
import NetworkingRoutes from './admin/networking';
import SupportRoutes from './admin/support';
import SchedulingRoutes from './admin/scheduling';
import VoiceRoutes from './admin/voice';
import LicenseRouters from './admin/license';
import ProfileRoutes from './admin/profile';

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/apps/admin/views/admin/dashboard/MainDashboard');
const Welcome = () => import(/* webpackChunkName: "dashboard" */ '@/apps/admin/views/admin/welcome/WelcomePage');

export default makeIcons([
    {
        name: 'admin.dashboard',
        path: 'dashboard',
        component: Dashboard,
        meta: {
            breadcrumbs: [
                { title: ['site', 'Dashboard'], url: '/admin/dashboard' },
            ],
            title: ['dashboard', 'Dashboard'],
            icon: 'icon-ic_fluent_board_24_regular',
            color: 'secondary',
            keepAlive: false,
            componentName: 'Dashboard',
        },
    },
    {
        path: 'tariffs',
        meta: {
            icon: 'icon-ic_fluent_add_subtract_circle_24_regular',
            color: 'success',
        },
        children: TariffsRoutes,
    },
    {
        path: 'crm',
        meta: {
            icon: 'icon-ic_fluent_handshake_24_regular',
            color: 'purple',
        },
        children: CrmRoutes,
    },
    {
        path: 'customers',
        meta: {
            icon: 'icon-ic_fluent_people_team_24_regular',
            color: 'purple',
        },
        children: CustomersRoutes,
    },
    {
        path: 'tickets',
        meta: {
            icon: 'icon-ic_fluent_ticket_diagonal_24_regular',
            color: 'purple',
        },
        children: TicketsRoutes,
    },
    {
        path: 'finance',
        meta: {
            icon: 'icon-ic_fluent_money_24_regular',
            color: 'purple',
            breadcrumbs: [
                { title: ['config', 'Finance'], url: '/admin/finance/dashboard' },
            ],
        },
        children: FinanceRoutes,
    },
    {
        path: 'inventory',
        meta: {
            icon: 'icon-ic_fluent_layer_24_regular',
            color: 'success',
        },
        children: InventoryRoutes,
    },
    {
        path: 'networking',
        children: NetworkingRoutes,
        meta: {
            icon: 'icon-ic_fluent_globe_person_24_regular',
            color: 'success',
        },
    },
    {
        path: 'support',
        meta: {
            icon: 'icon-ic_fluent_mail_24_regular',
            color: 'purple',
        },
        children: SupportRoutes,
    },
    {
        path: 'scheduling',
        meta: {
            icon: 'icon-ic_fluent_calendar_ltr_24_regular',
            color: 'success',
        },
        children: SchedulingRoutes,
    },
    {
        path: 'voice',
        children: VoiceRoutes,
        meta: {
            icon: 'icon-ic_fluent_mic_24_regular',
            color: 'success',
        },
    },
    {
        path: 'administration',
        children: AdministrationRoutes,
        meta: {
            icon: 'icon-ic_fluent_calendar_info_20_regular',
            color: 'secondary',
        },
    },
    {
        path: 'config',
        children: ConfigRoutes,
        meta: {
            icon: 'icon-ic_fluent_options_24_regular',
            color: 'secondary',
        },
    },
    {
        path: 'profile',
        children: ProfileRoutes,
        meta: {
            icon: 'icon-ic_fluent_person_24_regular',
            color: 'secondary',
        },
    },
    {
        path: 'reset-password',
        name: 'admin.reset_password',
        component: XTwigPage,
    },
    {
        path: 'reset-password--verify-check-code',
        name: 'admin.reset_password.verify',
        component: XTwigPage,
    },
    {
        path: 'reset-password--set-new-password',
        name: 'admin.reset_password.set_new_password',
        component: XTwigPage,
    },
    {
        path: 'welcome',
        name: 'admin.welcome',
        component: Welcome,
        meta: {
            layout: LAYOUT_EMPTY,
            title: ['dashboard', 'Post-install configuration'],
        },
    },
    {
        path: 'help',
        name: 'admin.help',
        component: XTwigPage,
    },
    {
        path: 'license',
        children: LicenseRouters,
    },
]);
