<template>
    <div
        id="help_render_wrapper"
        ref="helpRenderWrapper"
        class="admin-help-content"
        v-html="content"
    />
</template>

<script>

import { empty } from '@/utils/functions';
import { mapGetters } from 'vuex';

export default {
    name: 'HelpDocumentationRender',
    props: {
        content: {
            type: String,
        },
        allowRedirect: {
            type: Boolean,
            default: true,
        },
        hash: String,
    },
    mounted() {
        this.render();
        if (this.hash) {
            this.scrollToHash();
        }
    },
    methods: {
        scrollToHash() {
            this.$nextTick(() => {
                const targetElement = this.$refs.helpRenderWrapper.querySelector(`#${this.hash}`);
                if (targetElement) {
                    targetElement.scrollIntoView({ block: 'start' });
                }
            });
        },
        isUrlAbsolute(url) {
            if (typeof url !== 'string') {
                return false;
            }

            return /^[a-z][a-z0-9+.-]*:/.test(url);
        },
        isUrlWithJs(url) {
            if (typeof url !== 'string') {
                return false;
            }

            return /^javascript:/.test(url);
        },
        isDownloadLink(url) {
            if (typeof url !== 'string') {
                return false;
            }

            return /\/download\?/.test(url);
        },
        goToDocumentation(href) {
            window.open(href);
        },
        prepareLinks() {
            let that = this;
            let content = $(this.$refs.helpRenderWrapper);
            let wikiDomain = that.apiUrl;

            content.find('img').each(function () {
                let src = $(this).attr('src');
                if (!src.includes(wikiDomain)) {
                    $(this).attr('src', `${wikiDomain}/${src}`);
                    let wrapper = $(this).parent('a');
                    if (!empty(wrapper)) {
                        wrapper.attr('href', '');
                    }
                }
            });

            content.find('a').each(function () {
                let href = $(this).attr('href');
                if ($('a').hasClass('is-external-link')) {
                    $(this).click((e) => {
                        e.preventDefault();
                    });
                }

                // Skip absolute links and links with js
                if (that.isUrlAbsolute(href) || that.isUrlWithJs(href) || that.isDownloadLink(href)) {
                    if (!that.isUrlWithJs(href)) {
                        $(this).click(() => {
                            that.goToDocumentation(href);
                        });
                    }
                    return;
                }
                if ($('a').hasClass('is-internal-link')) {
                    if (that.allowRedirect) {
                        $(this).attr('href', `${wikiDomain}${href}`);
                    } else {
                        $(this).click((e) => {
                            e.preventDefault();
                            if (e.target.localName === 'a') {
                                that.$emit('load-by-path', href);
                            }
                        });
                    }
                }
            });
        },
        prepareDetails() {
            // Fix not expand details elements
            $(this.$refs.helpRenderWrapper).find('details').each(function () {
                $(this).find('summary').on('click', (e) => {
                    e.preventDefault();
                    $(this).attr('open', !$(this).attr('open'));
                });
            });
        },
        prepareImages() {
            // Apply enlarging functionality to each image
            $(this.$refs.helpRenderWrapper).find('img').each(function () {
                imageEnlarging(this);
            });
        },
        render() {
            this.prepareLinks();
            this.prepareImages();
            this.prepareDetails();
        },
    },
    computed: {
        ...mapGetters([
            'documentationConfig',
        ]),
        apiUrl() {
            return this.documentationConfig.api_url;
        },
    },
    updated() {
        this.render();
    },
};
</script>
