<template>
    <div class="d-flex align-items-center pb-12 justify-content-between">
        <div class="d-flex align-items-center">
            <button
                class="conversation-action-btn btn btn-link btn-icon rounded-pill me-16"
                @click="back"
            >
                <i
                    class="icon-ic_fluent_arrow_left_24_regular"
                />
            </button>
            <div class="d-flex flex-column">
                <h5>{{ title }}</h5>
                <small
                    v-if="assigned"
                >
                    <span class="text-secondary">{{ $t('messengers', 'Assigned to') }}</span>
                    <span class="text-dark"> {{ assigned }}</span>
                </small>
            </div>
        </div>
        <div
            v-if="showActions"
            class="chat-head-actions"
        >
            <div class="btn-group dropdown">
                <button
                    class="btn btn-primary dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {{ $t('common', 'Actions') }}
                </button>
                <ul
                    class="dropdown-menu"
                >
                    <li
                        class="dropdown-item"
                    >
                        <a
                            href="javascript:void(0)"
                            @click="$emit('set-mass-action-select-enabled', !massActionSelectEnabled)"
                        >
                            {{ massActionSelectEnabled ? $t('messengers', 'Deselect messages') : $t('messengers', 'Select messages') }}
                        </a>
                    </li>
                    <li
                        v-for="action of actions"
                        :key="action.name"
                        class="dropdown-item"
                    >
                        <a
                            href="javascript:void(0)"

                            data-submit="true"
                            data-activities="hide"
                            @click="$emit(action.name)"
                        >
                            {{ action.title }}{{ action.selectedCount }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import HeadMassActionsMixin from '@/apps/admin/mixins/messengers/head/head-mass-actions-mixin';

export default {
    name: 'ChatHeadExtended',
    mixins: [HeadMassActionsMixin],
    props: {
        title: String,
        assigned: String,
        customerId: [String, Number],
        showActions: Boolean,
        actions: Array,
    },
    methods: {
        back() {
            this.$router.push({
                query: {
                    id: this.customerId,
                    messengers: true,
                },
            });
        },
    },
};
</script>
