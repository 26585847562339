import { stateToGetters } from '@/apps/admin/store/helpers';
import {
    SET_NETWORK_SITE_VIEW_MODEL,
    SET_NETWORK_SITE_VIEW_ATTRIBUTES_META,
    SET_NETWORK_SITE_VIEW_CONTACTS_DATA,
    SET_NETWORK_SITE_VIEW_ACCESS_DATA,
    SET_NETWORK_SITE_VIEW_DOCUMENTS_DATA,
    SET_NETWORK_SITE_VIEW_PHOTOS_DATA,
    SET_NETWORK_SITE_VIEW_ADDON_TABS,
    SET_NETWORK_SITE_CAN_UPDATE_DATA,
} from '@/apps/admin/store/mutation-types';

const state = {
    model: {},
    contactsData: {
        contacts: [],
        contactsMeta: {},
    },
    accessData: {
        accessAction: '',
        accessController: '',
    },
    documentsData: {
        data: [],
        removeUrl: '',
        uploadUrl: '',
    },
    photosData: {
        data: [],
        removeUrl: '',
        uploadUrl: '',
    },
    attributesMeta: {
        additional_attributes: {},
        attributes: {},
    },
    canUpdate: false,
    addonTabs: [],
};
const getters = {
    attributesMeta(state) {
        return state.attributesMeta;
    },
    ...stateToGetters(state),
};
const actions = {
    updateModel({ commit }, model) {
        commit(SET_NETWORK_SITE_VIEW_MODEL, model);
    },
    updateAddonTabs({ commit }, data) {
        commit(SET_NETWORK_SITE_VIEW_ADDON_TABS, data);
    },
    updateAttributesMeta({ commit }, data) {
        commit(SET_NETWORK_SITE_VIEW_ATTRIBUTES_META, data);
    },
    updateContactsData({ commit }, data) {
        commit(SET_NETWORK_SITE_VIEW_CONTACTS_DATA, data);
    },
    updateAccessData({ commit }, data) {
        commit(SET_NETWORK_SITE_VIEW_ACCESS_DATA, data);
    },
    updateDocumentsData({ commit }, data) {
        commit(SET_NETWORK_SITE_VIEW_DOCUMENTS_DATA, data);
    },
    updatePhotosData({ commit }, data) {
        commit(SET_NETWORK_SITE_VIEW_PHOTOS_DATA, data);
    },
    updateCanUpdate({ commit }, data) {
        commit(SET_NETWORK_SITE_CAN_UPDATE_DATA, data);
    },
};
const mutations = {
    [SET_NETWORK_SITE_VIEW_MODEL](state, model) {
        state.model = { ...model };
    },
    [SET_NETWORK_SITE_VIEW_ADDON_TABS](state, data) {
        state.addonTabs = [...data];
    },
    [SET_NETWORK_SITE_VIEW_ATTRIBUTES_META](state, data) {
        state.attributesMeta = { ...data };
    },
    [SET_NETWORK_SITE_VIEW_CONTACTS_DATA](state, data) {
        state.contactsData = { ...data };
    },
    [SET_NETWORK_SITE_VIEW_ACCESS_DATA](state, data) {
        state.accessData = { ...data };
    },
    [SET_NETWORK_SITE_VIEW_DOCUMENTS_DATA](state, data) {
        state.documentsData = { ...data };
    },
    [SET_NETWORK_SITE_CAN_UPDATE_DATA](state, data) {
        state.canUpdate = data;
    },
    [SET_NETWORK_SITE_VIEW_PHOTOS_DATA](state, data) {
        state.photosData = { ...data };
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
