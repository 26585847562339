import XTwigPage from '@/components/base/XTwigPage';

const WorkflowsList = () => import(/* webpackChunkName: "scheduling_config" */ '@/apps/admin/views/admin/config/scheduling/workflows/WorkflowsList');
const SchedulingTeamsList = () => import(/* webpackChunkName: "scheduling_config" */ '@/apps/admin/views/admin/config/scheduling/teams/SchedulingTeamsList');
const TaskTemplates = () => import(/* webpackChunkName: "scheduling_config" */ '@/apps/admin/views/admin/config/scheduling/task-templates/TaskTemplates');
const ChecklistTemplatesList = () => import(/* webpackChunkName: "scheduling_config" */ '@/apps/admin/views/admin/config/scheduling/checklist-template/ChecklistTemplatesList');
const ProjectTypesList = () => import(/* webpackChunkName: "scheduling_config" */ '@/apps/admin/views/admin/config/scheduling/project-types/ProjectTypesList');
const ProjectCategories = () => import(/* webpackChunkName: "scheduling_config" */ '@/apps/admin/views/admin/config/scheduling/project-categories/ProjectCategories');

export default [
    {
        path: 'workflow',
        name: 'admin.config.scheduling.workflow',
        component: WorkflowsList,
        meta: {
            keepAlive: true,
            componentName: 'WorkflowsList',
        },
    },
    {
        path: 'team',
        name: 'admin.config.scheduling.team',
        component: SchedulingTeamsList,
        meta: {
            keepAlive: true,
            componentName: 'SchedulingTeamsList',
        },
    },
    {
        path: 'task-template',
        name: 'admin.config.scheduling.task_template',
        component: TaskTemplates,
        meta: {
            keepAlive: true,
            componentName: 'TaskTemplates',
        },
    },
    {
        path: 'checklist-template',
        name: 'admin.config.scheduling.checklist_template',
        component: ChecklistTemplatesList,
        meta: {
            keepAlive: true,
            componentName: 'ChecklistTemplatesList',
        },
    },
    {
        path: 'project-type',
        name: 'admin.config.scheduling.project_type',
        component: ProjectTypesList,
        meta: {
            keepAlive: true,
            componentName: 'ProjectTypesList',
        },
    },
    {
        path: 'project-category',
        name: 'admin.config.scheduling.project_category',
        component: ProjectCategories,
        meta: {
            keepAlive: true,
            componentName: 'ProjectCategories',
        },
    },
    {
        path: 'notifications',
        name: 'admin.config.scheduling.notifications',
        component: XTwigPage,
    },
];
