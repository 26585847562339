import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: 'history',
        name: 'admin.crm.leads.activity.history',
        component: XTwigPage,
        meta: {
            title: ['customers', 'History'],
        },
    },
];
