import { stateToGetters } from '@/apps/admin/store/helpers';
import {
    APPEND_BUNDLE_TARIFF,
    REMOVE_BUNDLE_TARIFF,
    SET_BUNDLE_TARIFF_FORM_DATA,
    SET_BUNDLE_TARIFFS_FORM_MODEL,
    SET_BUNDLE_TARIFFS_OPTIONS,
} from '@/apps/admin/store/mutation-types';
import { formattedRequest, formattedResponse } from '@/utils/functions';

const state = {
    showInternetTariffsPlans: false,
    showVoiceTariffsPlans: false,
    showCustomTariffsPlans: false,
    breadcrumbsTitle: 'Edit',
    isSetTransactionCategory: false,
    labels: {},
    afLabels: {},
    attributesMeta: {},
    additionalAttributes: {},
    model: {
        additionalAttributes: {},
        partnersIds: [],
        billingTypes: [],
    },
    className: '',
    options: {},
    internetTariffs: [],
    voiceTariffs: [],
    customTariffs: [],
    relations: [],
};

const getters = {
    ...stateToGetters(state),
    model(state) {
        return {
            ...state.model,
            customPeriod: state.model.customPeriod || '0',
            customerLabels: undefined,
        };
    },
};

const actions = {
    appendBundleTariff({ commit }, data) {
        commit(APPEND_BUNDLE_TARIFF, data);
    },
    removeBundleTariff({ commit }, data) {
        commit(REMOVE_BUNDLE_TARIFF, data);
    },
    loadTariffsByPartnersAndBilling({ commit }, {
        partnerIds, billingTypes, id, checkIsActive,
    }) {
        const params = $.param(formattedRequest({
            partnerIds,
            billingTypes,
            id,
            checkIsActive,
        }));
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: `/admin/tariffs/bundles--get-plans-by-partners-and-billing?${params}`,
                success: (response) => {
                    if (response.result) {
                        commit(SET_BUNDLE_TARIFFS_OPTIONS, response);
                    } else {
                        window.showErrors(response.message);
                    }
                    resolve();
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },
    loadEditPageInfo({ commit, state, dispatch }, { id, loadTariffs = true }) {
        const params = $.param({
            id,
        });
        let url = `/admin/tariffs/bundles--form-page-info?${params}`;
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url,
                success: (response) => {
                    if (!response.result && response.message) {
                        window.showErrors(response.message);
                    } else {
                        commit(SET_BUNDLE_TARIFF_FORM_DATA, response);
                        if (loadTariffs) {
                            dispatch('loadTariffsByPartnersAndBilling', {
                                partnerIds: state.model.partnersIds.join(','),
                                billingTypes: state.model.billingTypes.join(','),
                                id: state.model.id,
                                checkIsActive: '1',
                            }).then(() => {
                            }).catch(() => {
                            });
                        }
                    }
                    resolve();
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },
    setBundleTariffsFormModel({ commit }, data) {
        commit(SET_BUNDLE_TARIFFS_FORM_MODEL, data);
    },
};

const prepareModel = (model, additionalFields, labels) => {
    const additionalFieldsValues = {};
    for (let field in additionalFields) {
        additionalFieldsValues[field] = (additionalFields[field].value !== null) ? additionalFields[field].value : additionalFields[field].default;
    }
    return formattedResponse({
        ...model,
        additionalAttributes: additionalFieldsValues,
        customerModuleLabels: labels,
    });
};

function pushInternetTariff(state, id) {
    state.model.internetTariffs.push(id);
    return state;
}

function pushVoiceTariff(state, id) {
    state.model.voiceTariffs.push(id);
    return state;
}

function pushCustomTariff(state, id) {
    state.model.customTariffs.push(id);
    return state;
}

function removeInternetTariff(state, id) {
    state.model.internetTariffs.splice(id, 1);
    return state;
}

function removeVoiceTariff(state, id) {
    state.model.voiceTariffs.splice(id, 1);
    return state;
}

function removeCustomTariff(state, id) {
    state.model.customTariffs.splice(id, 1);
    return state;
}

const mutations = {
    [APPEND_BUNDLE_TARIFF](state, data) {
        switch (data.type) {
            case 'internet':
                state = pushInternetTariff(state, data.id);
                break;
            case 'voice':
                state = pushVoiceTariff(state, data.id);
                break;
            case 'custom':
                state = pushCustomTariff(state, data.id);
                break;
            default:
                return false;
        }
    },
    [REMOVE_BUNDLE_TARIFF](state, data) {
        switch (data.type) {
            case 'internet':
                state = removeInternetTariff(state, data.id);
                break;
            case 'voice':
                state = removeVoiceTariff(state, data.id);
                break;
            case 'custom':
                state = removeCustomTariff(state, data.id);
                break;
            default:
                return false;
        }
    },
    [SET_BUNDLE_TARIFFS_FORM_MODEL](state, {
        value, field, additional, model,
    }) {
        if (model) {
            state.model = model;
        } else if (additional) {
            state.model.additionalAttributes[field] = value;
        } else {
            state.model[field] = value;
        }
    },
    [SET_BUNDLE_TARIFFS_OPTIONS](state, data) {
        state.internetTariffs = data.items.internet;
        state.voiceTariffs = data.items.voice;
        state.customTariffs = data.items.custom;
        try {
            state.model.internetTariffs = data.items.internet?.selected;
            state.model.voiceTariffs = data.items.voice?.selected;
            state.model.customTariffs = data.items.custom?.selected;
        } catch (e) {
            console.error(e);
        }
    },
    [SET_BUNDLE_TARIFF_FORM_DATA](state, data) {
        data = formattedResponse(data);
        state.showInternetTariffsPlans = data.showInternetTariffsPlans;
        state.showVoiceTariffsPlans = data.showVoiceTariffsPlans;
        state.showCustomTariffsPlans = data.showCustomTariffsPlans;
        state.breadcrumbsTitle = data.breadcrumbsTitle;
        state.isSetTransactionCategory = data.isSetTransactionCategory;
        state.labels = formattedResponse(data.labels);
        state.afLabels = formattedResponse(formattedResponse(state.labels).additionalAttributes);
        state.additionalAttributes = formattedResponse(formattedResponse(data.attributesMeta).additionalAttributes);
        state.className = formattedResponse(data.attributesMeta).className;
        state.attributesMeta = formattedResponse(data.attributesMeta.attributes);
        state.model = prepareModel(data.model, state.additionalAttributes, data.customerModuleLabels);
        state.options = formattedResponse(data.options);
        state.relations = data.relations;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
