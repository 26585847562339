import {
    UPDATE_ROWS, DELETE_ROWS, ADD_ROWS, NEED_UPDATE_FLAG,
} from './mutation-types';

export default {
    [ADD_ROWS](state, rows) {
        state.rowsForAdd = rows;
    },
    [UPDATE_ROWS](state, rows) {
        state.rowsForUpdate = rows;
    },
    [DELETE_ROWS](state, ids) {
        if (typeof ids == 'string') {
            ids = ids.split(',');
        }
        state.idsForDelete = ids;
    },
    [NEED_UPDATE_FLAG](state, bool) {
        state.needToUpdate = bool;
        if (bool) {
            splynx_event_bus.emit('clear-selection-data-table');
        }
    },
};
