import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: 'quotes',
        name: 'admin.crm.leads.billing.quotes',
        component: XTwigPage,
        meta: {
            title: ['crm', 'Quotes'],
        },
    },
];
