// For photo|pdf gallery
window.makePhotoSwiper = function (selector) {
    let gallerySelector = !empty(selector) ? selector : '.splynx-photo-swiper';

    let getItems = function (galeryBlock) {
        let items = [];
        galeryBlock.find('.pws-open').each((i, item) => {
            let newItem;
            let $item = $(item);
            let type = $item.data('pws-type');

            switch (type) {
                case 'iframe':
                    var iframe = $('<iframe frameborder="0" width="100%" height="100%" class="embed-responsive-item" style="background: url(/images/loading.gif) no-repeat center center; margin-top: 44px;"></iframe>')
                        .attr('src', 'about:blank')
                        .attr('data-src', $item.data('pws-url'))
                        .prop('outerHTML');
                    var downloadButton = $(`<a class="btn btn-primary" onclick="download_by_iframe('${$item.data('pws-download-url')}')"></a>`)
                        .text(t('common', 'Download'));
                    downloadButton.css({
                        position: 'fixed',
                        top: `calc(50% - ${$('.pswp-hidden-download-button').outerHeight() / 2}px)`,
                        right: `calc(50% - ${$('.pswp-hidden-download-button').outerWidth() / 2}px)`,
                    });

                    newItem = {
                        html: $('html').hasClass('mobile') ? downloadButton.prop('outerHTML') : iframe,
                    };
                    break;

                case 'image':
                    var ratio = $item.data('pws-ratio').split('x');
                    newItem = {
                        src: $item.data('pws-url'),
                        w: parseInt(ratio[0], 10),
                        h: parseInt(ratio[1], 10),
                    };

                    break;

                default:
                    break;
            }
            if ($item.get(0).hasAttribute('data-pws-download-url')) {
                newItem.download = $item.data('pws-download-url');
            }
            if ($item.get(0).hasAttribute('data-pws-url')) {
                newItem.view = $item.data('pws-url');
            }
            if (newItem != undefined) {
                newItem.type = $item.data('pws-type');
                newItem.title = $item.data('pws-title');
                newItem.el = $item.get(0);
                items.push(newItem);
            }
        });
        return items;
    };

    let onClickelementEvent = function (e) {
        e = e || window.event;
        let el = e.target || e.srcElement;
        if (el.className == 'pws-open-froala') {
            let item = $(this).find(`.pws-open[data-pws-url="${el.getAttribute('data-pws-url')}"]`);
            if (item.length > 0) {
                el = item[0];
            }
        }
        let type = el.getAttribute('data-pws-type');
        if (type === 'iframe' || type === 'image') {
            // find base galery block
            let galeryBlock = $(el).closest(gallerySelector);
            let index = 0;
            galeryBlock.find('.pws-open[data-is-pws]').each((i, item) => {
                if ($(item).attr('data-is-pws') && el === item) {
                    index = i;
                    return false;
                }
            });

            openPhotoSwipe(index, galeryBlock);

            return false;
        }
    };
    let getDownloadAllUrl = (galeryBlock) => galeryBlock.find('.pws-open').parent('[data-pws-download-all-url]').data('pws-download-all-url');
    let isDownloadAllUrl = (galeryBlock) => galeryBlock.find('.pws-open').parent('[data-pws-is-download-all-url]').data('pws-is-download-all-url');

    var openPhotoSwipe = function (index, galeryBlock) {
        let pswpElement = document.querySelector('.pswp');
        let items = getItems(galeryBlock);
        let options = {
            bgOpacity: 0.8,
            closeOnScroll: false,
            closeOnVerticalDrag: false,
            mouseUsed: true,
            history: false,
            shareButtons: [
                { id: 'open_new_tab', label: t('common', 'Open in new window'), url: '{{raw_image_url}}' },
                {
                    id: 'download', label: t('common', 'Download'), url: '{{raw_image_url}}', download: true,
                },
            ],
            index: parseInt(index, 10),
        };
        if (isDownloadAllUrl(galeryBlock)) {
            options.shareButtons.push({
                id: 'download_all',
                label: t('common', 'Download All'),
                url: getDownloadAllUrl(galeryBlock),
                download: true,
            });
        }

        let gallery = new window.PhotoSwipe(pswpElement, window.PhotoSwipeUI_Default, items, options);

        // Fix for not lazy loading html content
        gallery.listen('afterChange', () => {
            $('.pswp iframe').prop('src', 'about:blank');
            $('.pswp iframe').each((i, item) => {
                if ($(item).prop('outerHTML') == gallery.currItem.html) {
                    $(item).prop('src', $(item).data('src'));
                }
            });
            $('.pswp__button--share').on('click', () => {
                $('.pswp__share-tooltip').find('.pswp__share--open_new_tab').attr('href', gallery.currItem.view);
                $('.pswp__share-tooltip').find('.pswp__share--download').attr('href', gallery.currItem.view);
            });
        });

        gallery.init();
    };

    // loop through all gallery elements and bind events
    let galleryElements = document.querySelectorAll(gallerySelector);
    galleryElements.forEach((item) => {
        item.onclick = onClickelementEvent;
    });
};
