import { stateToGetters } from '@/apps/admin/store/helpers';
import { CLEAR_HOTSPOT_REPORT_INFO, SET_HOTSPOT_REPORT_INFO } from '@/apps/admin/store/mutation-types';

const state = {
    model: {},
    attributesMeta: {},
    dataForForm: {},
    className: '',
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadPageData({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/administration/reports/hotspot-export--get-page-data',
                method: 'GET',
                success: (response) => {
                    commit(SET_HOTSPOT_REPORT_INFO, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_HOTSPOT_REPORT_INFO](state, info) {
        for (let key in info) {
            if (key === 'attributesMeta') {
                state[key] = info[key].attributes;
                state.className = info[key].className;
                continue;
            }
            state[key] = info[key];
        }
    },
    [CLEAR_HOTSPOT_REPORT_INFO](state) {
        state.model = {};
        state.dataForForm = {};
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
