import { promisifiedAjax } from '@/utils/functions';
import { SET_SEARCH_ACTIVE_POSITION, SET_SEARCH_QUERY, SET_SEARCH_RESULTS } from '@/apps/admin/store/mutation-types';

const state = {
    searchResults: {},
    searchQuery: '',
    activePosition: 0,
};

const actions = {
    search({ commit }, searchQuery) {
        if (searchQuery.length < 2) {
            return;
        }

        let queryString = searchQuery.replace('#', '%23');
        splynx_event_bus.emit('globalSearchProcessingStart');

        promisifiedAjax({
            url: `/admin/search?q=${queryString}`,
        }).then((result) => {
            commit(SET_SEARCH_RESULTS, result);
            splynx_event_bus.emit('globalSearchProcessingStop');
            commit(SET_SEARCH_QUERY, searchQuery);
        }).catch(() => {
            commit(SET_SEARCH_RESULTS, {
                isError: true,
                error: window.xApp.$splang.t('common', 'Error while search'),
            });
            splynx_event_bus.emit('globalSearchProcessingStop');
            commit(SET_SEARCH_QUERY, searchQuery);
        });
    },
    clearSearch({ commit }) {
        commit(SET_SEARCH_RESULTS, {});
        commit(SET_SEARCH_QUERY, '');
    },
    updateActivePositionByKey({ commit, state }, keyCode) {
        let { activePosition } = state;

        if (keyCode === 38) { // move up
            activePosition--;
        }

        if (keyCode === 40) { // move down
            activePosition++;
        }

        if (activePosition < 0) {
            activePosition = 0;
        }

        let rows = 0;
        if (state.searchResults.items !== undefined) {
            rows = state.searchResults.items.length;
        }

        if (activePosition >= rows) {
            activePosition = rows - 1;
        }

        commit(SET_SEARCH_ACTIVE_POSITION, activePosition);
    },
};

const getters = {
    searchResults(state) {
        return state.searchResults;
    },
    activePosition(state) {
        return state.activePosition;
    },
    searchQuery(state) {
        return state.searchQuery;
    },
};

const mutations = {
    [SET_SEARCH_RESULTS](state, searchResults) {
        state.searchResults = searchResults;
        state.activePosition = 0;
    },
    [SET_SEARCH_QUERY](state, searchQuery) {
        state.searchQuery = searchQuery;
    },
    [SET_SEARCH_ACTIVE_POSITION](state, searchQuery) {
        state.activePosition = searchQuery;
    },
};

export default {
    namespaced: true, state, actions, getters, mutations,
};
