import { stateToGetters } from '@/apps/admin/store/helpers';
import { toCamelCase } from '@/utils/functions';
import {
    SET_CONFIGS_REMINDERS_PAGE_DATA,
    SET_CONFIGS_REMINDERS_PAGE_BLOCKS_DATA,
} from '@/apps/admin/store/mutation-types';

const state = {
    config: {},
    rawConfig: {},
    attributesMeta: {},
    billing: {},
    partnerId: null,
    partnersList: {},
    isReminderStaticDay: false,
    blocks: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadPageData({ commit }, { partnerId }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/finance/reminders--get-page-data?partner_id=${partnerId}`,
                success: (response) => {
                    commit(SET_CONFIGS_REMINDERS_PAGE_DATA, response);
                    commit(SET_CONFIGS_REMINDERS_PAGE_BLOCKS_DATA, response.raw_config.blocks);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        }).then(() => {}).catch(() => {});
    },
};

const mutations = {
    [SET_CONFIGS_REMINDERS_PAGE_DATA](state, data) {
        for (let k in data) {
            state[toCamelCase(k)] = data[k];
        }
    },
    [SET_CONFIGS_REMINDERS_PAGE_BLOCKS_DATA](state, blocks) {
        state.blocks = blocks;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
