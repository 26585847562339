const InventoryReports = () => import(/* webpackChunkName: "inventory_sold_items_report" */ '@/apps/admin/views/admin/inventory/reports/InventoryReports');

export default [
    {
        path: 'sold-items',
        name: 'admin.inventory.reports.sold_items',
        component: InventoryReports,
    },
    {
        path: 'inventory-items',
        name: 'admin.inventory.reports.inventory_items',
        component: InventoryReports,
    },
];
