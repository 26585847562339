export default class MessageAuthor {
    static ROLE_CUSTOMER = 'customer';

    static ROLE_ADMIN = 'admin';

    static ROLE_LEAD = 'lead';

    constructor({ id, role, name } = {}) {
        this.id = id;
        this.name = name ?? '';
        this.role = role ?? MessageAuthor.ROLE_CUSTOMER;
    }
}
