import XTwigPage from '@/components/base/XTwigPage';

const MessagesHistoryList = () => import(/* webpackChunkName: "messages_history" */ '@/apps/admin/views/admin/support/history/MessagesHistoryList');

export default [
    {
        path: 'create',
        name: 'admin.support.messages.create',
        component: XTwigPage,
    },
    {
        path: 'history',
        name: 'admin.support.messages.history',
        component: MessagesHistoryList,
        meta: {
            keepAlive: true,
            componentName: 'MessagesHistoryList',
        },
    },
];
