import { stateToGetters } from '@/apps/admin/store/helpers';
import { SET_RENDER_TEMPLATE_CALLBACK, SET_RENDER_TEMPLATE_TRIGGER } from '@/apps/admin/store/mutation-types';

const state = {
    renderTemplateTrigger: false,
    callback: () => {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    setRenderTemplateTrigger({ commit }, value) {
        commit(SET_RENDER_TEMPLATE_TRIGGER, value);
    },
    setRenderTemplateCallback({ commit }, callback) {
        commit(SET_RENDER_TEMPLATE_CALLBACK, callback);
    },
};

const mutations = {
    [SET_RENDER_TEMPLATE_CALLBACK](state, callback) {
        state.callback = callback;
    },
    [SET_RENDER_TEMPLATE_TRIGGER](state, value) {
        state.renderTemplateTrigger = value;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
