import { promisifiedAjax } from '@/utils/functions';

const CUSTOMER = 'customer';
const LEAD = 'lead';

export default class TicketsService {
    static CREATE_TICKET_URL = '/admin/tickets/create--save';

    static MASS_ADD_LABELS_TO_TICKETS_URL = '/admin/tickets/opened--change-labels';

    static FORM_DATA_FOR_CREATING_TICKET_URL = '/admin/tickets/create--get-page-data';

    constructor(type) {
        this.url = TicketsService.TYPE_PATHS[type];
    }

    static TYPE_PATHS = {
        [CUSTOMER]: '/admin/customers/communication',
        [LEAD]: '/admin/crm/leads/communication',
    };

    static CONTROLLERS = {
        MESSENGERS_CHAT: 'messengers-chat',
    };

    static ACTIONS = {
        LINK_MESSAGE_TO_TICKET: 'link-message-to-ticket',
        UNLINK_MESSAGE_FROM_TICKET: 'unlink-message-from-ticket',
        GET_MODAL_LINK_TO_TICKET: 'get-modal-link-to-ticket',
    };

    static getCreateTicketUrl(customerId) {
        if (!customerId) {
            return TicketsService.CREATE_TICKET_URL;
        }
        return `${TicketsService.CREATE_TICKET_URL}?customerId=${customerId}`;
    }

    async getTicketsForOptions(customerId) {
        const controller = this.constructor.CONTROLLERS.MESSENGERS_CHAT;
        const action = this.constructor.ACTIONS.GET_MODAL_LINK_TO_TICKET;
        const params = `?customerId=${customerId}`;
        return new Promise((resolve) => {
            promisifiedAjax({
                url: `${this.url}/${controller}--${action}${params}`,
                method: 'GET',
            }).then((response) => {
                resolve(response);
            });
        });
    }

    async unlinkMessageFromTicket(messagesId = '', ticketId = '') {
        const controller = this.constructor.CONTROLLERS.MESSENGERS_CHAT;
        const action = this.constructor.ACTIONS.UNLINK_MESSAGE_FROM_TICKET;
        if (!(messagesId instanceof Array)) {
            messagesId = [messagesId];
        }
        return new Promise((resolve) => {
            promisifiedAjax({
                url: `${this.url}/${controller}--${action}`,
                method: 'POST',
                data: {
                    messagesIds: messagesId.join(','),
                    ticketId,
                },
            }).then((response) => {
                resolve(response);
            });
        });
    }

    async linkMessageToTicket(messagesId = '', ticketId = '') {
        const controller = this.constructor.CONTROLLERS.MESSENGERS_CHAT;
        const action = this.constructor.ACTIONS.LINK_MESSAGE_TO_TICKET;
        if (!(messagesId instanceof Array)) {
            messagesId = [messagesId];
        }
        return new Promise((resolve) => {
            promisifiedAjax({
                url: `${this.url}/${controller}--${action}`,
                method: 'POST',
                data: {
                    messagesIds: messagesId.join(','),
                    ticketId,
                },
            }).then((response) => {
                resolve(response);
            });
        });
    }

    static async getFormDataForCreatingTicket(customerId = '') {
        const params = customerId ? `?customerId=${customerId}` : '';
        return new Promise((resolve) => {
            promisifiedAjax({
                url: TicketsService.FORM_DATA_FOR_CREATING_TICKET_URL + params,
                method: 'GET',
            }).then((response) => {
                resolve(response);
            });
        });
    }

    static async getTicketsLabelsData(ids) {
        const formData = new FormData();
        formData.append('ids', ids);

        return new Promise((resolve) => {
            promisifiedAjax({
                url: TicketsService.MASS_ADD_LABELS_TO_TICKETS_URL,
                method: 'POST',
                data: formData,
                contentType: false,
                processData: false,
            }).then((response) => {
                resolve(response);
            });
        });
    }
}
