import { empty } from '@/utils/functions';
import {
    UPDATE_ROWS, DELETE_ROWS, ADD_ROWS, NEED_UPDATE_FLAG,
} from './mutation-types';

export default {
    addTableRows({ commit }, data) {
        commit(ADD_ROWS, data);
        commit(NEED_UPDATE_FLAG, !empty(data));
    },
    updateTableRows({ commit }, data) {
        commit(UPDATE_ROWS, data);
        commit(NEED_UPDATE_FLAG, !empty(data));
    },
    deleteRows({ commit }, data) {
        commit(DELETE_ROWS, data);
        commit(NEED_UPDATE_FLAG, !empty(data));
    },
    clearNeedToUpdate({ commit }, data) {
        commit(NEED_UPDATE_FLAG, data);
    },
};
