import {
    SET_PREVIEW_REMINDER, CLEAR_PREVIEW_REMINDER,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';

const state = {
    mail: {},
    sms: {},
    subject: '',
    reminderSubjects: '',
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadModalInfo({ commit }, { customerId, type }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing/overview--preview-reminder?customerId=${customerId}&reminderType=${type}`,
                method: 'GET',
                success: (response) => {
                    commit(SET_PREVIEW_REMINDER, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_PREVIEW_REMINDER](state, info) {
        for (let key in info) {
            state[key] = info[key];
        }
    },
    [CLEAR_PREVIEW_REMINDER](state) {
        state.mail = {};
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
