import { LOAD_LICENSE_CUSTOMERS_USAGE_MODAL_DATA } from '@/apps/admin/store/mutation-types';
import { promisifiedAjax } from '@/utils/functions';

const state = {
    dataTableHeaders: [],
    dtColumnDefs: {},
};

const getters = {
    dataTableHeaders(state) {
        return state.dataTableHeaders;
    },
    dtColumnDefs(state) {
        return state.dtColumnDefs;
    },
};

const actions = {
    loadModalInfo({ commit }) {
        return new Promise((resolve, reject) => {
            promisifiedAjax({
                url: '/admin/administration/splynx/license--load-customers-count-license-information',
            }).then((response) => {
                if (response.result) {
                    commit(LOAD_LICENSE_CUSTOMERS_USAGE_MODAL_DATA, response.data);
                    resolve();
                } else {
                    reject();
                }
            }).catch(() => {
                reject();
            });
        });
    },
};

const mutations = {
    [LOAD_LICENSE_CUSTOMERS_USAGE_MODAL_DATA](state, data) {
        for (let key in data) {
            if (!Object.prototype.hasOwnProperty.call(data, key) || !Object.prototype.hasOwnProperty.call(state, key)) {
                continue;
            }

            state[key] = data[key];
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
