import { empty } from '@/utils/functions';

export default class LicenseChecker {
    constructor() {
        this.license_info = window.spl_config.license_info;
    }

    needRedirectToLicenseRoute(originalRoute) {
        if (this.license_info !== undefined && !originalRoute.includes('/admin/license')) {
            return true;
        }
        return false;
    }

    getRouteToRedirect() {
        return this.license_info.licenseKey !== undefined && empty(this.license_info.licenseKey) ? { name: 'admin.license.enter' } : { name: 'admin.license' };
    }

    static reloadLicenseRoute() {
        window.spl_config.license_info = undefined;
        window.location.reload();
    }

    getLicenseInfo() {
        return this.license_info;
    }
}
