<template>
    <div :class="classes">
        <transition :name="transitionName">
            <div
                :key="route"
                class="chat-box-animation-wrapper"
            >
                <div
                    v-if="route === LIST"
                    class="chat-box-route"
                >
                    <chats-list
                        :chats="filteredChats"
                        :unassigned-filter="filter.length === 1 && filter[0] === '1'"
                        :loading="loading"
                        @create="viewCreate"
                        @view-chat="viewChat"
                        @view-assigned="viewAssignedChats"
                    />
                </div>
                <div
                    v-if="route === VIEW"
                    class="chat-box-route"
                >
                    <chat-box-chat-view
                        v-if="currentChatView.chatId"
                        :chat-id="currentChatView.chatId"
                        :customer-id="currentChatView.customerId"
                        :title="currentChatView.title"
                        :unknown-sender="currentChatView.isUnknownSender"
                        :messenger-id="currentChatView.messengerId"
                        :messenger-name="currentChatView.messengerName"
                        @not-found="onChatNotFound"
                        @back="viewList"
                        @start-conversation="startConversation"
                    />
                </div>
                <div
                    v-if="route === CREATE"
                    class="chat-box-route chat-box-create"
                >
                    <chat-box-create
                        :messengers="messengersList"
                        @create-form="viewCreateForm"
                        @back="viewList"
                    />
                </div>
                <div
                    v-if="route === CREATE_FORM"
                    class="chat-box-route chat-box-create"
                >
                    <chat-box-create-form
                        :only-messenger="onlyMessenger"
                        :messenger-id="currentCreateMessenger.messengerId"
                        :customer-id="currentCreateMessenger.customerId"
                        :phone="currentCreateMessenger.phone"
                        :messenger-title="currentCreateMessenger.messengerTitle"
                        :module="currentCreateMessenger.module"
                        :point="currentCreateMessenger.point"
                        @back="viewCreate(true, $event)"
                        @view-list="viewList"
                        @go-to-chat="goToChat"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ChatsList from '@/components/common/XMessengersChatbox/ChatsList';
import ChatBoxCreate from '@/components/common/XMessengersChatbox/create/ChatBoxCreate';
import ChatBoxCreateForm from '@/components/common/XMessengersChatbox/create/ChatBoxCreateForm';
import ChatBoxChatView from './ChatBoxChatView';

const LIST = 'list';
const VIEW = 'view';
const CREATE = 'create';
const CREATE_FORM = 'create-form';

export default {
    name: 'ChatBox',
    data() {
        return {
            currentChatView: {
                chatId: null,
                customerId: null,
                isUnknownSender: null,
                messengerId: null,
                messengerName: '',
                title: '',
            },
            currentCreateMessenger: {
                phone: '',
                customerId: null,
                messengerId: null,
                messengerTitle: '',
                module: '',
                point: null,
            },

            loading: true,
            transitionName: '',
            LIST,
            VIEW,
            CREATE,
            CREATE_FORM,
            route: LIST,
        };
    },
    async mounted() {
        await this.fetchChats();
        this.loading = false;
    },
    methods: {
        ...mapActions('chatbox', [
            'fetchChats',
            'setChatBoxFilter',
        ]),
        startConversation({
            messengerId, messengerTitle, customerId, phone,
        }) {
            const messenger = this.messengersList.find((messenger) => messenger.id === messengerId);

            this.viewCreateForm({
                messengerId,
                messengerTitle,
                customerId,
                phone,
                point: String(messenger.start_conversation.point_meta.point),
                module: messenger.start_conversation.point_meta.module,
            });
        },
        goToChat({
            id, messengerId, unknownSender, messengerName, customerId,
        }) {
            this.viewChat({
                chatId: id,
                customerId,
                messengerId,
                isUnknownSender: unknownSender,
                messengerName,
            });
        },
        viewAssignedChats() {
            this.setChatBoxFilter(['0']);
        },
        resetChatView() {
            this.currentChatView = {
                chatId: null,
                customerId: null,
                isUnknownSender: null,
                messengerId: null,
            };
        },
        viewList() {
            this.transitionName = 'slide-right';
            this.route = LIST;
        },
        viewCreate(isRight, onlyMessenger) {
            if (isRight) {
                this.transitionName = 'slide-right';
            } else {
                this.transitionName = 'slide-left';
            }

            if (onlyMessenger) {
                this.route = LIST;
                return;
            }

            if (this.onlyMessenger) {
                this.viewCreateForm({
                    messengerId: this.messengersList[0].id,
                    messengerTitle: this.messengersList[0].title,
                    point: String(this.messengersList[0].start_conversation.point_meta.point),
                    module: this.messengersList[0].start_conversation.point_meta.module,
                });
                return;
            }

            this.route = CREATE;
        },
        viewCreateForm({
            messengerId, messengerTitle, customerId, phone, module, point,
        }) {
            this.currentCreateMessenger = {
                messengerId,
                messengerTitle,
                customerId,
                phone,
                module,
                point,
            };

            this.$nextTick(() => {
                this.transitionName = 'slide-left';
                this.route = CREATE_FORM;
            });
        },
        viewChat({
            chatId, customerId, title, isUnknownSender, messengerId, messengerName,
        }) {
            this.currentChatView = {
                chatId,
                customerId,
                isUnknownSender,
                messengerId,
                messengerName,
                title,
            };

            this.$nextTick(() => {
                this.transitionName = 'slide-left';
                this.route = VIEW;
            });
        },
        onChatNotFound() {
            this.viewList();
            this.resetChatView();
        },
    },
    computed: {
        ...mapGetters('chatbox', [
            'viewListTrigger',
            'messengersList',
            'opened',
            'filter',
            'chats',
            'filteredChats',
        ]),
        onlyMessenger() {
            return this.messengersList.length === 1;
        },
        classes() {
            return [
                'chat-box',
                {
                    closed: !this.opened,
                },
            ];
        },
    },
    watch: {
        viewListTrigger(trigger) {
            if (!trigger) {
                return false;
            }
            this.viewList();
        },
        chats: {
            deep: true,
            handler(newList) {
                if (!newList.length) {
                    return false;
                }
                const chat = newList.find((chat) => chat.id === this.currentChatView.chatId);
                if (!chat) {
                    return false;
                }
                this.currentChatView.title = chat?.customer?.name;
                this.currentChatView.customerId = chat?.customer_id;
                this.currentChatView.isUnknownSender = !chat.customer && !chat?.customer_id;
            },
        },
    },
    components: {
        ChatBoxCreateForm, ChatBoxCreate, ChatsList, ChatBoxChatView,
    },
};
</script>

<style>
.slide-left-enter-active, .slide-left-leave-active {
    transition: transform 0.4s ease;
}
.slide-left-enter {
    transform: translateX(100%);
    z-index: 1;
}
.slide-left-leave-to {
    transform: translateX(-100%);
    z-index: 0;
}
.slide-left-enter-to {
    transform: translateX(0);
}
.slide-left-enter-active {
    transition: transform 0.35s ease;
}
.slide-left-leave-active {
    transition: transform 0.4s ease 0.05s;
}
.slide-right-enter-active, .slide-right-leave-active {
    transition: transform 0.4s ease-in-out;
}
.slide-right-enter {
    transform: translateX(-100%);
    z-index: 1;
}
.slide-right-leave-to {
    transform: translateX(100%);
    z-index: 0;
}
.slide-right-enter-to {
    transform: translateX(0);
}
.slide-right-enter-active {
    transition: transform 0.35s ease-in-out;
}
.slide-right-leave-active {
    transition: transform 0.4s ease-in-out 0.05s;
}
.slide-left-enter *, .slide-right-enter * {
    z-index: inherit;
}
.slide-left-leave-to *, .slide-right-leave-to * {
    z-index: inherit;
}
</style>
