import { stateToGetters } from '@/apps/admin/store/helpers';
import { SET_ADMIN_PROFILE_PAGE_DATA } from '../../mutation-types';

const state = {
    isSchedulingEnabled: true,
    addonTabs: [],
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadProfilePageData({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/profile--load-profile-page-data',
                success: (response) => {
                    commit(SET_ADMIN_PROFILE_PAGE_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_ADMIN_PROFILE_PAGE_DATA](state, data) {
        for (let k in data) {
            state[k] = data[k];
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
