import XTwigPage from '@/components/base/XTwigPage';

const PaymentMethodsList = () => import(/* webpackChunkName: "config_finance" */ '@/apps/admin/views/admin/config/finance/payment-methods/PaymentMethodsList');
const TransactionCategoriesList = () => import(/* webpackChunkName: "config_finance" */ '@/apps/admin/views/admin/config/finance/transaction-categories/TransactionCategoriesList');
const PaymentAccountsList = () => import(/* webpackChunkName: "config_finance" */ '@/apps/admin/views/admin/config/finance/payment-accounts/PaymentAccountsList');
const CostCategoriesList = () => import(/* webpackChunkName: "config_finance" */ '@/apps/admin/views/admin/config/finance/cost-categories/CostCategoriesList');
const FixedCostsList = () => import(/* webpackChunkName: "config_finance" */ '@/apps/admin/views/admin/config/finance/fixed-costs/FixedCostsList');
const TaxesList = () => import(/* webpackChunkName: "config_finance" */ '@/apps/admin/views/admin/config/finance/taxes/TaxesList');
const Reminders = () => import(/* webpackChunkName: "config_finance" */ '@/apps/admin/views/admin/config/finance/reminders/Reminders');
const ConfigFinanceSettings = () => import(/* webpackChunkName: "config_finance" */ '@/apps/admin/views/admin/config/finance/settings/ConfigFinanceSettings');

export default [
    {
        delimiter: '--',
        path: 'settings',
        name: 'admin.config.finance.main_settings',
        component: XTwigPage,
    },
    {
        path: 'settings',
        name: 'admin.config.finance.settings',
        component: ConfigFinanceSettings,
        meta: {
            breadcrumbs: [
                { title: ['config', 'Config'], url: '/admin/config' },
                { title: ['config', 'Finance'], url: '/admin/config#finance' },
                { title: ['config', 'Settings'], url: '/admin/config/finance/settings' },
            ],
            title: ['config', 'Settings'],
            componentName: 'ConfigFinanceSettings',
        },
    },
    {
        path: 'pairing',
        name: 'admin.config.finance.pairing',
        component: XTwigPage,
    },
    {
        path: 'notifications',
        name: 'admin.config.finance.notifications',
        component: XTwigPage,
    },
    {
        path: 'change-plan',
        name: 'admin.config.finance.change_plan',
        component: XTwigPage,
    },
    {
        path: 'payment-methods',
        name: 'admin.config.finance.payment_methods',
        component: PaymentMethodsList,
        meta: {
            keepAlive: true,
            componentName: 'PaymentMethodsList',
        },
    },
    {
        path: 'transaction-categories',
        name: 'admin.config.finance.transaction_categories',
        component: TransactionCategoriesList,
        meta: {
            keepAlive: true,
            componentName: 'TransactionCategoriesList',
        },
    },
    {
        path: 'reminders',
        name: 'admin.config.finance.reminders',
        component: Reminders,
        meta: {
            breadcrumbs: [
                { title: ['config', 'Config'], url: '/admin/config' },
                { title: ['config', 'Finance'], url: '/admin/config#finance' },
                { title: ['config', 'Reminders'], url: '/admin/config/reminders' },
            ],
            title: ['config', 'Reminders'],
            componentName: 'Reminders',
        },
    },
    {
        path: 'payment-accounts',
        name: 'admin.config.finance.payment_accounts',
        component: PaymentAccountsList,
        meta: {
            keepAlive: true,
            componentName: 'PaymentAccountsList',
        },
    },
    {
        path: 'cost-categories',
        name: 'admin.config.finance.cost_categories',
        component: CostCategoriesList,
        meta: {
            keepAlive: true,
            componentName: 'CostCategoriesList',
        },
    },
    {
        path: 'fixed-costs',
        name: 'admin.config.finance.fixed_costs',
        component: FixedCostsList,
        meta: {
            keepAlive: true,
            componentName: 'FixedCostsList',
        },
    },
    {
        path: 'accounting-categories',
        name: 'admin.config.finance.accounting_categories',
        component: XTwigPage,
    },
    {
        path: 'accounting-bank-accounts',
        name: 'admin.config.finance.accounting_bank_accounts',
        component: XTwigPage,
    },
    {
        path: 'accounting-tax-rates',
        name: 'admin.config.finance.accounting_tax_rates',
        component: XTwigPage,
    },
    {
        path: 'e-invoicing-tax-rates',
        name: 'admin.config.finance.e_invoicing_tax_rates',
        component: XTwigPage,
    },
    {
        path: 'e-invoicing-categories',
        name: 'admin.config.finance.e_invoicing_categories',
        component: XTwigPage,
    },
    {
        path: 'e-invoicing-payment-methods',
        name: 'admin.config.finance.e_invoicing_payment_methods',
        component: XTwigPage,
    },
    {
        path: 'taxes',
        name: 'admin.config.finance.taxes',
        component: TaxesList,
        meta: {
            breadcrumbs: [
                { title: ['config', 'Config'], url: '/admin/config' },
                { title: ['config', 'Finance'], url: '/admin/config#finance' },
                { title: ['config', 'Taxes'], url: '/admin/config/finance/taxes' },
            ],
            keepAlive: true,
            componentName: 'TaxesList',
            title: ['config', 'Taxes'],
        },
    },
];
