import {
    SET_CONFIGS_COMPANY_INFO_DATA,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';
import { toCamelCase } from '@/utils/functions';

const state = {
    config: {},
    rawConfig: {},
    attributesMeta: {},
    partner: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadPageData({ commit }, { partnerId }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/company-information--get-page-data?partner_id=${partnerId}`,
                success: (response) => {
                    commit(SET_CONFIGS_COMPANY_INFO_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        }).then(() => {}).catch(() => {});
    },
    deleteLogo({ commit }, { partnerId }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/company-information--delete-logo?partner_id=${partnerId}`,
                method: 'POST',
                success: (response) => {
                    commit(SET_CONFIGS_COMPANY_INFO_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        }).then(() => {}).catch(() => {});
    },
};

const mutations = {
    [SET_CONFIGS_COMPANY_INFO_DATA](state, data) {
        for (let k in data) {
            state[toCamelCase(k)] = data[k];
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
