import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: '',
        name: 'admin.customers.cards.view',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'statistics',
        name: 'admin.customers.cards.view.statistics',
        component: XTwigPage,
        meta: {
            title: ['customers', 'Voucher statistics'],
        },
    },
];
