<template>
    <div
        class="message-status position-relative w-100 d-flex align-items-center justify-content-end"
        :style="style"
    >
        <div :class="doubleContainerClass">
            <template v-if="!double">
                <i
                    :class="icon"
                    :style="{ color }"
                />
            </template>
            <template v-else>
                <i
                    :class="icon[0]"
                    :style="{ color }"
                />
                <i
                    :class="icon[1]"
                    :style="{ color }"
                />
            </template>
        </div>
    </div>
</template>

<script>
import MessageStatus from '@/apps/admin/services/messengers/MessageStatus';

export default {
    name: 'MessageStatusIcon',
    props: {
        status: {
            type: [String],
            default: 'queue',
        },
        out: Boolean,
    },
    computed: {
        style() {
            return { opacity: String(Number(this.out)) };
        },
        color() {
            return MessageStatus.get(this.status).color;
        },
        double() {
            return MessageStatus.get(this.status).double;
        },
        icon() {
            const status = MessageStatus.get(this.status);
            if (this.double) {
                return [status.icon, status.icon];
            }
            return status.icon;
        },
        doubleContainerClass() {
            return ['icons-container', { double: this.double }];
        },
    },
};
</script>
