import { promisifiedAjax } from '@/utils/functions';

/**
 * Dependencies:
 * props:
 *  - projectId
 *  - newRecord
 * data:
 *  - options
 *   - statuses
 *  - model
 *   - title
 *   - workflow_status_id
 * methods:
 *  - doesHasCurlyBrackets:
 *  - changeRenderForTemplates:
 *  - renderTemplates:
 *  - checkPartners:
 */
export default {
    data() {
        return {
            relatedToProjectData: {},
        };
    },
    methods: {
        /** @method Call it after loadData */
        __changeHandlersMixin_loadData() {
            if (this.projectId && this.newRecord) this.changeProject(this.model.project_id);
        },
        async changeTitle() {
            if (this.doesHasCurlyBrackets(this.model.title)) {
                this.changeRenderForTemplates('schedulingtask-title', this.model.title);
                await this.renderTemplates();
            }
            return true;
        },
        changeDescription() {
            if (this.doesHasCurlyBrackets(this.model.description)) {
                this.templatesForRender.admin_scheduling_task_create_description_area_froala_input = this.model.description;
                this.renderTemplates();
            }
        },
        changeAdmin() {
            this.checkPartners();
            this.renderTemplates();
        },
        changeProject(value) {
            if (value !== null && isset(this.relatedToProjectData, [value])) {
                this.checkPartners();
                this.options.statuses = this.relatedToProjectData[value].statuses;
                this.$nextTick(() => {
                    this.model.workflow_status_id = this.relatedToProjectData[value]?.statuses[0]?.id;
                });
            }
        },
        changeTaskGeoDataByParamsHandler(params) {
            let type = params.scenario;
            params.address = this.model.address;
            params.gps = this.model.gps;

            // Call to server and define if we need change address in inputs
            promisifiedAjax({
                url: `/admin/scheduling/tasks/view--define-geo-data-change?type=${type}_change`,
                data: params,
                method: 'POST',
            }).then((response) => {
                if (response.need_change) {
                    this.setAddressAction(response.address);
                    this.setCoordinateAction(response.marker);

                    this.model.address = response.address;
                    this.model.gps = response.marker;

                    this.setGeoDataSource(type, 'address', response.address);
                    this.setGeoDataSource(type, 'marker', response.marker);

                    this.$nextTick(() => {
                        if (typeof this.formMapPreview === 'function') {
                            this.formMapPreview(response.marker);
                        }
                    });
                }
            });
        },
    },
};
