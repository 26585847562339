import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: 'internet-statistics',
        name: 'admin.customers.statistics.internet_statistics',
        component: XTwigPage,
        meta: {
            title: ['customers', 'Statistics internet'],
        },
    },
    {
        path: 'voice-statistics',
        name: 'admin.customers.statistics.voice_statistics',
        component: XTwigPage,
        meta: {
            title: ['customers', 'Statistics voice'],
        },
    },
];
