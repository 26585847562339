import {
    SET_ADMIN_PROFILE_PHOTO, SET_LOGOUT_TIMEOUT, SET_SHOW_ERROR_PAGE,
    SET_PAGE_TITLE,
} from '@/apps/admin/store/mutation-types';

export default {
    setTitle({ commit }, title) {
        commit(SET_PAGE_TITLE, title ?? '');
    },
    setErrorState({ commit }, response) {
        commit(SET_SHOW_ERROR_PAGE, response);
    },
    updateAdminProfileImage({ commit }) {
        $.ajax({
            dataType: 'json',
            url: '/admin/profile--get-photo-url',
            success: (data) => {
                commit(SET_ADMIN_PROFILE_PHOTO, data.url);
            },
        });
    },
    setLogoutTimeout({ commit }, time) {
        window.logout_is_enabled = time !== 0;
        window.logout_timer_now = window.time();
        window.logout_time = time;
        commit(SET_LOGOUT_TIMEOUT, time);
        setInterval(window.logout_timer, 1000);
    },
};
