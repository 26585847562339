import {
    SET_FINANCE_MASS_PAY, CLEAR_FINANCE_MASS_PAY, SET_PAYMENT_MODAL_INFO,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';

const state = {
    isMassAction: false,
    isPendingInvoices: false,
    isPendingProformaInvoices: false,
    customerId: 0,
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadModalInfo({ commit }, { nameController, ids }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing/${nameController}--mass-pay`,
                method: 'POST',
                data: {
                    ids,
                },
                success: (response) => {
                    commit(SET_FINANCE_MASS_PAY, response);
                    commit(`customer_payments/${SET_PAYMENT_MODAL_INFO}`, response, { root: true });
                    resolve(response);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_FINANCE_MASS_PAY](state, info) {
        for (let key in info) {
            state[key] = info[key];
        }
    },
    [CLEAR_FINANCE_MASS_PAY](state) {
        state.isMassAction = false;
        state.isPendingInvoices = false;
        state.isPendingProformaInvoices = false;
        state.customerId = 0;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
