<template>
    <div class="chat-box-list-row">
        <chat-box-header
            @close="$closeChatBox()"
            @create="createChatRoute"
        />

        <div
            class="chat-box-content-grow chat-box-scroll"
        >
            <div
                v-if="!loading && chats.length"
            >
                <div
                    v-if="loading"
                    class="spinner-wrapper mt-0"
                >
                    <x-bootstrap-spinner
                        size="md"
                    />
                </div>
                <template v-if="!loading">
                    <transition-group
                        name="list"
                        tag="div"
                        :css="false"
                        @before-enter="onBeforeEnter"
                        @before-leave="onBeforeLeave"
                        @enter="onEnter"
                        @leave="onLeave"
                    >
                        <div
                            v-for="(chat, index) in chats"
                            :key="index"
                            :data-index="index"
                        >
                            <chat-box-item
                                :key="index"
                                :messenger-name="chat.messenger_name"
                                :messenger-id="chat.messenger_id"
                                :customer-id="chat.customer_id"
                                :chat-id="chat.id"
                                :title="chat.customer && chat.customer.name"
                                :phone="chat.phone"
                                :attachments="chat.message.attachments || []"
                                :preview="chat.message.body"
                                :assignee="chat.assign_id"
                                :date="chat.message.created"
                                :counter="chat.chat_unread_messages"
                                @view="viewChatRoute"
                            />
                        </div>
                    </transition-group>
                </template>
            </div>

            <div
                v-if="!chats.length && !loading"
                class="chat-box-empty-list chat-box-content-grow"
            >
                <x-translate
                    v-if="unassignedFilter"
                    tag="false"
                    message="There are no unassigned chats at the moment. You can {my} or browse {all}."
                >
                    <template #my>
                        <a
                            href="javascript:void(0)"
                            data-test-selector="view-assigned-chats"
                            @click="$emit('view-assigned')"
                        >{{ $t('messengers', 'view your chats') }}</a>
                    </template>
                    <template #all>
                        <a
                            href="javascript:void(0)"
                            data-test-selector="browse-all-chats"
                            @click="seeAllInMessengers"
                        >{{ $t('messengers', 'browse all available conversations') }}</a>
                    </template>
                </x-translate>

                <x-translate
                    v-else
                    tag="false"
                    message="No active chats are currently assigned to you. You can either {create} or view {all}"
                >
                    <template #create>
                        <a
                            href="javascript:void(0)"
                            data-test-selector="create-new-chat"
                            @click="createChatRoute"
                        >{{ $t('messengers', 'create a new chat') }}</a>
                    </template>
                    <template #all>
                        <a
                            href="javascript:void(0)"
                            data-test-selector="see-all-chats"
                            @click="seeAllInMessengers"
                        >{{ $t('messengers', 'all available conversations') }}</a>
                    </template>
                </x-translate>
            </div>
        </div>

        <div class="chat-box-all-link">
            <a
                href="javascript:void(0)"
                @click="seeAllInMessengers"
            >
                {{ $t('messengers', 'See all in Messengers') }}
            </a>
        </div>
    </div>
</template>

<script>
import ChatBoxHeader from '@/components/common/XMessengersChatbox/ChatBoxHeader';
import XTranslate from '@/splang/XTranslate';
import ChatBoxItem from '@/components/common/XMessengersChatbox/ChatBoxItem';
import XBootstrapSpinner from '@/components/common/XBootstrapSpinner/XBootstrapSpinner';

export default {
    name: 'ChatsList',
    props: {
        loading: Boolean,
        chats: Array,
        unassignedFilter: Boolean,
    },
    methods: {
        onBeforeEnter(element) {
            element.style.opacity = 0;
            element.style.transform = 'translateY(30px)';
        },
        onEnter(element, done) {
            const index = parseInt(element.dataset.index, 10);
            const delay = (index + 1) * 100;
            setTimeout(() => {
                element.style.transition = 'all 0.5s ease';
                element.style.opacity = 1;
                element.style.transform = 'translateY(0)';
                done();
            }, delay);
        },
        onBeforeLeave(element) {
            element.style.opacity = 1;
            element.style.transform = 'translateY(0)';
        },
        onLeave(element, done) {
            element.style.transition = 'all 0.5s ease';
            element.style.opacity = 0;
            element.style.transform = 'translateY(-30px)';
            setTimeout(() => {
                done();
            }, 500);
        },
        viewChatRoute({
            chatId, customerId, title, isUnknownSender, messengerId, messengerName,
        }) {
            this.$emit('view-chat', {
                chatId, customerId, title, isUnknownSender, messengerId, messengerName,
            });
        },
        createChatRoute() {
            this.$emit('create');
        },
        seeAllInMessengers() {
            switch_page('/admin/support/messengers?tab=all_senders_tab').then(() => {
                this.$closeChatBox();
            });
        },
    },
    components: {
        XBootstrapSpinner,
        ChatBoxItem,
        XTranslate,
        ChatBoxHeader,
    },
};
</script>
