import { stateToGetters } from '@/apps/admin/store/helpers';
import { CHECK_ALL_CUSTOMERS } from '@/apps/admin/store/mutation-types';

const state = () => ({
    checkedAll: false,
});

const getters = {
    ...stateToGetters(state()),
};

const actions = {
    checkAllState({ commit }, isAllChecked) {
        commit(CHECK_ALL_CUSTOMERS, isAllChecked);
    },
};

const mutations = {
    [CHECK_ALL_CUSTOMERS](state, checked) {
        state.checkedAll = checked;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
