import { mapActions, mapGetters } from 'vuex';

// frontend/apps/admin/store/modules/scheduling/scheduling_calendars
const STORE = 'scheduling_calendars';

/**
 * Dependencies:
 * data:
 *  - model
 *   - address
 */
export default {
    methods: {
        /** @method Call it after loadData */
        __addressMixin_loadData() {
            this.setAddress(this.model.address);
        },
        setAddress(address) {
            this.setAddressAction(address);
        },
        ...mapActions(STORE, [
            'setAddressAction',
        ]),
    },
    computed: {
        ...mapGetters(STORE, [
            'getTaskAddress',
        ]),
    },
    watch: {
        'model.address': function (newVal) {
            this.setAddress(newVal);
        },
        getTaskAddress(newVal) {
            if (newVal !== this.model.address) {
                this.model.address = newVal;
            }
        },
    },
};
