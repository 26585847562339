import { XTwigPage } from '@/components/base/XTwigPage';
import CardsRoutes from './customers/cards';
import ListRoutes from './customers/list';
import StatisticsRoutes from './customers/statistics';
import ActivityRoutes from './customers/activity';

const CustomerView = () => import(/* webpackChunkName: "customer_view" */ '@/apps/admin/views/admin/customers/view/CustomerView');

export default [
    {
        path: 'add',
        name: 'admin.customers.add',
        component: XTwigPage,
    },
    {
        path: 'search',
        name: 'admin.customers.search',
        component: XTwigPage,
    },
    {
        path: 'list',
        children: ListRoutes,
    },
    {
        path: 'map',
        name: 'admin.customers.map',
        component: XTwigPage,
    },
    {
        path: 'view',
        name: 'admin.customers.view',
        component: CustomerView,
        meta: {
            breadcrumbs: [
                { title: ['common', 'Customers'], url: '/admin/dashboard' },
                { title: ['common', 'List'], url: '/admin/customers/list' },
            ],
            title: ['common', 'Customer'],
        },
    },
    {
        path: 'cards',
        children: CardsRoutes,
    },
    {
        path: 'statistics',
        children: StatisticsRoutes,
    },
    {
        path: 'activity',
        children: ActivityRoutes,
    },
];
