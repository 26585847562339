import XTwigPage from '@/components/base/XTwigPage';
import CustomerRatesRoutes from '@/apps/admin/router/routes/admin/voice/customer_rates';
import ProviderRatesRoutes from '@/apps/admin/router/routes/admin/voice/provider_rates';

export default [
    {
        path: 'customers',
        name: 'admin.voice.rates.customers',
        component: XTwigPage,
    },
    {
        path: 'customer-rates',
        children: CustomerRatesRoutes,
    },
    {
        path: 'providers',
        name: 'admin.voice.rates.providers',
        component: XTwigPage,
    },
    {
        path: 'provider-rates',
        children: ProviderRatesRoutes,
    },
];
