import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions('partners', [
            'loadPartners',
        ]),
        ...mapActions('locations', [
            'loadLocations',
        ]),
        loadPageInfo() {
            return this.$route.meta.edit ? this.loadEditPageInfo({ id: this.$route.query.id }) : this.loadAddPageInfo();
        },
    },
    computed: {
        type() {
            return this.$route.meta.edit ? 'edit' : 'add';
        },
        breadcrumbs() {
            return !this.$route.meta.edit
                ? this.$route.meta.breadcrumbs
                : [
                    ...this.$route.meta.breadcrumbs,
                    { title: this.model.title, url: '#' },
                ];
        },
    },
};
