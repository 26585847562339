import { stateToGetters } from '@/apps/admin/store/helpers';
import {
    SET_SIDEBAR_TASK_WARNINGS_ARRAY,
    SET_TASK_WARNINGS_ARRAY,
} from '@/apps/admin/store/mutation-types';

const state = {
    warningsTask: {},
    warningsSidebarTask: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    updateTaskWarningsArray({ commit }, data) {
        commit(SET_TASK_WARNINGS_ARRAY, data);
    },
    updateSidebarTaskWarningsArray({ commit }, data) {
        commit(SET_SIDEBAR_TASK_WARNINGS_ARRAY, data);
    },
};

const mutations = {
    [SET_TASK_WARNINGS_ARRAY](state, data) {
        state.warningsTask = data;
    },
    [SET_SIDEBAR_TASK_WARNINGS_ARRAY](state, data) {
        state.warningsSidebarTask = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
