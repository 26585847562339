import { mapActions } from 'vuex';

export default {
    mounted() {
        this.initTicketSocketListener();
    },
    methods: {
        ...mapActions('chats_listener', ['addChatHandlers']),
        initTicketSocketListener() {
            this.addChatHandlers({ callbacks: [this.onTicketLinked], ids: { chat: this.chatId, messenger: this.messenger } });
            this.addChatHandlers({ callbacks: [this.onTicketUnlinked], ids: { chat: this.chatId, messenger: this.messenger } });
        },
        onTicketLinked({ message_id, ticket }) {
            const message = this.currentPart.messages.find((message) => message.id === message_id);
            if (message) {
                message.ticket = ticket;
            }
        },
        onTicketUnlinked({ message_id }) {
            const message = this.currentPart.messages.find((message) => message.id === message_id);
            if (message) {
                message.ticket.id = null;
            }
        },
    },
};
