import {
    SET_OVERVIEW_EDIT_TO_BILL_DATE, CLEAR_OVERVIEW_EDIT_TO_BILL_DATE,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';

const state = {
    blockInNextBillingCycle: false,
    recurringBlockingDays: '',
    recurringBlockingDate: '',
    deactivationDate: '',
    deactivationDays: '',
    customerStatus: '',
    isProcessBillingDueEnabled: false,
    toBillLogDate: '',
    isWorkingDay: true,
    deactivationDayIsWorking: true,
    disabledByPeriod: false,
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadModalInfo({ commit }, { customer_id, date }) {
        let data = {
            id: customer_id,
        };
        if (date) {
            data.date = date;
        }

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing/overview--recurring-blocking-info?${$.param(data)}`,
                method: 'GET',
                success: (response) => {
                    commit(SET_OVERVIEW_EDIT_TO_BILL_DATE, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_OVERVIEW_EDIT_TO_BILL_DATE](state, info) {
        for (let key in info) {
            state[key] = info[key];
        }
    },
    [CLEAR_OVERVIEW_EDIT_TO_BILL_DATE](state) {
        state.blockInNextBillingCycle = false;
        state.recurringBlockingDays = '';
        state.recurringBlockingDate = '';
        state.deactivationDate = '';
        state.deactivationDays = '';
        state.customerStatus = '';
        state.isProcessBillingDueEnabled = false;
        state.toBillLogDate = '';
        state.isWorkingDay = true;
        state.deactivationDayIsWorking = true;
        state.disabledByPeriod = false;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
