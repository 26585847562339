import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: '',
        name: 'admin.tariffs.fup',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'preview',
        name: 'admin.tariffs.fup.preview',
        component: XTwigPage,
        meta: {
            title: ['voice', 'FUP Preview and Test'],
        },
    },
];
