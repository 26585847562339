import { stateToGetters } from '@/apps/admin/store/helpers';
import FileUploadCheckAccessService from '@/apps/admin/services/common/FileUpload';

const state = {
    checkAccessAction: '',
    checkAccessController: '',
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    async getAccessPermissions({ commit }, params) {
        let data = await FileUploadCheckAccessService.loadCheckAccess(params);
        commit('updateData', data);
    },
};

const mutations = {
    updateData(state, data) {
        state.checkAccessAction = data.check_access_action;
        state.checkAccessController = data.check_access_controller;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
