import ProcessingRoutes from './voice/processing';
import RatesRoutes from './voice/rates';

const VoiceCategoriesList = () => import(/* webpackChunkName: "voice_categories" */ '@/apps/admin/views/admin/voice/categories/VoiceCategoriesList');
const VoicePrefixesList = () => import(/* webpackChunkName: "voice_prefixes" */ '@/apps/admin/views/admin/voice/prefixes/VoicePrefixesList');
export default [
    {
        path: 'processing',
        children: ProcessingRoutes,
    },
    {
        path: 'rates',
        children: RatesRoutes,
    },
    {
        path: 'categories',
        name: 'admin.voice.categories',
        component: VoiceCategoriesList,
        meta: {
            keepAlive: true,
            componentName: 'VoiceCategoriesList',
        },
    },
    {
        path: 'prefixes',
        name: 'admin.voice.prefixes',
        component: VoicePrefixesList,
        meta: {
            keepAlive: true,
            componentName: 'VoicePrefixesList',
        },
    },
];
