import { promisifiedAjax } from '@/utils/functions';
import { mapActions } from 'vuex';

/**
 * Dependencies:
 * data:
 *  - model
 *   - id
 */
export default {
    data() {
        return {
            checklistTemplateItems: [],
        };
    },
    methods: {
        renderChecklistTemplate(value) {
            return new Promise((resolve, reject) => {
                promisifiedAjax({
                    url: `/admin/scheduling/tasks/view--load-checklist-items?id=${this.model.id}&checklist=${value}`,
                    method: 'GET',
                }).then((response) => {
                    this.updateChecklist([...response]);
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        getChecklistByTemplate(value) {
            return new Promise((resolve, reject) => {
                promisifiedAjax({
                    url: `/admin/scheduling/tasks/view--load-checklist-by-template?id=${value}`,
                    method: 'GET',
                }).then((response) => {
                    this.checklistTemplateItems = response;
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        ...mapActions('task_view_checklist', ['updateChecklist']),
    },
};
