export default {
    namespaced: true,
    state: {
        backlogTasksCount: 0,
        taskCoordinate: '',
        taskAddress: '',
    },
    getters: {
        getBacklogTasksCount(state) {
            return state.backlogTasksCount;
        },
        getTaskCoordinate(state) {
            return state.taskCoordinate;
        },
        getTaskAddress(state) {
            return state.taskAddress;
        },
    },
    actions: {
        setCoordinateAction({ commit }, coordinate) {
            commit('setTaskCoordinate', coordinate);
        },
        setAddressAction({ commit }, address) {
            commit('setTaskAddress', address);
        },
    },
    mutations: {
        setBacklogTasksCount(state, count) {
            state.backlogTasksCount = count;
        },
        setTaskCoordinate(state, coordinate) {
            state.taskCoordinate = coordinate;
        },
        setTaskAddress(state, address) {
            state.taskAddress = address;
        },
    },
};
