import { formattedResponse, promisifiedAjax } from '@/utils/functions';
import MessagesRequest from '@/apps/admin/services/messengers/MessagesRequest';

const CUSTOMER = 'customer';
const LEAD = 'lead';
const MESSENGERS = 'messengers';

export default class MessengersService {
    static TYPE_PATHS = {
        [CUSTOMER]: '/admin/customers/communication',
        [LEAD]: '/admin/crm/leads/communication',
        [MESSENGERS]: '/admin/support/messengers',
    };

    static CONTROLLERS = {
        MESSENGERS: 'messengers',
        MESSENGERS_CHAT: 'messengers-chat',
        UNKNOWN_SENDERS: 'unknown-senders',
        ALL_SENDERS: 'all-senders',
    };

    static ACTIONS = {
        MESSENGER_TABLE_DATA: 'load-chats',
        REQUEST_CONVERSATION: 'assign-to-logged-admin',
        CONVERSATIONS_PAGE_DATA: 'get-page-data',
        CLOSE_CHAT: 'close-chat',
        SEND_MESSAGE: 'send-message',
        REASSIGN_ADMIN: 'reassign-admin',
        GET_ADMINS: 'load-administrators',
        GET_CHAT_DATA: 'info',
        LOAD_MESSAGES: 'load-messages',
        LOAD_TABS_PERMISSIONS: 'load-tabs-permissions',
    };

    constructor(type) {
        this.url = MessengersService.TYPE_PATHS[type];
    }

    getDatatableAllMessengersFilterUrl() {
        const controller = this.constructor.CONTROLLERS.ALL_SENDERS;
        const action = this.constructor.ACTIONS.MESSENGER_TABLE_DATA;
        return `${this.url}/${controller}--${action}`;
    }

    getDatatableUnknownMessengersFilterUrl() {
        const controller = this.constructor.CONTROLLERS.UNKNOWN_SENDERS;
        const action = this.constructor.ACTIONS.MESSENGER_TABLE_DATA;
        return `${this.url}/${controller}--${action}`;
    }

    async getAllMessengersPageData() {
        const controller = this.constructor.CONTROLLERS.ALL_SENDERS;
        return new Promise((resolve) => {
            promisifiedAjax({
                url: `${this.url}/${controller}`,
                method: 'GET',
            }).then((response) => {
                resolve(formattedResponse(response));
            });
        });
    }

    async getUnknownMessengersPageData() {
        const controller = this.constructor.CONTROLLERS.UNKNOWN_SENDERS;
        return new Promise((resolve) => {
            promisifiedAjax({
                url: `${this.url}/${controller}`,
                method: 'GET',
            }).then((response) => {
                resolve(formattedResponse(response));
            });
        });
    }

    getDatatableMessengersFilterUrl({ messenger, customer_id }) {
        const controller = this.constructor.CONTROLLERS.MESSENGERS;
        const action = this.constructor.ACTIONS.MESSENGER_TABLE_DATA;
        return `${this.url}/${controller}--${action}?messenger=${messenger}&customerId=${customer_id}`;
    }

    async getConversationsPageData() {
        const controller = this.constructor.CONTROLLERS.MESSENGERS;
        const action = this.constructor.ACTIONS.CONVERSATIONS_PAGE_DATA;
        return new Promise((resolve) => {
            promisifiedAjax({
                url: `${this.url}/${controller}--${action}`,
                method: 'GET',
            }).then((response) => {
                resolve(formattedResponse(response));
            });
        });
    }

    // Chat View
    async requestConversation(chatId) {
        const controller = this.constructor.CONTROLLERS.MESSENGERS_CHAT;
        const action = this.constructor.ACTIONS.REQUEST_CONVERSATION;
        return new Promise((resolve) => {
            promisifiedAjax({
                url: `${this.url}/${controller}--${action}?chatId=${chatId}`,
                method: 'GET',
            }).then((response) => {
                resolve(response);
            });
        });
    }

    async closeChat(chatId) {
        const controller = this.constructor.CONTROLLERS.MESSENGERS_CHAT;
        const action = this.constructor.ACTIONS.CLOSE_CHAT;
        return new Promise((resolve) => {
            promisifiedAjax({
                url: `${this.url}/${controller}--${action}`,
                method: 'GET',
                data: {
                    chatId,
                },
            }).then((response) => {
                resolve(formattedResponse(response));
            });
        });
    }

    async sendMessage(chatId, content, files = []) {
        const controller = this.constructor.CONTROLLERS.MESSENGERS_CHAT;
        const action = this.constructor.ACTIONS.SEND_MESSAGE;
        return new Promise((resolve) => {
            promisifiedAjax({
                url: `${this.url}/${controller}--${action}`,
                method: 'POST',
                data: {
                    chatId,
                    content,
                    files,
                },
            }).then((response) => {
                resolve(formattedResponse(response));
            });
        });
    }

    async fetchPart(chatId, centralMessage) {
        const controller = this.constructor.CONTROLLERS.MESSENGERS_CHAT;
        const action = this.constructor.ACTIONS.LOAD_MESSAGES;
        const request = new MessagesRequest(
            chatId,
            `${this.url}/${controller}--${action}`,
            centralMessage,
        );
        await request.fetch();
        return request.get().get();
    }

    async fetchEarly(chatId, centralMessage) {
        const controller = this.constructor.CONTROLLERS.MESSENGERS_CHAT;
        const action = this.constructor.ACTIONS.LOAD_MESSAGES;
        const request = new MessagesRequest(
            chatId,
            `${this.url}/${controller}--${action}`,
            centralMessage,
        );
        await request.fetchEarly();
        return request.get().get();
    }

    async loadLaterPart(chatId, centralMessage) {
        const controller = this.constructor.CONTROLLERS.MESSENGERS_CHAT;
        const action = this.constructor.ACTIONS.LOAD_MESSAGES;
        const request = new MessagesRequest(
            chatId,
            `${this.url}/${controller}--${action}`,
            centralMessage,
        );
        await request.fetchLater();
        return request.get().get();
    }

    async getChatData(chatId = null) {
        if (!chatId) {
            return [];
        }
        return new Promise((resolve) => {
            const controller = this.constructor.CONTROLLERS.MESSENGERS_CHAT;
            const action = this.constructor.ACTIONS.GET_CHAT_DATA;
            promisifiedAjax({
                url: `${this.url}/${controller}--${action}`,
                method: 'GET',
                data: {
                    chatId,
                },
            }).then(({ result, data, message }) => {
                resolve({ result, data: formattedResponse(data), message });
            });
        });
    }

    async getTabsPermissions() {
        const action = this.constructor.ACTIONS.LOAD_TABS_PERMISSIONS;
        return new Promise((resolve) => {
            promisifiedAjax({
                url: `${this.url}--${action}`,
                method: 'GET',
            }).then((response) => {
                resolve(formattedResponse(response));
            });
        });
    }

    async getReAssignAdmins(chatId) {
        const controller = this.constructor.CONTROLLERS.MESSENGERS_CHAT;
        const action = this.constructor.ACTIONS.GET_ADMINS;
        return new Promise((resolve) => {
            promisifiedAjax({
                url: `${this.url}/${controller}--${action}?chatId=${chatId}`,
                method: 'GET',
            }).then((response) => {
                resolve(formattedResponse(response));
            });
        });
    }

    async reAssignAdmin(chatId, adminId) {
        const controller = this.constructor.CONTROLLERS.MESSENGERS_CHAT;
        const action = this.constructor.ACTIONS.REASSIGN_ADMIN;
        return new Promise((resolve) => {
            promisifiedAjax({
                url: `${this.url}/${controller}--${action}?chatId=${chatId}&adminId=${adminId}`,
                method: 'GET',
            }).then((response) => {
                resolve(formattedResponse(response));
            });
        });
    }
}
