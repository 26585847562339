<template>
    <chat-head-chatbox
        v-if="chatbox"
        :assigned="assigned"
        :title="title"
        :actions="actions"
        :customer-id="customerId"
        :phone="phone"
        :profile-name="profileName"
        :show-actions="isChatActive"
        :messenger-name="messengerName"
        :is-lead="isLead"

        :mass-action-select-enabled="massActionSelectEnabled"
        @set-mass-action-select-enabled="$emit('mass-action-select-enabled', $event)"

        @mass-action-link-ticket="$emit('mass-action-link-ticket')"
        @mass-action-create-ticket="$emit('mass-action-create-ticket')"

        @create-from-unknown="$emit('create-from-unknown')"
        @link-sender="$emit('link-sender')"

        @close-chat="$emit('close-chat', $event)"
        @re-assign-chat="$emit('re-assign-chat')"
        @back="$emit('back')"
        @minimize="$emit('minimize')"
        @view-chat="$emit('view-chat')"
    />
    <chat-head-extended
        v-else-if="extended"
        :assigned="assigned"
        :title="title"
        :actions="actions"
        :customer-id="customerId"
        :show-actions="isChatActive"

        :mass-action-select-enabled="massActionSelectEnabled"
        @set-mass-action-select-enabled="$emit('mass-action-select-enabled', $event)"

        @mass-action-link-ticket="$emit('mass-action-link-ticket')"
        @mass-action-create-ticket="$emit('mass-action-create-ticket')"

        @close-chat="$emit('close-chat', $event)"
        @re-assign-chat="$emit('re-assign-chat')"
    />
    <chat-head-minimal
        v-else
        :assigned="assigned"
        :phone="phone"
        :actions="actions"
        :customer-id="customerId"
        :show-actions="isChatActive"

        :mass-action-select-enabled="massActionSelectEnabled"
        @set-mass-action-select-enabled="$emit('mass-action-select-enabled', $event)"

        @mass-action-create-ticket="$emit('mass-action-create-ticket')"

        @create-from-unknown="$emit('create-from-unknown')"
        @close-chat="$emit('close-chat')"
        @re-assign-chat="$emit('re-assign-chat')"
        @link-sender="$emit('link-sender')"
    />
</template>

<script>
import ChatHeadExtended from '@/apps/admin/views/admin/customers/view/communication/head/ChatHeadExtended';
import ChatHeadMinimal from '@/apps/admin/views/admin/customers/view/communication/head/ChatHeadMinimal';
import HeadMassActionsMixin from '@/apps/admin/mixins/messengers/head/head-mass-actions-mixin';
import ChatHeadChatbox from '@/components/common/XMessengersChatbox/ChatHeadChatbox.vue';

export default {
    name: 'ChatHead',
    mixins: [HeadMassActionsMixin],
    props: {
        extended: {
            type: Boolean,
            default: true,
        },
        messengerName: {
            type: String,
            default: '',
        },
        chatbox: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        phone: {
            type: String,
            default: '',
        },
        assigned: {
            type: String,
            default: '',
        },
        profileName: {
            type: String,
            default: '',
        },
        isChatActive: {
            type: Boolean,
            default: true,
        },
        actions: {
            type: Array,
            default: () => ([]),
        },
        customerId: [String, Number],
        isLead: Boolean,
    },
    components: {
        ChatHeadMinimal,
        ChatHeadExtended,
        ChatHeadChatbox,
    },
};
</script>
