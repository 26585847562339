<template>
    <audio
        v-if="canPlay"
        ref="audio"
        controls
    >
        <source
            v-if="!alternative"
            ref="source"
            :src="link"
            :type="audioFormat"
        >
        <source
            v-else
            ref="sourceAlt"
            :src="audioAlternativeLink"
            :type="audioAlternativeFormat"
        >
    </audio>
    <a
        v-else
        href="javascript:void(0)"
        @click="downloadFile(link)"
    >
        {{ name }} ({{ size }})
    </a>
</template>

<script>
import ChatMessageAttachmentMixin from '@/apps/admin/mixins/messengers/message/chat-message-attachment-mixin';

export default {
    name: 'ChatAttachmentAudio',
    mixins: [ChatMessageAttachmentMixin],
    props: {
        audioAlternativeLink: String,
        audioAlternativeFormat: String,
    },
    data() {
        return {
            canPlay: true,
            alternative: false,
        };
    },
    mounted() {
        if (!document.createElement('audio').canPlayType(this.audioFormat)) {
            this.alternative = true;
        }

        this.$nextTick(() => {
            if (!this.alternative) {
                this.$refs.source.addEventListener('error', () => {
                    this.canPlay = false;
                });
            } else {
                this.$refs.sourceAlt.addEventListener('error', () => {
                    this.canPlay = false;
                });
            }
        });

        this.$refs.audio.addEventListener('canplay', () => {
            this.canPlay = true;
        });
    },
};
</script>
