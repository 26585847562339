<template>
    <component
        :is="compiledDynamic"
        v-if="!compilation"
        :down-data="downData"
        @send-up="$emit('up-data', $event)"
    />
    <div
        v-else
        :class="preloaderClasses"
    >
        <slot />
    </div>
</template>

<script>
import * as functions from '@/utils/functions';

import XInputHidden from '@/components/common/inputs/XInputHidden';
import { XForm } from '@/components/common/XForm';
import vuex from 'vuex';
import jquery from 'jquery';
import XPreloaderBlock from '@/components/common/XPreloaderBlock';
import XBreadcrumbs from '@/components/base/XBreadcrumbs';
import XDatatable from '@/components/common/XDatatable';
import * as tabs from '@/components/common/XTabs';
import XModal from '@/components/common/XModal';
import XComplexInput from '@/components/common/inputs/XComplexInput';
import * as ace from '@/components/common/XAce';

// File with defined global variables allowed in dynamic components
import XBootstrapSpinner from '@/components/common/XBootstrapSpinner/XBootstrapSpinner';
import allows from './allows.json';

const ALLOW_GLOBALS = {
    ...allows,
};

const DEPENDENCIES = {
    // Modules that a component can import from Splynx
    vue: window.Vue,
    vuex,
    jquery,
    '@/components/common/XBootstrapSpinner/XBootstrapSpinner': () => import('@/components/common/XBootstrapSpinner/XBootstrapSpinner'),
    '@/components/common/XAlertWithProgressBar/XAlertWithProgressBar': () => import('@/components/common/XAlertWithProgressBar/XAlertWithProgressBar'),
    '@/components/base/XLicense/XLicense': () => import('@/components/base/XLicense/XLicense'),
    '@/components/common/inputs/XInputHidden': { XInputHidden },
    '@/utils/functions': { ...functions },
    '@/components/common/XPreloaderBlock': { XPreloaderBlock },
    '@/components/base/XBreadcrumbs': { XBreadcrumbs },
    '@/components/common/XDatatable': { XDatatable },
    '@/components/common/XTabs': { ...tabs },
    '@/components/common/XFilter/XFilterWithUrl': () => import('@/components/common/XFilter/XFilterWithUrl'),
    '@/components/common/XModal': XModal,
    '@/components/common/inputs/XComplexInput': { XComplexInput },
    '@/components/common/XForm': { XForm },
    '@/components/common/XAce': { ...ace },
};

const reservedWords = [
    'break', 'case', 'catch', 'class', 'const', 'continue',
    'debugger', 'default', 'delete', 'do', 'else', 'export',
    'extends', 'finally', 'for', 'function', 'if', 'import',
    'in', 'instanceof', 'let', 'new', 'return', 'super',
    'switch', 'this', 'throw', 'try', 'typeof', 'var',
    'void', 'while', 'with', 'yield',
];

export function parseToComponent(SFC, sfcString, internalList, globalModulesToAllow, securityCallback) {
    const options = {
        moduleCache: {
            ...DEPENDENCIES,
            ...globalModulesToAllow,
        },
        async getFile(url) {
            if (url === 'root.vue') {
                return sfcString;
            }
            if (url in internalList) {
                return {
                    type: `.${internalList[url].type}`,
                    getContentData: () => securityCallback(internalList[url].data, false, `.${internalList[url].type}`),
                };
            }
            alert(`Missing dependency: ${url}`);
            return null;
        },
        addStyle(styleString) {
            // Processing of dynamic component styles
            let style = document.getElementById('vue-add-on-style');
            if (!style) {
                style = document.createElement('style');
                style.setAttribute('id', 'vue-add-on-style');
                const ref = document.head.getElementsByTagName('style')[0] || null;
                document.head.insertBefore(style, ref);
            }
            style.textContent = styleString;
        },
    };
    return SFC.loadModule('root.vue', options);
}

export default {
    name: 'XAddonVueComponent',
    props: {
        internalPreloader: {
            type: Boolean,
            default: false,
        },
        /**
         * Processes an action at a specified URL, returning the content of a component.
         *
         * @param {string} component - The string content of the component, either in .vue or .js(mjs) format.
         * @param {Object.<string, Array.<{type: string, data: Object}>>} internal_dependencies -
         * Maps import paths to an array of objects, where each object specifies the type (.vue or .mjs)
         * and the component's data.
         * The key should correspond to the import path of this component in the parent component.
         */
        url: String,
        downData: Object,
    },
    data() {
        return {
            sfcLoader: {},
            compilation: true,
            compiledDynamic: {},
        };
    },
    methods: {
        _importsToString(object) {
            let string = 'import { ';
            const props = [];
            for (let key of Object.keys(object)) {
                if (key === '$' || Number.isFinite(Number(key)) || reservedWords.includes(key)) {
                    continue;
                }
                props.push(key);
            }
            string += props.join(', ');
            string += ' } from \'stubs\'; \n';
            return string;
        },
        _setSecurity(stringComponent, isTag = true, type = '.vue') {
            let index = stringComponent.search('<script>');
            let before = stringComponent.slice(0, index + 8);
            let after = stringComponent.slice(index + 8);
            if (!isTag) {
                index = 0;
                before = stringComponent.slice(0, index);
                after = stringComponent.slice(index);
            }
            const jqueryImport = 'import $ from \'jquery\'; \n';
            return `${before}${this._getGlobalKeys(type)}${jqueryImport}${after}`;
        },
        _processObject(source, allowList, topLevel = true) {
            let result = {};
            for (const key in source) {
                if (Object.prototype.hasOwnProperty.call(allowList, [key])) {
                    if (typeof allowList[key] === 'object' && allowList[key] !== null) {
                        result[key] = this._processObject(source[key], allowList[key], false);
                    } else if (allowList[key] !== false) {
                        result[key] = source[key];
                    } else {
                        result[key] = undefined;
                    }
                } else if (topLevel) {
                    result[key] = undefined;
                } else {
                    result[key] = source[key];
                }
            }
            return result;
        },
        _deny(object) {
            return this._processObject(object, ALLOW_GLOBALS);
        },
        _getGlobalKeys(type) {
            let globalScope = { ...window };
            globalScope.globalThis = {};
            if (type === '.vue') {
                return this._importsToString(globalScope);
            }
            if (type === '.mjs') {
                return this._importsToString(globalScope);
            }
            throw new Error('Type is not support');
        },
        init() {
            functions.promisifiedAjax({
                url: this.url,
            }).then(async (response) => {
                if (typeof response !== 'object') {
                    alert('Entry point is not a Vue Component');
                    return false;
                }
                let { component, internal_dependencies } = response;
                component = this._setSecurity(component);
                this.compiledDynamic = await parseToComponent(
                    this.sfcLoader,
                    component,
                    internal_dependencies,
                    this._getGlobalModules,
                    this._setSecurity,
                );
                this.compilation = false;
                this.$emit('loaded');
            }).catch((error) => {
                this.$showError(error.responseText, 4);
                this.compilation = false;
            });
        },
    },
    computed: {
        preloaderClasses() {
            return {
                'h-100': this.internalPreloader,
                'w-100': this.internalPreloader,
            };
        },
        _getGlobalModules() {
            let stubs = { ...window };
            stubs = this._deny(stubs);
            stubs.window = {
                ...stubs,
            };
            stubs.globalThis = {
                ...stubs,
            };
            return { stubs };
        },
    },
    beforeCreate() {
        import('@/../web/js/development/vue2-sfc-loader.js')
            .then((module) => {
                this.sfcLoader = module;
            });
    },
    components: {
        XBootstrapSpinner,
        XPreloaderBlock,
    },
    watch: {
        compilation(newValue) {
            this.$emit('processing', newValue);
        },
        sfcLoader() {
            this.init();
        },
    },
};
</script>
