import DocumentAttachment from './DocumentAttachment';
import ImageAttachment from './ImageAttachment';
import IframeAttachment from './IframeAttachment';
import AudioAttachment from './AudioAttachment';

export default class Attachments {
    static IFRAME = 'iframe';

    static IMAGE = 'image';

    static DOCUMENT = 'document';

    constructor(attachments = []) {
        this.list = [...attachments].map(Attachments._defineTypes);
    }

    static _defineTypes(item) {
        if (item['data-pws-type'] === Attachments.IMAGE) {
            return new ImageAttachment(item);
        } if (item['data-pws-type'] === Attachments.IFRAME) {
            return new IframeAttachment(item);
        } if (item.is_audio || item.isAudio) {
            return new AudioAttachment(item);
        }
        return new DocumentAttachment(item);
    }

    get() {
        return this.list;
    }

    getImages() {
        return this.list.filter((attachment) => attachment instanceof ImageAttachment);
    }

    getIframes() {
        return this.list.filter((attachment) => attachment instanceof IframeAttachment);
    }

    getDocuments() {
        return this.list.filter((attachment) => attachment instanceof DocumentAttachment);
    }

    getAudio() {
        return this.list.filter((attachment) => attachment instanceof AudioAttachment);
    }
}
