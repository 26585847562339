import XTwigPage from '@/components/base/XTwigPage';
import ViewRoutes from '@/apps/admin/router/routes/admin/networking/acs/device-view';

const AcsDashboard = () => import(/* webpackChunkName: "acs_dashboard" */ '@/apps/admin/views/admin/networking/acs/dashboard/AcsDashboard');
const AcsDevicesList = () => import(/* webpackChunkName: "acs_devices" */ '@/apps/admin/views/admin/networking/acs/devices/AcsDevicesList');
const AcsFilesList = () => import(/* webpackChunkName: "acs_files" */ '@/apps/admin/views/admin/networking/acs/files/AcsFilesList');
const AcsUpgradesList = () => import(/* webpackChunkName: "acs_upgrades" */ '@/apps/admin/views/admin/networking/acs/upgrades/AcsUpgradesList');

export default [
    {
        path: 'dashboard',
        name: 'admin.networking.acs.dashboard',
        component: AcsDashboard,
        meta: {
            keepAlive: true,
            componentName: 'AcsDashboard',
        },
    },
    {
        path: 'devices',
        name: 'admin.networking.acs.devices',
        component: AcsDevicesList,
        meta: {
            keepAlive: true,
            componentName: 'AcsDevicesList',
        },
    },
    {
        path: 'device-view',
        children: ViewRoutes,
    },
    {
        path: 'files',
        name: 'admin.networking.acs.files',
        component: AcsFilesList,
        meta: {
            keepAlive: true,
            componentName: 'AcsFilesList',
        },
    },
    {
        path: 'upgrade',
        name: 'admin.networking.acs.upgrade',
        component: AcsUpgradesList,
        meta: {
            keepAlive: true,
            componentName: 'AcsUpgradesList',
        },
    },
    {
        path: 'upgrade--add',
        name: 'admin.networking.acs.upgrade.add',
        component: XTwigPage,
    },
    {
        path: 'upgrade--view-batch',
        name: 'admin.networking.acs.upgrade.view_batch',
        component: XTwigPage,
    },
];
