<template>
    <div
        :id="elementId"
        ref="container"
        class="attachments-grid"
    >
        <div
            class="image-attachment-preload"
            :style="{ height: calculatedHeight }"
        >
            <div class="position-absolute">
                <x-bootstrap-spinner
                    v-if="loading"
                    size="sm"
                />
            </div>
            <img
                ref="attachedImage"
                :src="link"
                alt="Attachment Image"
                class="attachment-image pws-open"
                :data-is-pws="isPws"
                :data-pws-download-url="downloadUrl"
                :data-pws-ratio="ratio"
                :data-pws-title="title"
                :data-pws-type="type"
                :data-pws-url="url"
            >
        </div>
    </div>
</template>

<script>
import ChatMessageAttachmentMixin from '@/apps/admin/mixins/messengers/message/chat-message-attachment-mixin';
import { XBootstrapSpinner } from '@/components/common/XBootstrapSpinner';

export default {
    name: 'ChatAttachmentImage',
    mixins: [ChatMessageAttachmentMixin],
    data() {
        return {
            width: 0,
            loading: true,
        };
    },
    mounted() {
        this.width = this.$refs.attachedImage.parentNode.clientWidth;
        this.$refs.attachedImage.onload = () => {
            this.loading = false;
        };
    },
    computed: {
        calculatedHeight() {
            const [width, height] = this.ratio.split('x').map(Number);
            const calculated = (height / width) * this.width;
            return `${calculated}px`;
        },
    },
    components: {
        XBootstrapSpinner,
    },
};
</script>
