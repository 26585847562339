import { SAVE_FILTER_STATE, SAVE_ADVANCED_OPENED, SAVE_BEFORE_URL_STATE } from '@/components/common/XFilter/store/mutation-types';

export default {
    [SAVE_BEFORE_URL_STATE](state, { filterName, newState }) {
        state.beforeUrlState[filterName] = newState;
    },
    [SAVE_FILTER_STATE](state, { filterName, newState }) {
        state.savedFilterStates[filterName] = newState;
    },
    [SAVE_ADVANCED_OPENED](state, { filterName, newState }) {
        state.savedAdvancedOpened[filterName] = newState;
    },
};
