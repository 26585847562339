const XChatBoxPlugin = {};

XChatBoxPlugin.install = (Vue) => {
    Vue.prototype.$openChatBox = function () {
        this.$store.dispatch('chatbox/open');
    };
    Vue.prototype.$closeChatBox = function () {
        this.$store.dispatch('chatbox/close');
    };
    Vue.prototype.$chatBoxNotify = function () {
        this.$store.dispatch('chatbox/notify');
    };
    Vue.prototype.$chatBoxShowList = function () {
        this.$store.dispatch('chatbox/showList');
    };
};
export default XChatBoxPlugin;
