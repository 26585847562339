import XTwigPage from '@/components/base/XTwigPage';

const ProvisionFlow = () => import(/* webpackChunkName: "provision_flow" */ '@/apps/admin/views/admin/config/networking/acs/ProvisioningFlow');
export default [
    {
        path: '',
        name: 'admin.config.networking.acs',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'provision-flow',
        name: 'admin.config.networking.acs.provision-flow',
        component: ProvisionFlow,
        meta: {
            breadcrumbs: [{ title: ['config', 'Provision flow'], url: '/admin/config/networking/acs--provision-flow' }],
        },
    },
];
