export default function serialize(state) {
    function serializeHelper(value) {
        if (typeof value === 'function') {
            return value.toString();
        } if (Array.isArray(value)) {
            return value.map(serializeHelper);
        } if (value !== null && typeof value === 'object') {
            return Object.fromEntries(
                Object.entries(value).map(([key, val]) => [key, serializeHelper(val)]),
            );
        }
        return value;
    }
    return serializeHelper(state);
}
